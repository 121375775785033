import { axiosPrivateBlob } from "../context/axios";
import { useState, useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";

const useAxiosPrivateBlob = () => {

    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {

        const requestIntercept = axiosPrivateBlob.interceptors.request.use(
            config => {
                if(!config.headers["Authorization"]) {
                config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
                }

                return config;
            }, (error) => Promise.reject(error)
        )

        
        const responseIntercept = axiosPrivateBlob.interceptors.response.use(
            (response) => response,
            async (error) => { 
                const prevRequest = error?.config;
                if (error.response.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosPrivateBlob(prevRequest);
                }
            }
       )
       return () => {
        axiosPrivateBlob.interceptors.request.eject(requestIntercept);
        axiosPrivateBlob.interceptors.response.eject(responseIntercept);
       }
    },[auth, refresh])

    return axiosPrivateBlob;

}

export default useAxiosPrivateBlob;
