import React, {Component, useEffect, useState} from 'react'
import {Link, withRouter} from 'react-router-dom'

import ReactPDF, { Page, Text, View, Image, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Logo from '../../static/assets/images/logo_slim.png'
import moment from 'moment'



const totalPages = (quantity,max) => {    
    let raw = quantity/max    
    let floor = Math.floor(quantity/max)
    if(raw>floor) { return floor + 1; }
    else { return floor }
}

const maxEntries = 20;

export default function BillPersonnel({ date, reference, orders, client, destination, location, service, clientReference, employee }) {

    const styles = StyleSheet.create({
        document: {
            background: "white",
            width: 800,
            height: 1000,
        },
        title: {
            width: '100%',
            fontSize: '50px',
        }, 
        topRight: {
            position: "absolute",
            right: "0px",
            top: "10px",
            fontSize: "14px",

        },
        topCenter: {
            position: "absolute",
            top: "10px",
            left: "240px",
            fontSize: "14px",
        
        },
        page: {
          backgroundColor: '#ffffff',
          width: 800,
          height: 1000,

        },
        image: {
            marginTop: "10px",
            //marginLeft: "10px",
            width: 260,
            height: 35,
        },
        header: {
            display:"flex",
            flexDirection: 'row',
            width:"100%",
            marginTop: "30px",
            height: "60px",
            //justifyContent: 'flex-end',
        },
        sectionInfo: {
            position: "absolute",
            color: "black",
            //left: "10px",
            top: "50px",
            fontWeight:"bold",
            fontSize: "10px",
            left: "2px",
        },            
        sectionDestination: {
            color: "black",   
            paddingLeft: "0px",
            flexBasis: 350,
            paddingTop: "10px",
            fontWeight:"bold",
            fontSize: "11px",
        },
        sectionClient: {
            color: "black",
            //paddingLeft: "12px",
            flexBasis: 380,
            paddingTop: "10px",
            fontSize: "11px",
        },
        details: {
            fontSize:"12px",
            width: "100%",
            display:"flex",
            paddingTop:"22px",
            paddingBottom:"12px",
        },
        dateCompleted:{
            fontSize:"12px",
            position:"absolute",
            left: "310px",
            top: "24px"

        },
        service:{
            width:"50%", 
            fontSize:"12px", 
            textAlign:"left",
            //paddingLeft:"10px",

        },
        worker:{
            width:"45%", 
            textAlign:"right",
            position:"absolute",
            right:"10px",
            top: "23px"
        },
        pagination:{
            width:"45%", 
            fontSize:"11px", 
            textAlign:"right",
            position:"absolute",
            right:"0px",
            top: "-50px",
        },            
        //////////////////////////////////////////////
        rowsHeader: {
            display: "flex",
            flexDirection: 'row',
            width: "100%",
            fontSize: "12px",
            //paddingLeft: "50px",
        },
        orderEntry:{
            display: "flex",
            flexDirection: 'row',
            width: "100%",
            fontSize: "10px",
            //paddingLeft: "50px",
        },
        dividerHorBig: {
            border: "1px solid #2f2fa2",
            height: "5px",
            width: "100%",
            backgroundColor: "#2f2fa2",
            marginTop:"3px",
        },
        dividerHorSmall: {
            border: "1px solid #black",
            width: "100%",
            backgroundColor: "#2f2fa2",
        },
        dividerVerSmall: {
            border: "1px solid #black",
            width: "100%",
            backgroundColor: "#2f2fa2",
        },
        footer: {
            display: "flex",
            flexDirection: 'row',
            width: "100%",
            fontSize: "10px",
            bottom: 0,
            height: "55px",
        },
        totalsRow: {
            width: "100%",
            height: "20px"
        },
        footerBox: {                
            borderLeft:"1px solid #2f2fa2",
            borderRight:"1px solid #2f2fa2",
            borderBottom:"2px solid #2f2fa2",
            backgroundColor: "white",
            width: "50%",   
        },
        checkbox: {
            height: "15px",
            width: "15px",
            backgroundColor: "#ff0000",
            border: "1px solid black",
        }
        

    });

    let order_entries = [];
    let order_pages = []; 

    const [orderPages, setOrderPages] = useState([]);

    let order_date = moment().format("DD/MM/YYYY");
    const dateCompleted = "";

    let count = 0;
    let total_count = 0;
    let pages = 1;
    let total_pages = totalPages(orders.length,maxEntries);
    const dateStarted = moment(date).format('dddd, MMMM Do YYYY')

    const selectedOrders = orders.filter(order => order.selected);

    console.log("ORDERS", orders);
    console.log("SELECTED ORDERS", orders);


    useEffect(() => {

        console.log(orders);

        //for (let i=0; i<orders.length; ++i) {
        for (let i=0; i<orders.length; ++i) {

            //if (orders[i].selected) {        

                count = count + 1;  
                total_count = total_count+1;   

                let entry = 
                    <View style={styles.orderEntry} key={i}>                        
                        <Text style={{fontSize:"9px", paddingLeft:"10px", textAlign:"left", width:"2%", paddingBottom:"4px", paddingTop:"4px", borderRight:"1px solid black", borderLeft:"1px solid black"}}></Text>
                        <Text style={{fontSize:"9px", paddingLeft:"10px", textAlign:"left", width:"38%", paddingBottom:"4px", paddingTop:"4px", borderRight:"1px solid black"}}>{orders[i].name}</Text>
                        <Text style={{fontSize:"9px", paddingLeft:"10px", textAlign:"left", width:"58%", paddingBottom:"4px", paddingTop:"4px", borderRight:"1px solid black"}}>{orders[i].description}</Text>
                        <Text style={{fontSize:"9px", paddingLeft:"10px", textAlign:"left", width:"2%", paddingBottom:"4px", paddingTop:"4px", borderRight:"1px solid black"}}></Text>
                        {/* <Text style={{fontSize:"9px", paddingLeft:"10px", textAlign:"left", width:"80px", paddingBottom:"4px", paddingTop:"4px", borderRight:"1px solid black"}}>{orders[i].quantity} {orders[i].unit}</Text> */}
                        {/* <Text style={{fontSize:"9px", paddingRight:"10px", textAlign:"right", width:"80px", paddingBottom:"4px", paddingTop:"4px", borderRight:"1px solid black"}}>{orders[i].weight}</Text> */}
                        {/* <Text style={{fontSize:"9px", paddingLeft:"10px", textAlign:"left", width:"80px", paddingBottom:"4px", paddingTop:"4px",  borderRight:"1px solid black"}}>{orders[i].reference}</Text> */}
                        {/* <Text style={{fontSize:"9px", paddingLeft:"10px", textAlign:"left", width:"285px", paddingBottom:"4px", paddingTop:"4px",  borderRight:"1px solid black"}}>{orders[i].supplier.toUpperCase()} via {orders[i].carrier.toUpperCase()} # {orders[i].waybill}</Text> */}
                    </View>
            
        
                order_entries.push(entry);                            

                if (count == maxEntries || total_count==orders.length) {

                    if (count<maxEntries) {
                        for (let j=0; j<(maxEntries-count); ++j) {

                            let dummy_entry = 
                                <View style={styles.orderEntry} key={j}>
                                    <Text style={{fontSize:"9px", width:"2%", paddingLeft:"10px", textAlign:"left", paddingBottom:"4px", paddingTop:"4px", borderRight:"1px solid black", borderLeft:"1px solid black"}}></Text>
                                    <Text style={{fontSize:"9px", width:"38%", textAlign:"center", paddingBottom:"4px", paddingTop:"4px", color:"#ffffff", borderRight:"1px solid black"}}>[  ]</Text>
                                    <Text style={{fontSize:"9px", width:"58%", paddingLeft:"10px", textAlign:"left", paddingBottom:"4px", paddingTop:"4px", borderRight:"1px solid black"}}></Text>
                                    <Text style={{fontSize:"9px", width:"2%", paddingLeft:"10px", textAlign:"left", paddingBottom:"4px", paddingTop:"4px", borderRight:"1px solid black"}}></Text>
                                </View>

                            order_entries.push(dummy_entry);
                        }
                    }

                    // start a new page
                    count = 0;

                    let post_date = new Date(order_date).toLocaleString('en-US',{year: 'numeric', month: 'numeric', day: 'numeric'})

                    let page = 
                        <Page scale={1.0} size="A4" style={styles.page} key={`p${i+Math.random(9)}`}>

                            <Image
                                style={styles.image}
                                src={Logo}
                            /> 

                            <View style={styles.topRight}>                            
                                <Text>Bon de Livraison • Delivery Note</Text>
                                <Text style={{position:"absolute", right:"0px", top:"15px", fontSize:"14px"}}>{reference}</Text>
                                <Text style={{position:"absolute", right:"0px", top:"29px", fontSize:"14px"}}>{date}</Text>
                            </View>      

                            <View style={styles.sectionInfo}>
                                <Text>4944 Ave. Dunn. Montreal, H4E 1C2</Text>
                                <Text>Tel: 514 932 9959 • 24 HRS Service • service@locksanddocks.com</Text>                
                            </View> 


                            <View style={styles.header}>
                                <View style={styles.sectionClient}>
                                    <Text style={{width:"100%", textAlign:"left",borderBottom:"1px solid black",fontSize:"12px",fontWeight:"bold"}}>CLIENT: </Text>
                                    <Text style={{paddingTop:"4px"}}>{client}</Text>
                                </View>                       

                                <View style={styles.sectionDestination}>
                                    <Text style={{borderBottom:"1px solid black",strokeWidth:"3px",fontSize:"12px"}}>DESTINATION:</Text>
                                    <Text style={{paddingTop:"4px"}}>{destination}</Text>
                                    <Text>{location} </Text>
                                </View>
                            </View>
                    
                            <View style={styles.details}>
                                <View style={styles.service}>
                                    <Text>SERVICE:</Text>
                                    <Text>{service}</Text>
                                </View>

                                {/* <View style={styles.worker}>
                                    <Text>BY/PAR:</Text>
                                    <Text>{employee}</Text>
                                </View> */}
                                
                                <View style={styles.dateCompleted}>
                                    <Text>CLIENT REF:</Text>
                                    <Text>{clientReference}</Text>                            
                                </View>
                                
                                <Text style={styles.pagination}>PAGE {pages}/{total_pages}</Text>
                            </View>

                            <View style={styles.dividerHorBig}></View>

                            <View style={styles.rowsHeader}>                                
                                <Text style={{fontSize:"10px", width:"2%", textAlign:"center", paddingBottom:"4px", paddingTop:"4px", borderLeft:"1px solid black", borderRight:"1px solid black"}}></Text>
                                <Text style={{fontSize:"10px", width:"38%", textAlign:"center", paddingBottom:"4px", paddingTop:"4px", borderRight:"1px solid black"}}>NAME</Text>
                                <Text style={{fontSize:"10px", width:"58%", textAlign:"center", paddingBottom:"4px", paddingTop:"4px", borderRight:"1px solid black"}}>DESCRIPTION</Text>
                                <Text style={{fontSize:"10px", width:"2%", textAlign:"center", paddingBottom:"4px", paddingTop:"4px", borderRight:"1px solid black"}}></Text>
                            </View>

                            <View style={styles.dividerHorSmall}></View>

                            {order_entries}

                            <View style={styles.dividerHorBig}></View>

                            <View style={styles.footer}>
                                <View style={{width: "50%",backgroundColor: "white",borderRight:"2px solid #2f2fa2",borderLeft:"1px solid #2f2fa2",borderBottom:"2px solid #2f2fa2"}}>
                                    <Text style={{marginTop:"2px",textIndent:"5px"}}>SIGN</Text>

                                    <Text style={{marginTop:"25px",textIndent:"5px"}}>PRINT</Text>
                                </View>
                                <View style={styles.footerBox}>   

                                    <View style={{marginTop:"2px",marginLeft:"5px"}}>
                                        <Text>DATE COMPLETED:</Text>                                        
                                    </View>                                 

                                    <View style={{marginTop:"25px",marginLeft:"5px",display:"flex"}}>
                                        <Text style={{display:"flex"}}>BY/PAR: {employee}</Text>                                        
                                    </View>


                                </View>
                            </View>
                        </Page>    

                                            
                    order_pages.push(page);
                    order_entries = [];
                    pages = pages + 1;

                    //console.log(order_pages.length);
                }     
            }     
            setOrderPages(order_pages);
    }, [])



  
    return (

        <PDFViewer style={{height:"100%",width:"100%"}}>
            <Document style={styles.document}>
                {orderPages}
            </Document>
        </PDFViewer>

  )
 }