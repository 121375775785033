import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import useLogout from '../hooks/useLogout';

import useAuth from '../hooks/useAuth';
import useRefreshToken from '../hooks/useRefreshToken';

import Sidebar from '../modules/sidebar';
import Table from '../modules/table';
import Bulletin from '../modules/bulletin';
import Plot from '../modules/plot';
import Layout from '../context/layoutPortal.jsx';

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAxiosPrivateBlob from '../hooks/useAxiosPrivateBlob';


export default function Dashboard() {

  const axiosPrivate = useAxiosPrivate();

  const auth = useAuth();
  const refresh = useRefreshToken();
  const navigate = useNavigate();
  const childRef = useRef();
  //let location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [plotTitle, setPlotTitle] = useState("");
  const [plotData, setPlotData] = useState([]);

  useEffect(() => {
    //console.log(auth);
    //fetchPlotData();    
  }, []);

  const fetchPlotData = async () => {

    setIsLoading(true);

    const params = {
      "user": auth.auth.name
    }
    
    const response = await axiosPrivate.get("/datasets/orders", 
      {params:params}, 
      { method: "GET", credentials: "include" }
    );

    //console.log(response.data);

    let newPlotData = [];

    response.data.forEach((item) => {
      newPlotData.push({x: item.date, value: item.totalOrders});
    });

    //console.log(newPlotData)

    setPlotTitle("Running total orders");
    setPlotData(newPlotData);
    setIsLoading(false); 
  
  }
      

  return (

    <>    

      <Layout>

        <div class="h-100">
          <Bulletin />
        </div>

        {/* <div class="w-100 h-full px-6 mt-2 rounded">
          {plotData.length > 0 && !isLoading &&
            <Plot 
              title={plotTitle}
              data={plotData}
            />
          }
        </div> */}

        {/* <button class="bg-red-500 text-white" onClick={fetchPlotData}>FETCH</button> */}
                
      </Layout>
                
    </>
  );
}
















