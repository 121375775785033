import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import Loading from '../modules/loading.jsx';
import Layout from '../context/layoutPortal.jsx';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

import Table from '../modules/table.jsx';

function arrayAlphebetize(array) {
    return array.sort((a, b) => a.localeCompare(b));
}

function removeDuplicatesFromArray(array) {
    return array.filter((a, b) => array.indexOf(a) === b);
} 

export default function Activity() {

    const auth = useAuth();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    
    const monthList = [["01","January"], ["02","February"], ["03","March"], ["04","April"], ["05","May"], ["06","June"], ["07","July"], ["08","August"], ["09","September"], ["10","October"], ["11","November"], ["12","December"]];

    const [data, setData] = useState([]);    
    const [fetchLimit, setFetchLimit] = useState(100);
    const [isLoading, setIsLoading] = useState(false);
    const [editData, setEditData] = useState({}); 
    const [billJobData, setBillJobData] = useState({});   
    const [showEditMenu, setShowEditMenu] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [clientList, setClientList] = useState(arrayAlphebetize(Object.keys(auth.auth.divisionMap)));
    const [destinationList, setDestinationList] = useState(arrayAlphebetize(removeDuplicatesFromArray(arrayAlphebetize(Object.values(auth.auth.divisionMap).flatMap((division) => division.split(';')).slice(1)))));
    const [showWorkOrder, setShowWorkOrder] = useState(false);

    const [filterDate, setFilterDate] = useState("");
    const [filterLimit, setFilterLimit] = useState(25);
    const [filterYear, setFilterYear] = useState("All");
    const [filterMonth, setFilterMonth] = useState("All");    
    const [filterClient, setFilterClient] = useState("All");
    const [filterDestination, setFilterDestination] = useState("All");

    const columns = [
        { 
            id: 'client', 
            label: 'CLIENT', 
            minWidth: 100,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
        { 
            id: 'datetime', 
            label: 'DATE/TIME', 
            minWidth: 50,
            align: 'left',
            maxWidth: 50,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'id',
            //label: 'Size\u00a0(km\u00b2)',
            label: 'ID',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'note',
            //label: 'Size\u00a0(km\u00b2)',
            label: 'NOTE    ',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
    ];     


    useEffect(() => {
        //console.log(auth);
        fetchActivityData();
        
    }, []);

    const fetchActivityData = async () => {

        const params = {
            filter_limit: filterLimit,     
            filter_month: filterMonth=="All"?"%":filterMonth,
            filter_year: filterYear=="All"?"%":filterYear,             
            filter_client: filterClient=="All"?"%":filterClient,
            filter_destination: filterDestination=="All"?"%":filterDestination,
        }

        const response = await axiosPrivate.get("/activity", {params: params}, { method: "GET", credentials: "include" });

        //console.log(response.data[0]);

        setData(response.data);
    }

    const onSort = () => {

    }


    return (          

        <>
            <Layout>

                {/* <button onClick={testFetch} class="flex rounded-md px-10 ml-auto mr-8 text-center bg-blue-400 hover:bg-blue-500 text-white py-3">Fetch Event Data</button> */}

                <div class="w-100 flex pb-2 border-bottom">                    
                        
                        <div class="mr-auto w-1/3 flex-col">
                            <div class="flex flex-col w-36 mt-3 ">
                                <div class="w-full md:w-100">
                                    {!isLoading?
                                    <div onClick={fetchActivityData} class="w-48 h-10 flex bg-black hover:bg-gray-900 text-white font-bold" type="button">
                                        <p class="flex m-auto">Update Activity</p>
                                    </div> 
                                    :   
                                    <div class="w-36 h-10 flex bg-blue-700 text-white font-bold rounded pointer-events-none" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span> 
                                    </div> 
                                    }
                                </div> 
                            </div>

                            {/* <span class="w-100 inline-block ml-8">Select Date</span>
                            <div class="flex w-full mr-4 ml-4">                                
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Select Date"
                                        value={filterDate}
                                        onChange={(newValue) => {
                                            setFilterDate(newValue);
                                        }}                                
                                        renderInput={(params) => 
                                            <TextField 
                                                {...params} 
                                                sx={{ backgroundColor:"white",  }}
                                            />
                                        }
                                    />
                                </LocalizationProvider>
                            </div> */}

                            {auth.auth.username=="admin"?
                                <div class="mr-auto mt-3 w-1/2">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Client
                                    </label>
                                    <select onChange={(e) => setFilterClient(e.target.value)} 
                                        class="w-100 bg-white text-gray-700 border border-red-500 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                        <option value="All">All</option>
                                        <option class="py-2" disabled>---------------</option>
                                        {clientList.map((client, index) => {
                                            if (client!="admin"){
                                                return (
                                                    <option value={client}>{client}</option>
                                                )
                                            }
                                        })}                            

                                    </select>
                                    {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                            :null}


                                                      
                        </div> 

                        <div class="ml-auto w-1/3 flex-col pl-6">

                            <div class="w-full ">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Year
                                </label>
                                <select 
                                    onChange={(e) => setFilterYear(e.target.value)} 
                                    class="w-100 bg-white text-gray-700 border border-red-500 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                    <option value="All">All</option>
                                    <option class="py-2" disabled>---------------</option>
                                    <option value="2023">2023</option>
                                    <option value="2022">2022</option>
                                </select>
                            </div>

                            <div class="w-full">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Month
                                </label>
                                <select 
                                    onChange={(e) => setFilterMonth(e.target.value)} 
                                    class="w-100 bg-white text-gray-700 border border-red-500 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                    <option value="All">All</option>
                                    <option class="py-2" disabled>---------------</option>
                                    {/* create an option for each month */}
                                    {monthList.map((month, index) => {
                                        return (
                                            <option value={month[0]}>{month[1]}</option>
                                        )
                                    })} 
                                </select>
                            </div>

                        </div>
  
                        <div class="ml-auto w-1/3 flex-col pl-6">

                            <div class="w-full">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Destination
                                </label>
                                <select onChange={(e) => setFilterDestination(e.target.value)} 
                                    class="w-100 bg-white text-gray-700 border border-red-500 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                    <option value="All">All</option>
                                    <option class="py-2" disabled>---------------</option>
                                    {destinationList.slice(1).map((destination, index) => {                                
                                        return (
                                            <option value={destination}>{destination}</option>
                                        )
                                    })}
                                </select>
                                {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                            </div>
                            
                            <div class="w-full">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Limit
                                </label>
                                <select 
                                    onChange={(e) => setFilterLimit(e.target.value)} 
                                    class="w-100 bg-white text-gray-700 border border-red-500 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                    <option value="25" selected>25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                              
                        </div>                

                </div>
                
                <div class="h-5/6 w-100 mt-1">
                    <Table 
                        username={auth.auth.username}
                        columns={columns} 
                        data={data} 
                    />
                </div>

            </Layout>
        </>
        
    )
}