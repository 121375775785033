import axios from '../context/axios';
import useAuth from './useAuth';
import { useState, useEffect } from 'react';

const useLogout = () => {
    const { setAuth } = useAuth();

    const logout = async () => {
        setAuth({});
        try {
            const response = await axios('/logout', {
                withCredentials: true,
                method: 'POST'
            });
        } catch (err) {
            console.log('Logout failed', err);
        }
    };

    return logout;
}

export default useLogout;