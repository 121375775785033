import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import useLogout from '../hooks/useLogout';

import useAuth from '../hooks/useAuth';
import useRefreshToken from '../hooks/useRefreshToken';

import Sidebar from '../modules/sidebar';
import Table from '../modules/table';

import Layout from '../context/layoutPortal.jsx';



export default function Settings({ notify }) {

  const auth = useAuth();
  const refresh = useRefreshToken();
  const navigate = useNavigate();
  const childRef = useRef();
  //let location = useLocation();

  useEffect(() => {
    //const page = location.pathname;

    //console.log(auth);
    
  }, []);



  return (

    // MODULES

    // sidebar 
    // plot
    // miniTable

    <>    

      <Layout>

        <section class="p-3">

            <h1 class="changelog !text-xl font-bold">Changelog</h1>

            <div class="p-2">
              <h2 class="changelog !text-lg font-bold border-b-2 w-100 border-black">v0.7.17 - MAY 5, 2024</h2>
                <div class="space-y-1 ml-4">
                  <p class="changelog">• <b>FIXED:</b> Search hanging in Inventory Table on clients account.</p>
                  <p class="changelog">• <b>FIXED:</b> Outgoing jobs uploading POD causes Email formatting error.</p>
                  <p class="changelog">• <b>FIXED:</b> Error occuring when trying to update items in the inventory table.</p>
                  <p class="changelog">• <b>FIXED:</b> Assigning an item automatically sets "Locks & Docks" as the carrier, it should be "LOCKS AND DOCKS".</p>
                  <p class="changelog">• <b>FIXED:</b> Trying to edit a merchandise orders entry that was placed there by an inventory "assign" throws an error when the client was left as "Locks and Docks".</p>
                  <p class="changelog">• <b>FIXED:</b> Inputting quotes(") into the descriptions of an item in newWorkOrder/pickup/Add throws Error.</p>
                  <p class="changelog">• <b>FIXED:</b> Automatically add an email to newly created custom destinations.</p>
                  <p class="changelog">• <b>CHANGED:</b> Clients require at least one destinations and one destination email.</p>
                  <p class="changelog">• <b>CHANGED:</b> In New Merchandise Orders "Locks and Docks" edited to "LOCKS AND DOCKS".</p>
                  <p class="changelog">• <b>CHANGED:</b> Alphabetize the destination list in  Add Merchandise Orders.</p>
                  <p class="changelog">• <b>CHANGED:</b> All fields in pickup orders work orders are required to be filled in.</p>
                  <p class="changelog">• <b>CHANGED:</b> Creating pickup work order no longer automatically creates an item in outgoing.</p>
                  <p class="changelog">• <b>CHANGED:</b> Collecting more request information from users submitting contact form.</p>
                  <p class="changelog">• <b>CHANGED:</b> Changed the logo on the login page to match the landing page logo.</p>
                  <p class="changelog">• <b>ADDED:</b> Outgoing proof of delivery emails.</p>
                  <p class="changelog">• <b>ADDED:</b> Total weight and quantity to the work orders.</p>
                  <p class="changelog">• <b>ADDED:</b> On site employment application form. </p>
                  <p class="changelog">• <b>ADDED:</b> Password protection on admin tools page.</p>
                  <p class="changelog">• <b>ADDED:</b> When searching the Inventory Table, add "reference" to popup menu.</p>
                </div>
          </div> 

        </section>
          
      </Layout>
                
    </>
  );
}


