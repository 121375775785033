import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';

import moment from 'moment';

// import an edit icon from react-icons fa
import { FaEdit } from 'react-icons/fa';
import { FaCheckCircle, FaObjectGroup } from "react-icons/fa";
import { MdPending, MdCancel } from "react-icons/md";
import { FiTruck } from "react-icons/fi";
import { BsFillPeopleFill } from "react-icons/bs";
import { TbArrowBarRight } from "react-icons/tb";

import useAuth from '../hooks/useAuth';




export default function JobCard({ user, data, editFunc, renderWorkOrder, pullFile }) {

    useEffect(() => {
        //console.log(data)        
    }, [],);   


    const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

    // datetime, description, employee, id, destination, location, service, note, status, client,
       
    return (
        
        <div class="relative block px-6 py-4 overflow-hidden border bg-white shadow border-slate-100 ml-6 mr-6 mb-2 mt-1">
            {data.status=="Completed"
            ? <span class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-lime-300 via-emerald-500 to-green-600" />
            :data.status=="Pending"
            ? <span class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-gray-300 via-gray-500 to-gray-200" />
            :data.status=="Cancelled"
            ? <span class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-pink-600 via-rose-500 to-red-600" />
            :null}

            <div class="justify-between sm:flex">
                <div class="flex w-100">
                    <h5 class="flex text-lg font-bold text-slate-900">
                        {data.type=="1"? // ORDER
                            <span class="bg-black double-outline py-1 px-2 mr-3 "><FiTruck class="mt-1 text-white" /></span>
                        :data.type=="2"? // PERSONNEL
                            <span class="bg-black double-outline py-1 px-2 mr-3 "><BsFillPeopleFill class="mt-1 text-white" /></span>
                        :data.type=="3"? // PICKUP
                            <span class="bg-black double-outline py-1 px-2 mr-3 "><FaObjectGroup class="mt-1 text-white" /></span>                        
                        :data.type=="4"? // OUTGOING
                            <span class="bg-black double-outline py-1 px-2 mr-3 "><TbArrowBarRight class="mt-1 text-white" /></span>
                        :null}
                        <span class="font-mono text-xl underline tracking-tight">
                                {new Date(data.start_datetime).toLocaleString('en-US',date_options).replace(" at"," - ")}
                        </span>
                    </h5>
                    {user=="admin"
                        ? 
                        <>
                            <p class="text-red-600 font-bold ml-3">{data.client}</p>
                            <span onClick={() => editFunc(data)} class="hover:text-blue-600 hover:underline font-bold flex ml-3 text-sm mt-1 text-blue-400 hover:cursor-pointer">
                                <FaEdit />                             
                                <p class="no-underline ml-1 ">Edit</p>
                            </span>
                        </>
                    :null}                
                </div>

                <div class="absolute right-6 mt-2 hidden sm:block">
                    {data.status=="Completed"
                    ? <p class="rounded flex shadow border-2 w-40 justify-center no-underline px-4 py-2 bg-emerald-500 text-white">{data.status}<FaCheckCircle class="ml-1 mt-1" /></p>
                    :data.status=="Pending"
                    ? <p class="rounded flex shadow border-2 w-40 justify-center no-underline px-4 py-2 bg-gray-500 text-white">{data.status}<MdPending class="ml-1 mt-1" /></p>
                    :data.status=="Cancelled"
                    ? <p class="rounded flex shadow border-2 w-40 justify-center no-underline px-4 py-2 bg-red-700 text-white">{data.status}<MdCancel class="ml-1 mt-1" /></p>
                    :null}                    
                </div>

                <div class="flex-col flex absolute right-6 mb-4 bottom-2">
                    <span onClick={() => renderWorkOrder(data)} class="text-blue-500 hover:text-blue-700 hover:underline cursor-pointer justify-end flex">View Work Order</span>
                    {data.status=="Completed"
                    ?<span onClick={() => pullFile(data)}class="text-blue-500 hover:text-blue-700 hover:underline cursor-pointer justify-end flex">View Proof of Delivery</span>
                    :null}
                </div>


            </div>

            <p class="w-100 block text-xs font-medium text-slate-600">{data.description}</p>

            <div class="sm:pr-8">
                <p class="text-sm text-slate-500">
                    <span>{data.service}</span> 
                    <span class="font-bold ml-2">{data.destination}</span>
                    <span class="ml-2">| {data.location}</span>
                </p>
            </div>

            <dl class="flex mt-6">
                <div class="flex flex-col-reverse">
                    <dt class="text-sm font-medium text-slate-600">{data.id}</dt>
                    <dd class="text-xs text-slate-500">ID #</dd>
                </div>

                <div class="flex flex-col-reverse ml-3 sm:ml-6">
                    <dt class="text-sm font-medium text-slate-600">{data.employee?data.employee:"-"}</dt>
                    <dd class="text-xs text-slate-500">Employee</dd>
                </div>
            </dl>
        </div>
    );
    
}