import axios from '../context/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    
    const refresh = async () => {
        const response = await axios.get('/refresh', {
            withCredentials: true,
        });
        setAuth(prev => {
            console.log("REFRESHING-----------------")
            //console.log(JSON.stringify(prev));
            //console.log(response.data);
            return { 
                ...prev, 
                accessToken: response.data.accessToken,
                username: response.data.username,
                name: response.data.name,
                email: response.data.email,
                status: response.data.status,
                divisionMap: response.data.divisionMap,
                emailMap: response.data.emailMap,
                emails: response.data.emails,
            };
        }); 
        return response.data.accessToken;
    };  
    return refresh;
}
    
export default useRefreshToken;