import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';

import moment from 'moment';

// import an edit icon from react-icons fa
import { FaEdit } from 'react-icons/fa';

import useAuth from '../hooks/useAuth';


export default function Bulletin({ user, data, editFunc }) {

    const auth = useAuth();

    useEffect(() => {
        //console.log(data) 
        //console.log(auth);       
    }, [],);   

    const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

    // datetime, description, employee, id, destination, location, service, note, status, client,
    
       
    return (
        
        <section class="h-100 flex w-100">
            <div class="bg-white py-24 sm:py-20 rounded-xl flex w-100 my-auto">
                <div class="mx-auto max-w-7xl px-6 lg:px-8">
                    <div class="mx-auto max-w-2xl lg:text-center">
                    <h2 class="text-lg font-semibold leading-8 tracking-tight text-indigo-600">Locks & Docks</h2>
                    <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A platform for all your logistic needs</p>
                    <p class="mt-6 text-lg leading-8 text-gray-600">                    
                        We are in the process of updating our services and adding new features to our web platform. Expect new developments very soon. 
                    </p>
                    </div>
                    <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl class="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        <div class="relative pl-16">
                        <dt class="text-base font-semibold leading-7 text-gray-200">
                            <div class="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                <svg
                                class="w-6 h-6 fill-current mx-auto flex"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path
                                    fill-rule="evenodd"
                                    d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                    clip-rule="evenodd"
                                ></path>
                                </svg>
                            </div>
                            <Link to="/orders" class="text-black cursor-pointer">Incoming Orders</Link>
                        </dt>
                        <dd class="mt-2 text-base leading-7 text-gray-600">                       
                            A table that displays orders that have been received by our facility. Both delivered and stocked orders can be viewed here.
                        </dd>
                        </div>

                        <div class="relative pl-16">
                        <dt class="text-base font-semibold leading-7 text-gray-200">
                            <div class="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                <svg
                                class="w-6 h-6 fill-current mx-auto flex"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg" 
                                ><path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd"></path>
                                </svg>
                            </div>
                            <Link to="/outgoing" class="text-black cursor-pointer">Outgoing Orders</Link>
                        </dt>
                        <dd class="mt-2 text-base leading-7 text-gray-600">                        
                            A list of your orders that have been picked up by us and are scheduled for delivery to any location you want.
                        </dd>
                        </div>

                        <div class="relative pl-16">
                        <dt class="text-base font-semibold leading-7 text-gray-200">
                            <div class="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                <svg 
                                class="w-6 h-6 fill-current mx-auto flex"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                ><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
                            </div>
                            <Link to="/inventory" class="text-black cursor-pointer">Inventory Items</Link>
                        </dt>
                        <dd class="mt-2 text-base leading-7 text-gray-600">
                            Items that are being warehoused by us for you. These goods can be delivered at any time upon request.
                        </dd>
                        </div>

                        <div class="relative pl-16">
                        <dt class="text-base font-semibold leading-7 text-gray-200">
                            <div class="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                <svg
                                class="w-6 h-6 fill-current flex mx-auto"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
                                <path
                                fill-rule="evenodd"
                                d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                                clip-rule="evenodd"
                                ></path>
                            </svg>
                            </div>
                            <Link to="/jobs" class="text-black cursor-pointer">Scheduled Jobs</Link>
                        </dt>
                        <dd class="mt-2 text-base leading-7 text-gray-600">                        
                            A list of past and currently scheduled jobs. Bills and delivery proofs can be viewed from the job cards.
                        </dd>
                        </div>
                    </dl>
                    </div>
                </div>
            </div>
        </section>
    );
    
}