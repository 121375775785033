import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import moment from 'moment';

import Layout from '../context/layoutPortal.jsx';
import Popup from '../context/layoutPopup.jsx';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAxiosPrivateBlob from '../hooks/useAxiosPrivateBlob';

import MenuEditJobs from '../modules/menuEditJobs';
import JobCard from '../modules/jobCard';
import Loading from '../modules/loading';
 
import Bill from '../modules/bill';
import BillPersonnel from '../modules/billPersonnel';
import BillPickup from '../modules/billPickup';

import { Checkbox } from '@mui/material';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { BsCalendar2Week, BsX } from 'react-icons/bs';


function arrayAlphebetize(array) {
    return array.sort((a, b) => a.localeCompare(b));
}

function removeDuplicatesFromArray(array) {
    return array.filter((a, b) => array.indexOf(a) === b);
} 

export default function Jobs({ notify }) {

    const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }//, hour: 'numeric', minute: 'numeric', second: 'numeric' };

    const auth = useAuth();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const axiosPrivateBlob = useAxiosPrivateBlob();

    const monthList = [["01","January"], ["02","February"], ["03","March"], ["04","April"], ["05","May"], ["06","June"], ["07","July"], ["08","August"], ["09","September"], ["10","October"], ["11","November"], ["12","December"]];

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [editData, setEditData] = useState({}); 
    const [billJobData, setBillJobData] = useState({});   
    const [showEditMenu, setShowEditMenu] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [clientList, setClientList] = useState(arrayAlphebetize(Object.keys(auth.auth.divisionMap)));
    const [destinationList, setDestinationList] = useState(arrayAlphebetize(removeDuplicatesFromArray(arrayAlphebetize(Object.values(auth.auth.divisionMap).flatMap((division) => division.split(';')).slice(1)))));
    const [showWorkOrder, setShowWorkOrder] = useState(false);

    const [filterDate, setFilterDate] = useState("");
    const [filterLimit, setFilterLimit] = useState(25);
    const [filterYear, setFilterYear] = useState("All");
    const [filterMonth, setFilterMonth] = useState("All");    
    const [filterClient, setFilterClient] = useState("All");
    const [filterDestination, setFilterDestination] = useState("All");
    const [filterStatus, setFilterStatus] = useState("All");
    const [filterType, setFilterType] = useState("All");

    const [PDFLink, setPDFLink] = useState("");
    const [PDFBlob, setPDFBlob] = useState(null);
    const [showPdfViewer, setShowPdfViewer] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);    

    const [popupMessage, setPopupMessage] = useState([]);
    const [showPopup, setShowPopup] = useState(false);


    useEffect(() => {
        //console.log(auth);
        fetchJobsData();
    }, []);

    const uploadFile = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    const editJob = (editData) => {
        //console.log(editData);
        try {
            const orders = JSON.parse(editData.orders);
            editData.orders = orders;
        } catch (err) {}
        setEditData(editData);        
        setShowEditMenu(true);        
    }

    const handleDateChange = (e) => {
        //console.log(e);
        setFilterDate(e);
    }
   
    const fetchJobsData = async () => {

        setIsLoading(true); 
        
        //console.log("USER HERE---", auth.auth.name)
        
        const params = {       
            user: auth.auth.name,     
            filter_limit: filterLimit,     
            filter_month: filterMonth=="All"?"%":filterMonth,
            filter_year: filterYear=="All"?"%":filterYear,             
            filter_client: filterClient=="All"?"%":filterClient,
            filter_destination: filterDestination=="All"?"%":filterDestination,
            filter_status: filterStatus=="All"?"%":filterStatus,
            filter_type: filterType=="All"?"%":filterType,
        }

        const response = await axiosPrivate.get("/jobs", {params:params}, { method: "GET", credentials: "include" });
        //console.log(response.data);
        setData(response.data);
        setIsLoading(false); 
    
    }

    const renderWorkOrder = async (jobData) => {
        
        //'console.log("JOB DATA HERE---")
        //'console.log(jobData);

        // -------------
        try {
            var parsedOrders = JSON.parse(jobData.orders);            
        } catch {
            var parsedOrders = jobData.orders;
        }

        const jobDataOrders = Object.keys(parsedOrders).map((d) => { return parsedOrders[d] });

        //console.log("JOB DATA ORDERS HERE---")
        //console.log(jobDataOrders);

        const newJobData = { ...jobData, orders:jobDataOrders };

        setBillJobData(newJobData);
        setShowWorkOrder(true);
        
    }

    const pullDeliveryProof = async (jobData) => {

        setShowPopup(true)
        setPopupMessage(["Please wait","Retrieving delivery proof"])        
    
        //console.log(data);
        setIsLoading(true);   
        let id = jobData.id;
        await axiosPrivateBlob.get("/deliveryProof", {params:{id:id}})
        .then(response => {
            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = window.URL.createObjectURL(file);
            window.open(fileURL);
            setPDFBlob(file);
            setPDFLink(fileURL);
            setShowPdfViewer(true);
            setIsLoading(false);
            setShowPopup(false);
        })
        .catch(error => {
            console.log(error);
            setIsLoading(false);
            setShowPopup(false);
        });        
    }


    return (          

            <Layout>

                <div class="w-100 pr-2 flex px-2 pb-2 border-bottom">                    
                        
                        <div class="mr-auto w-1/4 flex-col">
                            <div class="flex flex-col w-36 mt-3 ml-4">
                                <div class="w-full md:w-100">
                                    {!isLoading?
                                    <div onClick={fetchJobsData} class="w-48 h-10 flex bg-black hover:bg-gray-900 text-white font-bold" type="button">
                                        <p class="flex m-auto">Update Jobs</p>
                                    </div> 
                                    :   
                                    <div class="w-36 h-10 flex bg-black text-white font-bold rounded pointer-events-none" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span> 
                                    </div> 
                                    }
                                </div> 
                            </div>
                            {/* <h3 class=" flex ml-6 h-8">Job List</h3> */}

                            {/* <span class="w-100 inline-block ml-8">Select Date</span>
                            <div class="flex w-full mr-4 ml-4">                                
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Select Date"
                                        value={filterDate}
                                        onChange={(newValue) => {
                                            setFilterDate(newValue);
                                        }}                                
                                        renderInput={(params) => 
                                            <TextField 
                                                {...params} 
                                                sx={{ backgroundColor:"white",  }}
                                            />
                                        }
                                    />
                                </LocalizationProvider>
                            </div> */}

                            {auth.auth.username=="admin"?
                                <div class="mr-auto ml-4 mt-3 w-1/2">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Client
                                    </label>
                                    <select onChange={(e) => setFilterClient(e.target.value)} 
                                        class="shadow w-100 bg-white text-gray-700 rounded border-1 border-gray-500 py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                        <option value="All">All</option>
                                        <optgroup class="w-100 bg-gray-300 h-10" label="&#xA0;"></optgroup>
                                        {clientList.map((client, index) => {
                                            if (client!="admin"){
                                                return (
                                                    <option value={client}>{client}</option>
                                                )
                                            }
                                        })}                            

                                    </select>
                                    {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                            :null}


                                                      
                        </div> 

                        <div class="ml-auto w-1/4 px-4 flex-col">

                            <div class="w-full ">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Year
                                </label>
                                <select 
                                    onChange={(e) => setFilterYear(e.target.value)} 
                                    class="shadow w-100 bg-white text-gray-700 rounded border-1 border-gray-500 py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >                                    
                                    <option value="All">All</option>
                                    <optgroup class="w-100 bg-gray-300 h-10" label="&#xA0;"></optgroup>
                                    <option value="2024">2024</option>
                                    <option value="2023">2023</option>
                                    <option value="2022">2022</option>
                                </select>
                            </div>

                            <div class="w-full">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Month
                                </label>
                                <select 
                                    onChange={(e) => setFilterMonth(e.target.value)} 
                                    class="shadow w-100 bg-white text-gray-700 rounded border-1 border-gray-500 py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                    <option value="All">All</option>
                                    <optgroup class="w-100 bg-gray-300 h-10" label="&#xA0;"></optgroup>
                                    {/* create an option for each month */}
                                    {monthList.map((month, index) => {
                                        return (
                                            <option value={month[0]}>{month[1]}</option>
                                        )
                                    })} 
                                </select>
                            </div>


                        </div>
  
                        <div class="ml-auto w-1/4 px-4 flex-col ">

                            <div class="w-full">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Destination
                                </label>
                                <select onChange={(e) => setFilterDestination(e.target.value)}                                     
                                    class="shadow w-100 bg-white text-gray-700 rounded border-1 border-gray-500 py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                    <option value="All">All</option>
                                    <optgroup class="w-100 bg-gray-300 h-10" label="&#xA0;"></optgroup>
                                    {destinationList.slice(1).map((destination, index) => {                                
                                        return (
                                            <option value={destination}>{destination}</option>
                                        )
                                    })}
                                </select>
                                {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                            </div>
                            
                            <div class="w-full">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Limit
                                </label>
                                <select 
                                    onChange={(e) => setFilterLimit(e.target.value)} 
                                    class="shadow w-100 bg-white text-gray-700 rounded border-1 border-gray-500 py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                    <option value="25" selected>25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>

                              
                        </div>   

                        <div class="ml-auto w-1/4 px-4 flex-col ">

                            <div class="w-full">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Type
                                </label>
                                <select 
                                    onChange={(e) => setFilterType(e.target.value)} 
                                    class="shadow w-100 bg-white text-gray-700 rounded border-1 border-gray-500 py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                    <option value="All">All</option>
                                    <optgroup class="w-100 bg-gray-300 h-10" label="&#xA0;"></optgroup>
                                    <option value="1">Orders</option>
                                    <option value="4">Outgoing</option>
                                    <option value="3">Pickup</option>
                                    <option value="2">Personnel</option>
                                </select>
                                {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                            </div>
                            
                            <div class="w-full">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Status
                                </label>
                                <select 
                                    onChange={(e) => setFilterStatus(e.target.value)} 
                                    class="shadow w-100 bg-white text-gray-700 rounded border-1 border-gray-500 py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                    <option value="All">All</option>
                                    <optgroup class="w-100 bg-gray-300 h-10" label="&#xA0;"></optgroup>
                                    <option value="Completed">Completed</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Cancelled">Cancelled</option>
                                </select>
                            </div>

                              
                        </div>             

                </div>
                
                <div class="flex flex-col overflow-auto">
                    {showEditMenu 
                    ? <MenuEditJobs
                        data={editData} 
                        divisionMap={auth.auth.divisionMap} 
                        onToggleDisplay={setShowEditMenu}
                        uploadFile={uploadFile}
                        updateParent={fetchJobsData}
                        notify={notify}
                    />                      
                    : null} 
                    
                    {showPopup
                    ? <Popup>
                        <div 
                            id="innerEditMenu" 
                            class="h-1/6 w-1/4 inline-block bg-white rounded-lg border-blue-500 border-2 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" 
                            role="dialog" 
                            aria-modal="true" 
                            aria-labelledby="modal-title"
                        >
                            
                            <div class="max-h-12 w-100 px-6 mt-4 flex flex-col">
                                <h3 class="text-blue-700 font-bold w-100">{popupMessage[0]}</h3>                        
                                <h3 id="loading" class="text-black text-md !bg-white">{popupMessage[1]}</h3>
                            </div>                        
                                
                        </div>
                        </Popup>                
                    :null}

                    {showWorkOrder
                    ?   <Popup>
                            {/* close button for popup */}
                            <div class="flex absolute top-5 right-5">
                                <button onClick={() => setShowWorkOrder(false)} class="h-10 w-10 bg-gray-200 hover:bg-gray-300 rounded-full flex m-2">
                                    <BsX class="m-auto" size={25}/>
                                </button>
                            </div>
                            
                            <div id="innerEditMenu" class="h-4/5 pb-0 w-3/4 inline-block bg-gray-200 rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
                                {billJobData.type==1?                                
                                    <Bill 
                                        reference={String(billJobData?.id)}
                                        date={new Date(billJobData?.start_datetime).toLocaleString('en-US',date_options).replace(" at",";")}                                        
                                        //moment(billJobData?.start_datetime).format('YYYY-MM-DD')}
                                        orders={billJobData?.orders} 
                                        client={billJobData?.client}
                                        destination={billJobData?.destination} 
                                        location={billJobData?.location}
                                        service={billJobData?.service}
                                        clientReference={billJobData?.clientRef}
                                        employee={billJobData?.employee}
                                    />
                                :billJobData.type==2?
                                    <BillPersonnel
                                        reference={String(billJobData?.id)}
                                        date={new Date(billJobData?.start_datetime).toLocaleString('en-US',date_options).replace(" at",";")}                                        
                                        //moment(billJobData?.start_datetime).format('YYYY-MM-DD')}
                                        orders={billJobData?.orders} 
                                        client={billJobData?.client}
                                        destination={billJobData?.destination} 
                                        location={billJobData?.location}
                                        service={billJobData?.service}
                                        clientReference={billJobData?.clientRef}
                                        employee={billJobData?.employee}
                                    />
                                :billJobData.type==3?
                                    <BillPickup
                                        reference={String(billJobData?.id)}
                                        date={new Date(billJobData?.start_datetime).toLocaleString('en-US',date_options).replace(" at",";")}                                                                                
                                        orders={billJobData?.orders} 
                                        client={billJobData?.client}
                                        destination={billJobData?.destination} 
                                        location={billJobData?.location}
                                        service={billJobData?.service}
                                        clientReference={billJobData?.clientRef}
                                        employee={billJobData?.employee}
                                        pickedUp={billJobData?.pickedUp}
                                        pickedUpLocation={billJobData?.pickedUpLocation}
                                    />
                                :billJobData.type==4?
                                    <Bill 
                                        reference={String(billJobData?.id)}
                                        date={new Date(billJobData?.start_datetime).toLocaleString('en-US',date_options).replace(" at",";")}                                        
                                        //moment(billJobData?.start_datetime).format('YYYY-MM-DD')}
                                        orders={billJobData?.orders} 
                                        client={billJobData?.client}
                                        destination={billJobData?.destination} 
                                        location={billJobData?.location}
                                        service={billJobData?.service}
                                        clientReference={billJobData?.clientRef}
                                        employee={billJobData?.employee}
                                    />
                                :null}
                            </div>
                        </Popup>
                    : null}


                    {/* <button onClick={testFetch} class="bg-black text-white">FETCH JOBS</button> */}
                    {data.length>0?
                        data.map((d) => {
                            return (
                            <JobCard 
                                user={auth.auth.username}
                                data={d}
                                editFunc={editJob}
                                renderWorkOrder={renderWorkOrder}    
                                pullFile={pullDeliveryProof}                            
                            />
                            )
                        })
                    :
                    isLoading?
                        <section className="flex items-center h-full p-16 relative w-100">
                        <div className="container w-100 flex flex-col items-center justify-center px-5 mx-auto my-20">
                            <div className="max-w-md text-center">
                                <h2 className="mb-8 font-extrabold text-5xl dark:text-gray-600">
                                    <span className="sr-only"></span>LOADING
                                </h2>
                                <p className="text-2xl font-semibold md:text-3xl">Searching for jobs...</p>
                                {/* <p className="mt-4 mb-8 dark:text-gray-400">But dont worry, you can find plenty of other things on our homepage.</p> */}
                                <div className="max-w-md text-center h-4 w-100 pt-4 px-12">
                                    <Loading type={3} class="w-100 h-32"/>
                                </div>
                            </div>
                        </div>
                        </section>
                        :
                        <section className="flex items-center h-full p-16 relative w-100">
                        <div className="container w-100 flex flex-col items-center justify-center px-5 mx-auto my-20">                          
                            <div className="max-w-md text-center">                              
                                <h2 className="mb-8 font-extrabold text-5xl dark:text-gray-600">                                  
                                    <span className="sr-only">Error</span>NO DATA
                                </h2>
                                <p className="text-2xl font-semibold md:text-3xl">No data available for this search.</p>
                                
                                {/* <p className="mt-4 mb-8 dark:text-gray-400">But dont worry, you can find plenty of other things on our homepage.</p> */}
                            </div>
                        </div>
                        </section>
                }   
                </div>                   
            </Layout>
        
    )
}