import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';

import moment from 'moment';

import useAuth from '../hooks/useAuth';

import Table from '../modules/table';
import Searcher from '../modules/searcher.jsx'
import MenuEditOutgoing from '../modules/menuEditOutgoing.jsx';
import MenuDownloadData from '../modules/menuDownloadData';
import Loading from '../modules/loading';
import Layout from '../context/layoutPortal.jsx';
import Popup from '../context/layoutPopup.jsx';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAxiosPrivateBlob from '../hooks/useAxiosPrivateBlob';

import { HiOutlineRefresh } from 'react-icons/hi';
import Tooltip from '@mui/material/Tooltip';
import { BsX } from 'react-icons/bs';

import { Checkbox } from '@mui/material';


function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum;// /1000;
}

function arrayAlphebetize(array) {
    return array.sort((a, b) => a.localeCompare(b));
}

function removeDuplicatesFromArray(array) {
    return array.filter((a, b) => array.indexOf(a) === b);
}


export default function Outgoing({ notify }) {

    const auth = useAuth();    
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const axiosPrivateBlob = useAxiosPrivateBlob();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [editData, setEditData] = useState({});
    
    const [clientList, setClientList] = useState(arrayAlphebetize(Object.keys(auth.auth.divisionMap)));
    const [destinationList, setDestinationList] = useState(arrayAlphebetize(removeDuplicatesFromArray(arrayAlphebetize(Object.values(auth.auth.divisionMap).flatMap((division) => division.split(';')).slice(1)))));
    const [showEditMenu, setShowEditMenu] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isSelected, setIsSelected] = useState(false);
    const [showTableMenu, setShowTableMenu] = useState(false);
    const [sort, setSort] = useState("up");

    const [searchGroup, setSearchGroup] = useState("");
    const [searchValue, setSearchValue] = useState("");

    const [filterClient, setFilterClient] = useState("All");
    const [filterDestination, setFilterDestination] = useState("All");

    const [PDFLink, setPDFLink] = useState("");
    const [PDFBlob, setPDFBlob] = useState(null);
    const [showPdfViewer, setShowPdfViewer] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    const [popupMessage, setPopupMessage] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showDownloadDataMenu, setShowDownloadDataMenu] = useState(false);

    const [totalWeight, setTotalWeight] = useState(0.0);

    const [datasets, setDatasets] = useState({
        from:[],
        to:[], 
        carriers:[]
    });

    const searchPreferences = localStorage.getItem("searchPreferences") ? JSON.parse(localStorage.getItem("searchPreferences")) 
    : {
        inventory: true,
        pending: true,
        delivered: false,
        damaged: false,
        delayed: false,
        lost: false,
    };

    const [filterStatus, setFilterStatus] = useState(searchPreferences);
    
    const columns = [
        { 
            id: 'edit', 
            label: 'EDIT', 
            minWidth: 50,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
        { 
            id: 'doc', 
            label: 'DOC', 
            minWidth: 50,
            align: 'left',
            maxWidth: 50,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'id',
            //label: 'Size\u00a0(km\u00b2)',
            label: 'ID',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'datetime',
            label: 'DATE/TIME',
            minWidth: 220,
            align: 'left',
            render: value => moment(new Date(value)).format('YYYY-MM-DD HH:mm:ss')
            //format: (value) => value.toLocaleString('en-US'),
        },        
        {
            id: 'client',
            label: 'CLIENT',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toFixed(2),
        },
        {
            id: 'from',
            label: 'FROM',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'to',
            label: 'TO',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'reference',
            label: 'REFERENCE',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },        
        {
            id: 'unit',
            label: 'UNIT',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'quantity',
            label: 'QUANTITY',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'weight',
            label: 'WEIGHT',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'weightType',
            label: 'LBS/KGS',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
        // {
        //     id: 'carrier',
        //     label: 'CARRIER',
        //     minWidth: 170,
        //     align: 'left',
        //     format: (value) => value.toLocaleString('en-US'),
        // },
        // {
        //     id: 'waybill',
        //     label: 'WAYBILL',
        //     minWidth: 170,
        //     align: 'left',
        //     format: (value) => value.toLocaleString('en-US'),
        // },
        {
            id: 'status',
            label: 'STATUS',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'checked',
            label: 'LAST UPDATE',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'description',
            label: 'DESCRIPTION',
            minWidth: 250,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        }, 
        {
            id: 'note',
            label: 'NOTE',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },        
    ];     

    useEffect(() => {       
        //console.log(auth);
        //console.log(data);
        fetchOutgoingData();
    }, []);        
    
    const fetchOutgoingData = async () => {      
        setIsLoading(true); 
        setData([]);
        
        const params = {
            user: auth.auth.name,
            filter_client: filterClient=="All"?"%":filterClient,
            filter_destination: filterDestination=="All"?"%":filterDestination,
            filter_status: filterStatus,
            search_group: searchGroup,
            search_value: searchValue,
        }

        const response = await axiosPrivate.get("/outgoing", {params:params}, { method: "GET", credentials: "include" });                       

        const fromList = [];
        const toList = [];
        const unitsList = [];
        const data = [];

        let total_weight = 0.0;

        //console.log(response.data)

        for (let i=0; i<response.data.length; ++i) {
            if(response.data[i].from) fromList.push(response.data[i].from);
            if(response.data[i].to) toList.push(response.data[i].to);
            if(response.data[i].unit) unitsList.push(response.data[i].unit);

            if(response.data[i].weight) {
                if(response.data[i].weightType=="KGS"){                    
                    total_weight = total_weight + Number(response.data[i].weight)/2.20462
                } else {
                    total_weight = total_weight + Number(response.data[i].weight)*1
                }
            }

            data.push({
                ...response.data[i], 
                checked: moment(response.data[i].checked).format('YYYY-MM-DD HH:mm:ss'), 
                datetime: moment(response.data[i].datetime).format('YYYY-MM-DD HH:mm:ss')
            });            
        }; 
        
        //console.log(response);
        setTotalWeight(total_weight.toFixed(2));
        setDatasets({            
            from: [... new Set(fromList)],
            to: [... new Set(toList)],
            unit: [... new Set(unitsList)],            
        })
        setData(data);
        setIsLoading(false);
    }

    const onSubmitSearch = async (group,value,filters) => {

        if(!value) fetchOutgoingData();
        else {

            setIsLoading(true);
            setData([]);

            const params = {
                user: auth.auth.name,
                filter_client: filterClient=="All"?"%":filterClient,
                filter_destination: filterDestination=="All"?"%":filterDestination,
                filter_status: filterStatus,
                search_group: group,
                search_value: value,
            }
            //if (group == "reference" || group == "waybill" || group == "id" || group == "units") {
            const data = [];
            const response = await axiosPrivate.get("/outgoing/search", {params:params}, { method: "GET", credentials: "include" });                       

            let total_weight = 0.0;

            for (let i=0; i<response.data.length; ++i) {
    
                if(response.data[i].weight) {
                    if(response.data[i].weightType=="KGS"){                    
                        total_weight = total_weight + Number(response.data[i].weight)/2.20462
                    } else {
                        total_weight = total_weight + Number(response.data[i].weight)*1
                    }
                }
    
                data.push({
                    ...response.data[i], 
                    checked: moment(response.data[i].checked).format('YYYY-MM-DD HH:mm:ss'), 
                    datetime: moment(response.data[i].datetime).format('YYYY-MM-DD HH:mm:ss')
                });            
            }; 

            setTotalWeight(total_weight.toFixed(2));
            //console.log(new_data);
            setData(data);
            setIsLoading(false);
        }
    } 

    const uploadFile = (event) => {
        //console.log(event.target.files[0]);
        selectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    const editTableRow = (editData, code) => {
        setEditData(editData);
        setShowEditMenu(true);
    }

    const onSort = (colName) => {

        // ascending/descending: id/reference, quantity, weight, datetime, checked
        
        // alphabetical: destination, supplier, unit type, carrier, client
  
        // let colName = col.target.innerText.toLowerCase();
    
        let data_list = JSON.parse(JSON.stringify(data));
  
        //console.log(data_list);       
  
        
        if (colName=="waybill"||colName=="note"||colName=="doc"||colName=="edit"||colName=="description") {
            sorted_data = data_list
        }
  
        // NUMERICAL
        if (colName=="quantity"||colName=="id") {
  
            if (sort=="up") {
                var sorted_data = data_list.sort((a, b) => Number(b[colName])-Number(a[colName]));
            }
            if (sort=="down") {
                var sorted_data = data_list.sort((a, b) => Number(a[colName])-Number(b[colName]));
            }          
        }
  
        // WEIGHT
        if (colName=="weight") {
            if (sort=="up") {
                var sorted_data = data_list.sort((a, b) => Number(b[colName].split(" ")[0])-Number(a[colName].split(" ")[0]));
            }
            if (sort=="down") {
                var sorted_data = data_list.sort((a, b) => Number(a[colName].split(" ")[0])-Number(b[colName].split(" ")[0]));
            }  
        }
  
        // REFERENCE
        if (colName=="reference") {
  
            if (sort=="up") {
                var sorted_data = data_list.sort((a, b) => Number(b[colName].replace("-",""))-Number(a[colName].replace("-","")));
            }
            if (sort=="down") {
                var sorted_data = data_list.sort((a, b) => Number(a[colName].replace("-",""))-Number(b[colName].replace("-","")));
            }  
        }
  
        // ALPHABETICAL
        if (colName=="destination"||colName=="supplier"||colName=="unit"||colName=="carrier"||colName=="status"||colName=="client"||colName=="weightType"||colName=="from"||colName=="to") {
  
            if (sort=="up") {
                var sorted_data = data_list.sort(function SortArrayObjects(x, y){
                    if (x[colName] < y[colName]) {return -1;}
                    if (x[colName] > y[colName]) {return 1;}
                    return 0;
                })
            }
            if (sort=="down") {
                var sorted_data = data_list.sort(function SortArrayObjects(x, y){
                    if (x[colName] > y[colName]) {return -1;}
                    if (x[colName] < y[colName]) {return 1;}
                    return 0;
                })
            }
        }
  
        // CHRONOLOGICAL
        if (colName=="datetime"||colName=="checked") {
  
            if (sort=="up") {
                var sorted_data = data_list.sort((a, b) => toTimestamp(b["datetime"])-toTimestamp(a["datetime"]));
            }
            if (sort=="down") {
                var sorted_data = data_list.sort((a, b) => toTimestamp(a["datetime"])-toTimestamp(b["datetime"]));
            }      
        }

        setData(sorted_data);
        setSort(sort=="up"?"down":"up");   
    }

    const savePreferences = async (preferences) => {
        setIsLoading(true)
        //console.log(preferences);
        // save preferences to localStorage
        localStorage.setItem("searchPreferences", JSON.stringify(filterStatus));
        setIsLoading(false)
        notify("Preferences saved", "success");
    }

    const fetchPackingslip = async (data) => {
        //console.log(data);
        setIsLoading(true);  
        
        setShowPopup(true)
        setPopupMessage(["Please wait","Retrieving packing slip"])

        let id = data.id;
        await axiosPrivateBlob.get("/packingSlip", {params:{id:id}})
        .then(response => {
            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = window.URL.createObjectURL(file);
            window.open(fileURL);
            setPDFBlob(file);
            setPDFLink(fileURL);
            setShowPdfViewer(true);
            setIsLoading(false);
            setShowPopup(false)
        })
        .catch(error => {
            console.log(error);
            setIsLoading(false);
            setShowPopup(false)
        });        
    }

    const displayDownloadDataMenu = () => {
        setShowDownloadDataMenu(!showDownloadDataMenu);
    }



    return (

            <Layout>
                
                {/* <button class="bg-black text-white" onClick={testFetch}>FETCH ORDERS</button> */}
                <p class="text-sm font-bold mb-0 pb-0">Outgoing Orders</p>
                <div class="flex w-100 py-2">
                    <Searcher 
                        datasets={datasets}    
                        notify={notify}                        
                        onEnter={onSubmitSearch}
                    />


                    {isLoading?
                    <div 
                        class="flex bg-gray-400 rounded-2 p-3 cursor-pointer" 
                        onClick={null}
                    >
                        <Tooltip title="Reload Table" placement="bottom"
                        PopperProps={{
                            modifiers: [
                                {
                                    name: "offset",
                                    options: {
                                        offset: [0, 10],
                                    },
                                },
                            ],
                          }}>
                            <button >
                                    <Loading type={1} size={20} />
                            </button>
                        </Tooltip>
                    </div>
                    : //----------------------------
                    <div 
                        class="flex bg-gray-300 rounded-2 p-3 hover:bg-gray-400 cursor-pointer" 
                        onClick={fetchOutgoingData}
                    >
                        <Tooltip title="Reload Table" placement="bottom"
                        PopperProps={{
                            modifiers: [
                                {
                                    name: "offset",
                                    options: {
                                        offset: [0, 10],
                                    },
                                },
                            ],
                          }}>
                            <button 
                                >
                                     <HiOutlineRefresh size={20} color={"inherit"}/>
                            </button>
                        </Tooltip>
                    </div>}

                    <div class="flex ml-auto mt-auto">
                        <div 
                            onClick={displayDownloadDataMenu}
                            class="mr-2 hover:underline font-bold w-36 h-10 flex text-black hover:text-blue-800 " type="button">
                            <p class="flex m-auto">Download Data</p>
                        </div> 

                        
                        <div 
                            onClick={() => setShowTableMenu(true)} 
                            class="w-36 h-10 hover:underline font-bold flex text-black hover:text-blue-800 " type="button">
                            <p class="flex m-auto">Table Filters</p>
                        </div> 
                    </div>
                </div>     

                {showPopup
                ? <Popup>
                    <div 
                        id="innerEditMenu" 
                        class="h-1/6 w-1/4 inline-block bg-white rounded-lg border-blue-500 border-2 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" 
                        role="dialog" 
                        aria-modal="true" 
                        aria-labelledby="modal-title"
                    >
                        
                        <div class="max-h-12 w-100 px-6 mt-4 flex flex-col">
                            <h3 class="text-blue-700 font-bold w-100">{popupMessage[0]}</h3>                        
                            <h3 id="loading" class="text-black text-md !bg-white">{popupMessage[1]}</h3>
                        </div>                        
                            
                    </div>
                    </Popup>                
                :null}

                {showDownloadDataMenu
                ? <MenuDownloadData 
                    data={data}
                    toggleDisplay={displayDownloadDataMenu}
                />
                :null}

                {showTableMenu
                ? <Popup>
                    <div class="flex absolute top-5 right-5">
                        <button onClick={() => setShowTableMenu(false)} class="h-10 w-10 bg-gray-200 hover:bg-gray-300 rounded-full flex m-2">
                            <BsX class="m-auto" size={25}/>
                        </button>
                    </div>
                    <div id="innerEditMenu" class="h-4/6 w-1/2 pt-3 pb-0 inline-block bg-gray-200 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
                        
                        <div class="max-h-12 flex w-100 px-6 pb-2 border-b-2 border-black">
                            <h3 class="text-black">Order Filters</h3>
                        </div>                        

                        <div class="flex h-[calc(100%-9rem)] overflow-y-scroll">
                            <div class="h-100 flex flex-col w-1/2 px-12 py-6 ml-6 border-right border-r-2 border-black">
                                {/* <p class="ml-2 font-bold">Filter Status</p> */}
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, inventory:!filterStatus.inventory})} checked={filterStatus.inventory}/>
                                    <p class="align-middle ml-2 my-auto flex">Inventory</p>
                                </div>
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, pending:!filterStatus.pending})} checked={filterStatus.pending}/>
                                    <p class="align-middle ml-2 my-auto flex">Pending</p>
                                </div>
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, delivered:!filterStatus.delivered})} checked={filterStatus.delivered}/>
                                    <p class="align-middle ml-2 my-auto flex">Delivered</p>
                                </div>
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, delayed:!filterStatus.delayed})} checked={filterStatus.delayed}/>
                                    <p class="align-middle ml-2 my-auto flex">Delayed</p>
                                </div>
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, damaged:!filterStatus.damaged})} checked={filterStatus.damaged}/>
                                    <p class="align-middle ml-2 my-auto flex">Damaged</p>
                                </div>
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, lost:!filterStatus.lost})} checked={filterStatus.lost}/>
                                    <p class="align-middle ml-2 my-auto flex">Lost</p>
                                </div>
                            </div>

                            <div class="h-100 flex flex-col w-1/2 px-12 py-6 ml-6 border-black">

                                {auth.auth.username=="admin"?
                                <div class="w-100">
                                    <label class="font-bold text-red-600 block uppercase tracking-wide text-xs mb-2" for="grid-first-name">
                                        Client *
                                    </label>
                                    <select onChange={(e) => setFilterClient(e.target.value)} 
                                        class="w-100 bg-white text-gray-700 border border-red-500 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                        <option value="All">All</option>
                                        <option class="py-2" disabled>---------------</option>
                                        {clientList.map((client, index) => {                              
                                            return (
                                                <option value={client} selected={client==filterClient?true:false}>{client}</option>
                                            )                                            
                                        })}                            

                                    </select>
                                    {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                :null}                                
                                
                                <div class="w-full">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        From
                                    </label>
                                    <select onChange={(e) => setFilterDestination(e.target.value)} 
                                        class="w-100 bg-white text-gray-700 border border-red-500 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                        <option value="All">All</option>
                                        <option class="py-2" disabled>---------------</option>
                                        {destinationList.slice(1).map((destination, index) => {                                
                                            return (
                                                <option value={destination} selected={destination==filterDestination?true:false}>{destination}</option>
                                            )
                                        })}
                                    </select>
                                    {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                
                            </div>
                        </div>

                        <div class="flex ">
                            <div class="max-h-12 flex px-6 pt-6 border-t-2 border-black">
                                <div class="w-100 mt-auto flex">
                                    {!isLoading?
                                    <div onClick={savePreferences} class=" ml-auto w-36 h-10 flex bg-gray-500 hover:bg-gray-700 text-white font-bold rounded" type="button">
                                        <p class="flex m-auto">Save</p>
                                    </div> 
                                    :   
                                    <div class="  w-36 h-10 flex bg-blue-800 text-white font-bold rounded pointer-events-none ml-auto" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span> 
                                    </div> 
                                    }
                                </div> 
                            </div>

                            <div class="max-h-12 flex w-100 px-6 pt-6 border-t-2 border-black">
                                <div class="w-100 mt-auto flex">
                                    {!isLoading?
                                    <div onClick={fetchOutgoingData} class=" ml-auto w-36 h-10 flex bg-blue-800 hover:bg-blue-900 text-white font-bold rounded" type="button">
                                        <p class="flex m-auto">Update</p>
                                    </div> 
                                    :   
                                    <div class="  w-36 h-10 flex bg-blue-800 text-white font-bold rounded pointer-events-none ml-auto" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span> 
                                    </div> 
                                    }
                                </div> 
                            </div>
                        </div>
                            
                    </div>
                  </Popup>                
                :null}

                {showEditMenu 
                ? <MenuEditOutgoing
                    data={editData}
                    divisionMap={auth.auth.divisionMap} 
                    onToggleDisplay={setShowEditMenu}
                    uploadFile={uploadFile}
                    updateParent={fetchOutgoingData}
                    notify={notify}
                />                      
                : null}       

                <div class="h-5/6 w-100 mt-1">
                    <Table 
                        username={auth.auth.username}
                        loading={isLoading}
                        data={data} 
                        columns={columns} 
                        editFunc={editTableRow}
                        fileFunc={fetchPackingslip}
                        onSort={onSort}
                        totalWeight={totalWeight}
                    />
                </div>
            </Layout>

    )
}