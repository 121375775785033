import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../static/assets/images/image832.png';
import logo2 from '../../static/assets/images/bollard.png';

import useAuth from '../hooks/useAuth';

import Tooltip from '@mui/material/Tooltip';

import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import TocOutlinedIcon from '@mui/icons-material/TocOutlined';

export default function Sidebar() {

  const childRef = useRef();
  //let location = useLocation();

  const auth = useAuth();

  useEffect(() => {
    //const page = location.pathname;
    //document.body.classNameList.add('is-loaded')
    //childRef.current.init();
    //trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    //console.log(auth);
  }, []);


  const [sidebarOpen, setSidebarOpen] = useState(true);


  return (

    <>

    {/* DIAMOND ICON */}
    {/* <svg class="w-6 h-5 text-gray-900 flex-shrink-0 group-hover:text-gray-900 transition duration-75" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gem" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M378.7 32H133.3L256 182.7L378.7 32zM512 192l-107.4-141.3L289.6 192H512zM107.4 50.67L0 192h222.4L107.4 50.67zM244.3 474.9C247.3 478.2 251.6 480 256 480s8.653-1.828 11.67-5.062L510.6 224H1.365L244.3 474.9z" data-darkreader-inline-fill="" style={{darkreaderInlineFill:"currentColor"}}></path></svg> */}

    {/* GRID THING ICON */}
    {/* <svg class="w-6 h-6 text-gray-900 flex-shrink-0 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-darkreader-inline-fill="" style={{darkreaderInlineFill:"currentColor"}}><path fill-rule="evenodd" d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z" clip-rule="evenodd"></path></svg> */}

    {sidebarOpen
    ?
    <div id="sidebar" class="shadow fixed z-20 h-full top-0 left-0 flex lg:flex  w-18 transition-width duration-75" aria-label="Sidebar">
    {/* </div><div onMouseEnter={HoverSidebar} id="sidebar" class="h-full w-64 transition-width duration-75" aria-label="Sidebar"> */}
        <div class="relative flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white pt-0">
          <div class="scrollbar-hide flex-1 flex flex-col pt-2 pb-1 overflow-y-auto">
            <div class="flex-1 px-3 bg-white divide-y space-y-1">
              <ul class="space-y-2 pb-1 px-0">
                <li class="py-3">

                  <button onClick={() => setSidebarOpen(!sidebarOpen)} class="flex mx-auto">
                    {/* create an svg and path of a left pointing arrow */}
                    <svg class="mx-auto flex h-6 w-6 text-gray-900 hover:bg-gray-100 rounded" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path class="tailwind-wind" d="M5 12h14M12 19l7-7-7-7" />
                    </svg>                    
                  </button>
                  
                  {/* <Link to="/xxx" class="no-underline text-base text-gray-900 font-normal rounded-lg flex items-center mt-4 hover:bg-gray-100 group">
                    <img src={logo2} alt="logo" width={30} />
                  </Link> */}

                </li>
                {/* <li>
                  <form action="#" method="GET" class="lg:hidden">
                    <label for="mobile-search" class="sr-only">Search</label>
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg class="w-5 h-5 text-gray-900" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-darkreader-inline-fill="" style={{darkreaderInlineFill:"currentColor"}}><path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>
                      </div>
                      <input type="text" name="email" id="mobile-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-600 block w-full pl-10 p-2.5" placeholder="Search"/>
                    </div>
                  </form>
                </li> */}
                {/* <Tooltip title="Dashboard" placement="right">
                  <li>
                      <Link to="/dashboard" class="no-underline text-base text-gray-900 font-normal rounded-lg flex items-center py-2 hover:bg-gray-100 group">
                          <svg 
                            class="mx-auto flex" 
                            width="20" 
                            height="20" 
                            fill="currentColor" 
                            viewBox="0 0 2048 1792" 
                            xmlns="http://www.w3.org/2000/svg"
                          >
                              <path 
                                d="M1070 1178l306-564h-654l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71 348 71 286 191 191 286 71 348z">
                              </path>
                          </svg>
                      </Link>
                  </li>
                </Tooltip> */}

                <Tooltip title="Dashboard" placement="right">
                  <li>
                    <Link to="/dashboard" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                    <svg
                      class="w-5 h-5  fill-current flex ml-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                      fill-rule="evenodd"
                      d="M10.21 11.69l3.06-5.64H7.16l-3.06 5.64h6.11zm7.22-2.82q0 1.82-0.71 3.48t-1.91 2.86-2.86 1.91-3.48 0.71-3.48-0.71-2.86-1.91-1.91-2.86-0.71-3.48 0.71-3.48 1.91-2.86 2.86-1.91 3.48-0.71 3.48 0.71 2.86 1.91 1.91 2.86 0.71 3.48z"
                      clip-rule="evenodd"
                    ></path>
                    </svg>
                    </Link>
                  </li>
                </Tooltip>

                <Tooltip title="Orders" placement="right">
                  <li>
                    <Link to="/orders" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                    <svg
                      class="w-6 h-6 fill-current mx-auto flex"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    </Link>
                  </li>
                </Tooltip>

                <Tooltip title="Outgoing" placement="right">
                <li>
                  <Link to="/outgoing" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 group transition duration-75 flex items-center py-2">
                    <svg
                      class="w-6 h-6 fill-current mx-auto flex"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg" 
                    ><path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd"></path>
                    </svg>
                  </Link>
                </li>
                </Tooltip>

                <Tooltip title="Inventory" placement="right">
                <li>
                  <Link to="/inventory" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 group transition duration-75 flex items-center py-2">
                    <svg 
                      class="w-6 h-6 fill-current mx-auto flex"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    ><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
                  </Link>
                </li>
                </Tooltip>
                
                <Tooltip title="Jobs" placement="right">
                  <li>
                    <Link to="/jobs"  class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                      {/* <svg class="w-6 h-6 text-gray-900 flex-shrink-0 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-darkreader-inline-fill="" style={{darkreaderInlineFill:"currentColor"}}><path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path><path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path></svg> */}
                      <svg
                        class="w-6 h-6 fill-current flex mx-auto"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
                        <path
                          fill-rule="evenodd"
                          d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </Link>
                  </li>
                </Tooltip>

                <Tooltip title="Activity" placement="right">
                  <li>
                    <Link to="/activity" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">                      
                      <svg
                        class="w-6 h-6 fill-current flex mx-auto"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </Link>
                  </li>
                </Tooltip>
                {/* <Tooltip title="Status" placement="right">
                  <li>
                    <Link to="/status" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex mx-auto h-6 w-6 cursor-pointer text-gray-900 transition-all hover:text-blue-600">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                    </svg>
                    </Link>
                  </li>
                </Tooltip> */}
                <Tooltip title="Data" placement="right">
                  <li>
                    <Link to="/data" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                      <svg 
                        class="w-6 h-6 fill-current mx-auto flex"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      ><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg>
                    </Link>
                  </li>
                </Tooltip>
              </ul>

              <ul class="space-y-2 pb-2 px-0 py-1">

                {auth.auth.username=="admin"
                ?
                <>
                  <Tooltip title="New Merchandise" placement="right">
                  <li>
                    <Link to="/addMerchandise" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">                    
                      <svg 
                        class="w-6 h-6 fill-current mx-auto flex"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      ><path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path><path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path></svg>
                    </Link>
                  </li>
                  </Tooltip>

                  {/* <Tooltip title="New Pick Ups" placement="right">
                  <li>
                    <Link to="/pickupForm" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">                    
                      <svg 
                        class="w-6 h-6 fill-current mx-auto flex"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      ><path stroke-linecap="round" stroke-linejoin="round" d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>
                    </Link>
                  </li>
                  </Tooltip> */}

                  <Tooltip title="Create Work Order" placement="right">
                  <li>
                    <Link to="/newWorkOrder" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 group transition duration-75 flex items-center py-2">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 flex mx-auto group-hover:text-indigo-400">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                      </svg>   */}

                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex mx-auto h-6 w-6 cursor-pointer text-gray-900 transition-all hover:text-blue-600" data-darkreader-inline-stroke="">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"></path>
                      </svg>
                    </Link>
                  </li>
                  </Tooltip>

                  <Tooltip title="Create New User" placement="right">
                  <li>
                    <Link to="/registration" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                      {/* <svg class="w-6 h-6 text-gray-900 flex-shrink-0 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-darkreader-inline-fill="" style={{darkreaderInlineFill:"currentColor"}}><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg> */}
                      <svg width="20" height="20" fill="currentColor" class="m-auto" viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1024 1131q0-64-9-117.5t-29.5-103-60.5-78-97-28.5q-6 4-30 18t-37.5 21.5-35.5 17.5-43 14.5-42 4.5-42-4.5-43-14.5-35.5-17.5-37.5-21.5-30-18q-57 0-97 28.5t-60.5 78-29.5 103-9 117.5 37 106.5 91 42.5h512q54 0 91-42.5t37-106.5zm-157-520q0-94-66.5-160.5t-160.5-66.5-160.5 66.5-66.5 160.5 66.5 160.5 160.5 66.5 160.5-66.5 66.5-160.5zm925 509v-64q0-14-9-23t-23-9h-576q-14 0-23 9t-9 23v64q0 14 9 23t23 9h576q14 0 23-9t9-23zm0-260v-56q0-15-10.5-25.5t-25.5-10.5h-568q-15 0-25.5 10.5t-10.5 25.5v56q0 15 10.5 25.5t25.5 10.5h568q15 0 25.5-10.5t10.5-25.5zm0-252v-64q0-14-9-23t-23-9h-576q-14 0-23 9t-9 23v64q0 14 9 23t23 9h576q14 0 23-9t9-23zm256-320v1216q0 66-47 113t-113 47h-352v-96q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v96h-768v-96q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v96h-352q-66 0-113-47t-47-113v-1216q0-66 47-113t113-47h1728q66 0 113 47t47 113z">
                          </path>
                      </svg>
                    </Link>
                  </li>
                  </Tooltip>

                  <Tooltip title="Admin Tools" placement="right">
                  <li>
                    <Link to="/adminTools" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 group transition duration-75 flex items-center py-2">                    
                      <svg
                        class="w-6 h-6 flex mx-auto fill-current"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
                        ></path>
                      </svg>
                    </Link>
                  </li>
                  </Tooltip>

                  <Tooltip title="Settings" placement="right">
                  <li>
                    <Link to="/settings" 
                    class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 group transition duration-75 flex items-center py-2">                    
                      <svg 
                        class="w-6 h-6 fill-current mx-auto flex"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      ><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z" clip-rule="evenodd"></path></svg>                    
                    </Link>
                  </li>
                  </Tooltip>

                </>
                :null}

              </ul>

              <ul class="space-y-2 pb-2 px-0 py-1 ">
                <Tooltip title="Help" placement="right">
                  <li>
                    <Link to="/help" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">                      
                      <svg width="20" fill="currentColor" height="20" class="h-6 w-6 flex mx-auto" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1088 1256v240q0 16-12 28t-28 12h-240q-16 0-28-12t-12-28v-240q0-16 12-28t28-12h240q16 0 28 12t12 28zm316-600q0 54-15.5 101t-35 76.5-55 59.5-57.5 43.5-61 35.5q-41 23-68.5 65t-27.5 67q0 17-12 32.5t-28 15.5h-240q-15 0-25.5-18.5t-10.5-37.5v-45q0-83 65-156.5t143-108.5q59-27 84-56t25-76q0-42-46.5-74t-107.5-32q-65 0-108 29-35 25-107 115-13 16-31 16-12 0-25-8l-164-125q-13-10-15.5-25t5.5-28q160-266 464-266 80 0 161 31t146 83 106 127.5 41 158.5z">
                          </path>
                      </svg>
                    </Link> 
                  </li>
                </Tooltip>
                <Tooltip 
                  title="Profile" placement="right"
                  PopperProps={{
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 0],
                            },
                        },
                    ],
                  }}
                  >
                  <li>
                    <Link to="/profile" 
                      //class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                      class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 group transition duration-75 flex items-center py-2">                    
                      <svg 
                        class="w-6 h-6 fill-current mx-auto flex"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      ><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
                    </Link>
                  </li>
                </Tooltip>
              </ul>

          </div>
        </div>
      </div>
    </div>
    :
    <div id="sidebar" class="shadow fixed z-20 h-full top-0 left-0 flex lg:flex flex-shrink-0 flex-col w-56 transition-width duration-75" aria-label="Sidebar">
    {/* </div><div onMouseEnter={HoverSidebar} id="sidebar" class="h-full w-64 transition-width duration-75" aria-label="Sidebar"> */}
        <div class="relative flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white pt-0">
          <div class="scrollbar-hide flex-1 flex flex-col pt-2 pb-4 overflow-y-auto">
            <div class="flex-1 px-3 bg-white divide-y space-y-1">
              <ul class="space-y-2 pb-2 px-0">
                <li class="py-3">

                  <div class="flex p-1 ">                    
                    <Link to="/dashboard" class=" w-1/2 no-underline text-base text-gray-900 font-normal rounded hover:bg-gray-100 group">
                      <img src={logo} alt="logo" width={120} height={60}/>
                    </Link>
                    <button onClick={() => setSidebarOpen(!sidebarOpen)} class="flex ml-auto">
                      <svg class="mx-auto flex h-6 w-6 text-gray-900 hover:bg-gray-100 rounded" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path class="tailwind-wind" d="M19 12H5m7-7l-7 7 7 7" />
                      </svg>
                    </button>
                  </div>

                </li>
                {/* <li>
                  <form action="#" method="GET" class="lg:hidden">
                    <label for="mobile-search" class="sr-only">Search</label>
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg class="w-5 h-5 text-gray-900" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-darkreader-inline-fill="" style={{darkreaderInlineFill:"currentColor"}}><path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>
                      </div>
                      <input type="text" name="email" id="mobile-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-600 block w-full pl-10 p-2.5" placeholder="Search"/>
                    </div>
                  </form>
                </li> */}
                {/* DASHBOARD */}
                <li>
                  <Link to="/dashboard" class="no-underline text-base text-gray-900 font-normal rounded-lg flex items-center py-2 hover:bg-gray-100 group">
                    <svg class=" w-6 h-6 fill-current" fill="currentColor" viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1070 1178l306-564h-654l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71 348 71 286 191 191 286 71 348z">
                        </path>
                    </svg>
                    <span class="ml-3 text-md font-bold">Dashboard</span>
                  </Link>
                </li>
                
                {/* ORDERS */}
                <li>
                  <Link to="/orders" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                    <svg
                      class="w-6 h-6 fill-current"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="ml-3 flex-1 whitespace-nowrap text-md font-bold">Orders</span>
                    {/* <span class="bg-gray-200 text-gray-800 ml-3 text-sm font-medium inline-flex items-center justify-center px-2 rounded-full">Pro</span> */}
                  </Link>
                </li>

                {/* OUTGOING */}
                <Link to="/outgoing" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                  <svg class="w-6 h-6 flex-shrink-0 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd"></path></svg>
                  <span class="ml-3 text-md font-bold">Outgoing</span>
                </Link>

                {/* INVENTORY */}
                <Link to="/inventory" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                  <svg class="w-6 h-6 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-darkreader-inline-fill="" style={{darkreaderInlineFill:"currentColor"}}><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
                  <span class="ml-3 text-md font-bold">Inventory</span>
                </Link>

                {/* JOBS */}
                <li>
                  <Link to="/jobs"  class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                    <svg
                      class="w-6 h-6 fill-current"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
                      <path
                        fill-rule="evenodd"
                        d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="ml-3 flex-1 whitespace-nowrap text-md font-bold">Jobs</span>
                    {/* <span class="bg-gray-200 text-gray-800 ml-3 text-sm font-medium inline-flex items-center justify-center px-2 rounded-full">Pro</span> */}
                  </Link>
                </li>

                {/* ACTIVITY */}
                <li>
                  <Link to="/activity" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                    <svg
                      class="w-6 h-6 fill-current"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                      fill-rule="evenodd"
                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                      clip-rule="evenodd"
                    ></path>
                    </svg>
                    <span class="ml-3 flex-1 whitespace-nowrap text-md font-bold">Activity</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/status" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                    <svg class="w-6 h-6 text-gray-900 group-hover:text-blue-600 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-darkreader-inline-fill="" style={{darkreaderInlineFill:"currentColor"}}>
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                    </svg>
                    <span class="ml-3 flex-1 whitespace-nowrap text-sm">Status</span>
                  </Link>
                </li> */}

                {/* PLOT */}
                <li>
                  <Link to="/plot" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                    <svg class="w-6 h-6 text-gray-900 group-hover:text-blue-600 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-darkreader-inline-fill="" style={{darkreaderInlineFill:"currentColor"}}><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg>
                    <span class="ml-3 flex-1 whitespace-nowrap text-md font-bold">Data</span>
                  </Link>
                </li>
              </ul>
              <div class="space-y-2 pt-2">
              
              {auth.auth.username=="admin"
              ?
                <>
                  <Link to="/addMerchandise" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                    <svg class=" w-6 h-6 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-darkreader-inline-fill="" style={{darkreaderInlineFill:"currentColor"}}><path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path><path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path></svg>
                    <span class="ml-3 text-md  ">Add Merchandise</span>
                  </Link>

                  {/* <Link to="/pickupForm" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 group transition duration-75 flex items-center py-2">                    
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 cursor-pointer" >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                    </svg>                    
                    <span class="ml-3 text-md">New Pick Ups</span>
                  </Link> */}

                  <Link to="/newWorkOrder" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 group transition duration-75 flex items-center py-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 cursor-pointer" >
                      <path   d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"></path>
                    </svg>
                    <span class="ml-3 text-md">New Work Order</span>
                  </Link>
                  
                  <Link to="/registration" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                    <svg class="w-6 h-6 transition duration-75" fill="currentColor" viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1024 1131q0-64-9-117.5t-29.5-103-60.5-78-97-28.5q-6 4-30 18t-37.5 21.5-35.5 17.5-43 14.5-42 4.5-42-4.5-43-14.5-35.5-17.5-37.5-21.5-30-18q-57 0-97 28.5t-60.5 78-29.5 103-9 117.5 37 106.5 91 42.5h512q54 0 91-42.5t37-106.5zm-157-520q0-94-66.5-160.5t-160.5-66.5-160.5 66.5-66.5 160.5 66.5 160.5 160.5 66.5 160.5-66.5 66.5-160.5zm925 509v-64q0-14-9-23t-23-9h-576q-14 0-23 9t-9 23v64q0 14 9 23t23 9h576q14 0 23-9t9-23zm0-260v-56q0-15-10.5-25.5t-25.5-10.5h-568q-15 0-25.5 10.5t-10.5 25.5v56q0 15 10.5 25.5t25.5 10.5h568q15 0 25.5-10.5t10.5-25.5zm0-252v-64q0-14-9-23t-23-9h-576q-14 0-23 9t-9 23v64q0 14 9 23t23 9h576q14 0 23-9t9-23zm256-320v1216q0 66-47 113t-113 47h-352v-96q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v96h-768v-96q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v96h-352q-66 0-113-47t-47-113v-1216q0-66 47-113t113-47h1728q66 0 113 47t47 113z">
                        </path>
                    </svg>
                    <span class="ml-3 text-md">Register User</span>
                  </Link>

                  <Link to="/adminTools" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 group transition duration-75 flex items-center py-2">
                    <svg
                      class="w-6 h-6 fill-current"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
                      ></path>
                    </svg>
                    <span class="ml-3 text-md" >Admin Tools</span>
                  </Link>

                  <Link to="/settings" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 group transition duration-75 flex items-center py-2">
                    <svg class="w-6 h-6 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-darkreader-inline-fill="" style={{darkreaderInlineFill:"currentColor"}}><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z" clip-rule="evenodd"></path></svg>                    
                    <span class="ml-3 text-md">Settings</span>
                  </Link>
                </>
              :null}

              </div>
            <div class="space-y-2 pt-3">
              <Link to="/help" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                <svg width="20" fill="currentColor" height="20" class="h-6 w-6 flex mx-auto" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1088 1256v240q0 16-12 28t-28 12h-240q-16 0-28-12t-12-28v-240q0-16 12-28t28-12h240q16 0 28 12t12 28zm316-600q0 54-15.5 101t-35 76.5-55 59.5-57.5 43.5-61 35.5q-41 23-68.5 65t-27.5 67q0 17-12 32.5t-28 15.5h-240q-15 0-25.5-18.5t-10.5-37.5v-45q0-83 65-156.5t143-108.5q59-27 84-56t25-76q0-42-46.5-74t-107.5-32q-65 0-108 29-35 25-107 115-13 16-31 16-12 0-25-8l-164-125q-13-10-15.5-25t5.5-28q160-266 464-266 80 0 161 31t146 83 106 127.5 41 158.5z">
                    </path>
                </svg>
                <span class="ml-3 flex-1 whitespace-nowrap text-md font-bold">Help</span>
              </Link>
              <Link to="/profile" class="no-underline text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center py-2 group ">
                <svg class="w-6 h-6  transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-darkreader-inline-fill="" style={{darkreaderInlineFill:"currentColor"}}><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
                <span class="ml-3 flex-1 whitespace-nowrap text-md font-bold">Profile</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    }

    </>

    );
}