import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';

import LayoutLanding from '../context/layoutLanding';

import { IoIosArrowForward } from 'react-icons/io';

export default function LandingAbout() {

  const childRef = useRef();
  let location = useLocation();

  //useEffect(() => {
  //  const page = location.pathname;
  //  document.body.classNameList.add('is-loaded')
  //  childRef.current.init();
  //  trackPage(page);
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [location]);

  return (

    <LayoutLanding>

    <svg width="0" height="0">
      <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
        <stop stopColor="#6e70ff" offset="0%" />
        <stop stopColor="#271aa1" offset="100%" />
      </linearGradient>
    </svg>


      <div class="min-w-0 max-w-2xl flex-auto px-4 py-6 lg:max-w-none lg:pr-0 lg:pl-8 xl:px-16">
                <section class="container pt-12 shadow-lg h-100 bg-white rounded">
                  <header class="mb-9 space-y-1 px-6">
                   <h2 class="mt-8 text-4xl tracking-tight font-extrabold  text-gray-700 px-6">
                      About LDF
                    </h2>
                  </header>
                  <div class="prose prose-slate px-6  max-w-none dark:prose-invert dark:text-slate-600 prose-headings:scroll-mt-28 prose-headings:font-display prose-headings:font-normal lg:prose-headings:scroll-mt-[8.5rem] prose-lead:text-slate-500 dark:prose-lead:text-slate-400 prose-a:font-semibold dark:prose-a:text-sky-400 prose-a:no-underline prose-a:shadow-[inset_0_-2px_0_0_var(--tw-prose-background,#fff),inset_0_calc(-1*(var(--tw-prose-underline-size,4px)+2px))_0_0_var(--tw-prose-underline,theme(colors.sky.300))] hover:prose-a:[--tw-prose-underline-size:6px] dark:[--tw-prose-background:theme(colors.slate.900)] dark:prose-a:shadow-[inset_0_calc(-1*var(--tw-prose-underline-size,2px))_0_0_var(--tw-prose-underline,theme(colors.sky.800))] dark:hover:prose-a:[--tw-prose-underline-size:6px] prose-pre:rounded-xl prose-pre:bg-slate-900 prose-pre:shadow-lg dark:prose-pre:bg-slate-800/60 dark:prose-pre:shadow-none dark:prose-pre:ring-1 dark:prose-pre:ring-slate-300/10 dark:prose-hr:border-slate-800">
                    <p class="leading-relaxed text-md p-4">
                      Our transport, warehousing and logistics company is dedicated to providing flexible and reliable solutions for all of our clients' shipping needs. 
                      We understand that every business is unique and has specific requirements for their shipments, which is why we offer a wide range of services and customizable options.
                      Our mission is to consistently deliver on-time, cost-effective transportation and logistics services that meet and exceed the expectations of our clients.
                      <br></br>
                      <br></br>
                      We strive to be the best in the industry by constantly improving our processes and investing in technologies to ensure the best possible service.                            
                      We are always available to answer questions, address concerns, and offer solutions to any challenges that may arise.
                      Ultimately, our goal is to be a reliable and trusted partner for all of your transportation, warehousing and logistics needs.
                    </p>
                    <hr></hr>
                    <header class="mb-9 space-y-1">
                    <h2 class="mt-8 text-4xl tracking-tight font-extrabold  text-gray-700 px-6">
                        Our Mission
                      </h2>
                    </header>             
                    <p class="py-4 px-6">
                      Founded in 1988, we have a long history of providing prompt and efficient services
                      to the marine industry. Originally, we specialized in transporting goods to vessels, 
                      but soon expanded our services to include transporting crew for all Canadian vessels, 
                      warehousing merchandise, providing laundry and mail services, and anything else our clients required.
                      <br></br>
                      <br></br>
                      Over the past 35 years, we have developed a reputation for high-quality service and 
                      a commitment to meeting our clients' needs. As part of this commitment, we constantly strive 
                      to improve our services and expand our capabilities, leveraging our decades of experience 
                      to find innovative solutions to the challenges faced by our clients.
                      <br></br>
                      <br></br>
                      We recognize that our clients' needs are constantly changing, and we are determined to meet 
                      these needs with innovative solutions and a commitment to providing the highest levels 
                      of service. With a focus on efficiency, reliability, and customer satisfaction, we are confident 
                      that we can deliver value to our clients in new industries and continue to build on our legacy.
                      We look forward to working with new clients and expanding our services to new industries in the years to come.
                    </p>



                  </div>
                  
                  
                  <div class="p-4 border-t-2 border-gray-700 prose prose-slate max-w-none dark:prose-invert dark:text-slate-400 prose-headings:scroll-mt-28 prose-headings:font-display prose-headings:font-normal lg:prose-headings:scroll-mt-[8.5rem] prose-lead:text-slate-500 dark:prose-lead:text-slate-400 prose-a:font-semibold dark:prose-a:text-sky-400 prose-a:no-underline prose-a:shadow-[inset_0_-2px_0_0_var(--tw-prose-background,#fff),inset_0_calc(-1*(var(--tw-prose-underline-size,4px)+2px))_0_0_var(--tw-prose-underline,theme(colors.sky.300))] hover:prose-a:[--tw-prose-underline-size:6px] dark:[--tw-prose-background:theme(colors.slate.900)] dark:prose-a:shadow-[inset_0_calc(-1*var(--tw-prose-underline-size,2px))_0_0_var(--tw-prose-underline,theme(colors.sky.800))] dark:hover:prose-a:[--tw-prose-underline-size:6px] prose-pre:rounded-xl prose-pre:bg-slate-900 prose-pre:shadow-lg dark:prose-pre:bg-slate-800/60 dark:prose-pre:shadow-none dark:prose-pre:ring-1 dark:prose-pre:ring-slate-300/10 dark:prose-hr:border-slate-800">
                      <p class="text-center font-bold text-black my-4">
                          We want to hear from you. Get in touch with us to learn more about our B2B transport, warehousing and logistics services. 
                      </p>
                      
                      <div class="w-100 flex">
                        <a href="/contact" class="mx-auto inline-flex no-underline items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-black hover:!bg-gray-800 rounded sm:w-auto sm:mb-0">
                              Contact Us
                              <span class="ml-2">
                                <IoIosArrowForward />
                              </span>
                              {/* <svg class="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg> */}
                          </a>
                        </div>
                    </div>


                </section>
   
      </div>

  </LayoutLanding>


    // <LayoutLanding>

    //   <section class="text-gray-600 body-font flex h-100 w-100 ">
    //     <div class="container px-5 flex my-auto mx-auto border-4 shadow-lg bg-white">
    //       <div class="container-fluid p-8">

    //       <p class="leading-relaxed text-md p-4">
    //             Our transport, warehousing and logistics company is dedicated to providing flexible and reliable solutions for all of our clients' shipping needs. 
    //             <br></br>
    //             <br></br>
    //             We understand that every business is unique and has specific requirements for their shipments, which is why we offer a wide range of services and customizable options.
    //             Our mission is to consistently deliver on-time, cost-effective transportation and logistics services that meet and exceed the expectations of our clients.
    //             <br></br>
    //             <br></br>
    //             We strive to be the best in the industry by constantly improving our processes and investing in technologies to ensure the best possible service.                            
    //             We are always available to answer questions, address concerns, and offer solutions to any challenges that may arise.
    //             <br></br>
    //             <br></br>
    //             Ultimately, our goal is to be a reliable and trusted partner for all of your transportation, warehousing and logistics needs.
    //           </p>

    //         {/* <h1>ABOUT</h1> */}
    //         <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About the growth of LDF</p>
    //         <p class="py-4">
    //         Founded in 1988, we have a long history of providing prompt and efficient services
    //         to the marine industry. Originally, we specialized in transporting goods to vessels, 
    //         but soon expanded our services to include transporting crew for all Canadian vessels, 
    //         warehousing merchandise, providing laundry and mail services, and anything else our clients required.
    //         <br></br>
    //         <br></br>
    //         Over the past 35 years, we have developed a reputation for high-quality service and 
    //         a commitment to meeting our clients' needs. As part of this commitment, we constantly strive 
    //         to improve our services and expand our capabilities, leveraging our decades of experience 
    //         to find innovative solutions to the challenges faced by our clients.
    //         <br></br>
    //         <br></br>
    //         We recognize that our clients' needs are constantly changing, and we are determined to meet 
    //         these needs with innovative solutions and a commitment to providing the highest levels 
    //         of service. With a focus on efficiency, reliability, and customer satisfaction, we are confident 
    //         that we can deliver value to our clients in new industries and continue to build on our legacy.
    //         We look forward to working with new clients and expanding our services to new industries in the years to come.
    //         </p>

    //       </div>
    //     </div>
    //   </section>
      
    // </LayoutLanding>
  );
}
















