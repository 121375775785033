import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import moment from 'moment';

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
import useRefreshToken from '../hooks/useRefreshToken';

import Layout from '../context/layoutPortal.jsx';
import Popup from '../context/layoutPopup.jsx';

import Loading from '../modules/loading.jsx';
import Table from '../modules/table.jsx';
import MenuEditClients from '../modules/menuEditClients';

import { BsX } from 'react-icons/bs';

function toTimestamp(strDate) {
  var datum = Date.parse(strDate);
  return datum;// /1000;
}

export default function Tools({ notify }) {

  const auth = useAuth();
  const refresh = useRefreshToken();
  const navigate = useNavigate();
  const childRef = useRef();
  //let location = useLocation();

  const axiosPrivate = useAxiosPrivate();

  const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

  const columns_users = [
    { 
        id: 'edit', 
        label: 'EDIT', 
        minWidth: 50,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    { 
        id: 'id', 
        label: 'ID', 
        minWidth: 120,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    { 
        id: 'name', 
        label: 'NAME', 
        minWidth: 150,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    { 
      id: 'username', 
      label: 'USERNAME', 
      minWidth: 150,
      align: 'left',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'email',
        label: 'EMAIL',
        minWidth: 100,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'phone',
        label: 'PHONE',
        minWidth: 100,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },        
    {
        id: 'address',
        label: 'ADDRESS',
        minWidth: 100,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'logged',
        label: 'LOGGED',
        minWidth: 170,
        align: 'left',
        render: value => moment(new Date(value)).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
        id: 'joined',
        label: 'JOINED',
        minWidth: 170,
        align: 'left',
        render: value => moment(new Date(value)).format('YYYY-MM-DD HH:mm:ss')
    },
    {
        id: 'status',
        label: 'STATUS',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'ip',
        label: 'IP',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },    
  ]; 

  const columns_mail = [
    { 
      id: 'delete_mail', 
      label: 'DELETE', 
      minWidth: 50,
      align: 'left',
      format: (value) => value.toLocaleString('en-US'),
    },
    { 
        id: 'read', 
        label: 'READ', 
        minWidth: 50,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    { 
        id: 'datetime', 
        label: 'DATETIME', 
        minWidth: 120,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    { 
        id: 'name', 
        label: 'NAME', 
        minWidth: 150,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'email',
        label: 'EMAIL',
        minWidth: 100,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'company',
        label: 'COMPANY',
        minWidth: 100,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'industry',
        label: 'INDUSTRY',
        minWidth: 100,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'), 
    },
    {
        id: 'message',
        label: 'MESSAGE',
        minWidth: 100,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'), 
    },
    {
        id: 'details',
        label: 'DETAILS',
        minWidth: 100,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'), 
    },
    {
        id: 'phone',
        label: 'PHONE',
        minWidth: 100,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },        
    
  ]; 

  const [toolsPassword, setToolsPassword] = useState("");
  const [passwordPrompt, setPasswordPrompt] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState({});
  const [showEditMenu, setShowEditMenu] = useState(false);

  const [readMessage, setReadMessage] = useState([]);
  const [showReadMessage, setShowReadMessage] = useState(false);
  const [mailbox, setMailbox] = useState([]);
  const [displayConfirmationPopup, setDisplayConfirmationPopup] = useState(false);
  const [_deleteMessage, setDeleteMessage] = useState([]);

  const [sort, setSort] = useState("up");
  const [tab, setTab] = useState(1);

  
  useEffect(() => {
    //const page = location.pathname;
    fetchUserData();
    //console.log(auth);        
  }, []);
  

  const fetchUserData = async () => {
    try {
      setIsLoading(true)
      const params = {}
      const response = await axiosPrivate.get("/users/all", {params: params}, { method: "GET", credentials: "include" });
      const data = response.data.map((d) => {
        return {
            ...d, joined: moment(d.joined).format('YYYY-MM-DD HH:mm:ss'), logged: moment(d.logged).format('YYYY-MM-DD HH:mm:ss')
        }
      });  
      console.log('USER DATA --->', data);
      setData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const removeUserFromData = async (userID) => {
    let newData = data.filter((user) => user.id !== userID);
    setData(newData);
  }

  const editTableRow = (editData, code) => {    
    setEditData(editData);
    setShowEditMenu(true);
  }

  const submitEdit = async (editData) => {
    const params = {}
    const response = await axiosPrivate.post("/users/edit", editData, {params: params}, { method: "POST", credentials: "include" });
    //console.log(response);
    if(response.data.status === "success"){
      notify(response.data.message, "success");
      fetchUserData();
    }else{
      notify(response.data.message, "error");
    }
  }

  const onSort = (colName) => {

    // ascending/descending: id/reference, quantity, weight, datetime, checked
    
    // alphabetical: destination, supplier, unit type, carrier, client

    // let colName = col.target.innerText.toLowerCase();

    let data_list = JSON.parse(JSON.stringify(data));

    //console.log(colName);

    if (colName=="phone"||colName=="ip"||colName=="read"||colName=="message"||colName=="details") {
      return
    };

    
    if (colName=="waybill"||colName=="note") {
        sorted_data = data_list
    }

    // NUMERICAL
    if (colName=="quantity"||colName=="id") {

        if (sort=="up") {
            var sorted_data = data_list.sort((a, b) => Number(b[colName])-Number(a[colName]));
        }
        if (sort=="down") {
            var sorted_data = data_list.sort((a, b) => Number(a[colName])-Number(b[colName]));
        }          
    }

    // WEIGHT
    if (colName=="weight") {
        if (sort=="up") {
            var sorted_data = data_list.sort((a, b) => Number(b[colName].split(" ")[0])-Number(a[colName].split(" ")[0]));
        }
        if (sort=="down") {
            var sorted_data = data_list.sort((a, b) => Number(a[colName].split(" ")[0])-Number(b[colName].split(" ")[0]));
        }  
    }

    // REFERENCE
    if (colName=="reference") {

        if (sort=="up") {
            var sorted_data = data_list.sort((a, b) => Number(b[colName].replace("-",""))-Number(a[colName].replace("-","")));
        }
        if (sort=="down") {
            var sorted_data = data_list.sort((a, b) => Number(a[colName].replace("-",""))-Number(b[colName].replace("-","")));
        }  
    }

    // ALPHABETICAL
    if (colName=="company"||colName=="industry"||colName=="name"||colName=="destination"||colName=="supplier"||colName=="unit"||colName=="carrier"||colName=="status"||colName=="client"||colName=="username"||colName=="email"||colName=="role"||colName=="address") {

        if (sort=="up") {
            var sorted_data = data_list.sort(function SortArrayObjects(x, y){
                if (x[colName] < y[colName]) {return -1;}
                if (x[colName] > y[colName]) {return 1;}
                return 0;
            })
        }
        if (sort=="down") {
            var sorted_data = data_list.sort(function SortArrayObjects(x, y){
                if (x[colName] > y[colName]) {return -1;}
                if (x[colName] < y[colName]) {return 1;}
                return 0;
            })
        }
    }

    // CHRONOLOGICAL
    if (colName=="datetime"||colName=="checked"||colName=="logged"||colName=="joined") {

        if (sort=="up") {
            var sorted_data = data_list.sort((a, b) => toTimestamp(b[colName])-toTimestamp(a[colName]));
        }
        if (sort=="down") {
            var sorted_data = data_list.sort((a, b) => toTimestamp(a[colName])-toTimestamp(b[colName]));
        }      
    }

    setData(sorted_data);
    setSort(sort=="up"?"down":"up");   
  }

  const fetchMail = async () => {
    setIsLoading(true)
    try {
      const response = await axiosPrivate.get("/mailbox", { method: "GET", credentials: "include" });
      //console.log(response)
      setMailbox(response.data);
      setIsLoading(false);     
    }
    catch {
      notify("Error fetching data", "error");
      setIsLoading(false);
    }
  }

  const showMailbox = () => {
    setTab(2)
    fetchMail();
  }

  const showUserList = () => {
    setTab(1)
  } 

  const openMessage = (message) => {
    //console.log(message);
    setReadMessage(message);
    setShowReadMessage(true);
  }

  const deleteMessage = (message) => {
    setDisplayConfirmationPopup(true);
    setDeleteMessage(message);
  }

  const confirmDeleteMessage = async () => {
    try{
      setIsLoading(true);
      const response = await axiosPrivate.post("/mailbox/delete", {messageId: _deleteMessage.id},{ method: "POST", credentials: "include" });
      //console.log(response);
      setIsLoading(false);
      setDisplayConfirmationPopup(false)
      notify("Message deleted", "success");      
      fetchMail();
    } catch {
      setIsLoading(false);
      setDisplayConfirmationPopup(false);
      notify("Error deleting message", "error");
    }     
  }

  const enterPassword = async () => {
     if (toolsPassword == "202404"){
        setPasswordPrompt(false);
      }else{
        notify("Incorrect password", "error");
      }    
  }


  return (

      <Layout>   

        {passwordPrompt ?

        //  create a centered password input field
        <div id="popupMenu" class="absolute z-20 mx-auto my-auto inset-0 overflow-hidden h-1/4 p-2 w-1/3  bg-gray-500 text-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
            <div class="bg-gray-500 text-white sm:p-6 h-full w-100 flex">
                <div class="flex">
                    <div class="ml-6 my-auto">
                          <h3 class="">Admin Tools Password</h3>
                          <p class="text-white">Please enter the password to access this page.</p>
                          <input onChange={(e) => setToolsPassword(e.target.value)} class="bg-gray-200 text-black font-bold py-2 px-4 rounded mr-2" type="password" placeholder="Password" />
                          <button
                            onClick={enterPassword}
                            class="bg-green-200 hover:bg-green-300 text-black font-bold py-2 px-4 rounded mr-2" 
                            >
                            <p class="flex m-auto">Submit</p>                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
     
        :
        <>

          <div class="flex w-full"> 
          {tab==1?
            <>
              <p class="flex font-bold">User List</p>          
              <button onClick={showMailbox} class="flex bg-gray-600 px-2 py-1 h-8 text-white rounded ml-auto hover:bg-gray-700">Mailbox</button>          
            </>
            :         
            <>
              <p class="flex font-bold">Mailbox</p>    
              <button onClick={fetchMail} class="flex bg-green-600 px-2 py-1 h-8 text-white rounded ml-auto hover:bg-green-700">Refresh</button>      
              <button onClick={showUserList} class="flex bg-gray-600 px-2 py-1 h-8 text-white rounded ml-3 hover:bg-gray-700">Users List</button>
            </>
          }
            
          </div>
          {/* <button onClick={fetchUserData} class="flex rounded-md px-10 ml-auto mr-8 text-center bg-blue-400 hover:bg-blue-500 text-white py-3">Fetch User Data</button>         */}
          
          {showEditMenu 
          ? <MenuEditClients
              data={editData} 
              onToggleDisplay={setShowEditMenu}
              removeUserFromData={removeUserFromData}
              updateParent={fetchUserData}
              notify={notify}
          />                      
          : null} 

          {showReadMessage 
          ? <Popup>

              <div class="flex absolute top-5 right-5">
                  <button onClick={() => setShowReadMessage(false)} class="h-10 w-10 bg-gray-200 hover:bg-gray-300 rounded-full flex m-2">
                      <BsX class="m-auto" size={25}/>
                  </button>
              </div>

              <div id="innerEditMenu" class=" p-4 h-3/4 pt-3 pb-0 w-3/4 inline-block bg-gray-200 rounded-lg text-left overflow-scroll shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">            

                <div class="flex flex-col pb-2">
                    <h5 class="flex-row">Message {readMessage.id}</h5>
                    <p class="font-bold flex-row">{readMessage.datetime}</p>
                </div>  

                <div class="flex pb-2">
                  <div class="flex-col">
                    <p class="flex-row font-bold mb-0">From:  <span class="text-blue-700 font-normal">{readMessage.name}</span></p>
                    <p class="flex-row font-bold mb-0">Email: <span class="text-blue-700 font-normal">{readMessage.email}</span></p>
                  </div>

                  <div class="flex-col ml-4">
                    <p class="flex-row font-bold mb-0">Company:  <span class="text-blue-700 font-normal">{readMessage.name}</span></p>
                    <p class="flex-row font-bold mb-0">Industry: <span class="text-blue-700 font-normal">{readMessage.email}</span></p>
                  </div>
                </div>

                <p class="font-bold mb-0">Message</p>
                <div class="flex flex-wrap  mb-2 px-4 bg-white border-2 border-black h-1/4 overflow-scroll px-auto pt-3 hid">                                    
                    {readMessage.message}
                </div>  

                <p class="font-bold mb-0">Details</p>              
                <div class="flex flex-wrap mb-2 px-4 bg-white border-2 border-black h-1/4 overflow-scroll px-auto pt-3 hid">                                    
                    {readMessage.details}
                </div>  
            
              </div>   

              

          </Popup>                              
          : null} 

          {
            tab == 1? // USERS
              
                <div class="h-5/6 w-100 mt-1">
                  <Table 
                    username={auth.auth.username}
                    data={data} 
                    loading={isLoading}
                    columns={columns_users} 
                    editFunc={editTableRow}
                    onSort={onSort}
                  />
                </div>


            :tab == 2? // MAILBOX
              <div class="h-5/6 w-100 mt-1">

                {displayConfirmationPopup? // create a centered popup to confirm deletion
                      <div id="popupMenu" class="absolute z-20 mx-auto my-auto inset-0 overflow-hidden h-1/4 p-2 w-1/2  bg-gray-500 text-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
                          <div class="bg-gray-500 text-white sm:p-6 h-full w-100 flex">

                                  <div class="flex">
                                      <div class="ml-6">
                                          <h3 class="">Delete Message #{_deleteMessage.id}</h3>
                                          <p class="text-white">Are you sure you want to delete this message?</p>
                                      </div>
                                  </div>
                                  <div class="flex ml-auto mt-auto">
                                      <button onClick={isLoading?null:() => setDisplayConfirmationPopup(false)} class="bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded mr-2">Cancel</button>                                      
                                      <div class="">
                                          {!isLoading?
                                          <div onClick={() => confirmDeleteMessage()} class="w-28 h-10 flex bg-red-400 hover:bg-red-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                                              <p class="flex m-auto">Confirm</p>
                                          </div> 
                                          :   
                                          <div class="w-28 h-10 flex bg-red-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                              <span class="flex m-auto"><Loading type={1} /></span>
                                          </div> 
                                          }
                                      </div> 
                                  </div>
                          </div>
                      </div>                                                    
                :null}

                <Table 
                  username={auth.auth.username}
                  data={mailbox} 
                  loading={isLoading}
                  columns={columns_mail} 
                  editFunc={editTableRow}
                  onSort={onSort}
                  readMessage={openMessage}
                  deleteMessage={deleteMessage}
                />
              </div>             
          :
          isLoading?
              <section className="flex items-center h-full p-16 relative w-100">
              <div className="container w-100 flex flex-col items-center justify-center px-5 mx-auto my-20">
                  <div className="max-w-md text-center">
                      <h2 className="mb-8 font-extrabold text-5xl dark:text-gray-600">
                          <span className="sr-only"></span>LOADING
                      </h2>
                      <p className="text-2xl font-semibold md:text-3xl">Searching for users...</p>
                      {/* <p className="mt-4 mb-8 dark:text-gray-400">But dont worry, you can find plenty of other things on our homepage.</p> */}
                      <div className="max-w-md text-center h-4 w-100 pt-4 px-12">
                          <Loading type={3} class="w-100 h-32"/>
                      </div>
                  </div>
              </div>
              </section>
              :
              // <section className="flex items-center h-full p-16 relative w-100">
              // <div className="container w-100 flex flex-col items-center justify-center px-5 mx-auto my-20">                          
              //     <div className="max-w-md text-center">                              
              //         <h2 className="mb-8 font-extrabold text-5xl dark:text-gray-600">                                  
              //             <span className="sr-only">Error</span>NO DATA
              //         </h2>
              //         <p className="text-2xl font-semibold md:text-3xl">No data available for this search.</p>
                      
              //         {/* <p className="mt-4 mb-8 dark:text-gray-400">But dont worry, you can find plenty of other things on our homepage.</p> */}
              //     </div>
              // </div>
              // </section>
              null
          }   
          
          <div class="w-100 h-auto flex">
            <span class='text-blue-700 mt-2 flex font-mono ml-auto'>
              LDF v0.7.17
            </span>
          </div>

        </>
      }
      </Layout>
                
  );
}


