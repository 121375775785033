
import React, { useRef, useEffect, useState } from 'react';

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Checkbox from '@mui/material/Checkbox';

import Loading from './loading';

import { FiX } from 'react-icons/fi';
import { BsTrash } from 'react-icons/bs';
import { BsX } from 'react-icons/bs';
import { IoMdDownload } from 'react-icons/io';

import Popup from '../context/layoutPopup.jsx';

import { RiFileExcel2Fill } from 'react-icons/ri';
import { FaFileCsv } from 'react-icons/fa';
import { VscJson } from 'react-icons/vsc';

// import caret down icon
import { IoIosArrowDown } from 'react-icons/io';


const Dropdown = ({ options, value, onChange }) => {
    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(value);
    const node = useRef();
  
    const handleClick = e => {
      if (node.current.contains(e.target)) {
        return;
      }
      setOpen(false);
    };
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClick);
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }, []);
  
    return (
      <div ref={node} 
        class=" h-24 bg-white outline outline-black outline-2 hover:bg-gray-100 text-gray-800 font-semibold pt-1  border-gray-400 rounded shadow"      
      
      >
        <div 
          class="w-full flex p-2 py-3 cursor-pointer"
          onClick={() => setOpen(!open)}
        >

            <div class="flex">
                <span class="ml-2 mt-2 flex">{selectedOption.icon}</span>
                <div class="inline">
                    <span style={{fontFamily:"monospace",fontSize:"26px"}} class="ml-4">{selectedOption.label}</span>
                    <sub class="flex ml-4">{selectedOption.info}</sub> 
                </div>           
            </div>


            <div class="flex ml-auto mr-2 mt-3">
                <span><IoIosArrowDown class="text-gray-500 hover:text-gray-700 cursor-pointer"/></span>
            </div>            

        </div>
        {open && (
          <ul class="bg-white relative w-100 py-2 pl-0 outline outline-black outline-2" 
          // style rounded bottoms
          style={{ borderBottomLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem"}}
          
          >
            {options.map(option => (
                 option.value == "csv"?
                    <li 
                        key={option.value}
                        onClick={() => {
                            setSelectedOption(option);
                            onChange(option.value);
                            setOpen(false);
                        }}
                        class="flex opacity-100 py-4 hover:bg-blue-300 cursor-pointer text-lg font-mono"
                        style={{fontFamily:"monospace"}}
                    >
                        <span class="ml-4 mt-2">{option.icon}</span>
                        <div class="inline">
                            <span style={{fontFamily:"monospace",fontSize:"26px"}} class="ml-4">{option.label}</span>
                            <sub class="flex ml-4">{option.info}</sub> 
                        </div>
                    </li>
                :
                    <li 
                        key={option.value}
                        //onClick={() => {
                        //    setSelectedOption(option);
                        //    onChange(option.value);
                        //    setOpen(false);
                        //}}
                        class="flex opacity-100 py-4 bg-gray-200 cursor-pointer text-lg font-mono"
                        style={{fontFamily:"monospace"}}
                    >
                        <span class="ml-4">{option.icon}</span>
                        <div class="inline">
                            <span style={{fontFamily:"monospace",fontSize:"26px"}} class="ml-4">{option.label}</span>
                            <sub class="flex ml-4">{option.info}</sub> 
                        </div> 
                    </li>
            ))}
          </ul>
        )}
      </div>
    );
  };


function objectToCSV(data) {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    for (const row of data) {
        const values = headers.map(header => {
            const escaped = (''+row[header]).replace(/"/g, '\\"');
            return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
}



export default function MenuDownloadData({ data, onSetFilterStatus, onSetFilterClient, onSetFilterDestination, toggleDisplay }) {

    const [isLoading, setIsLoading] = useState(false);
    const [filename, setFilename] = useState("ldf_orders_" + new Date().toISOString().slice(0,10).replace(/-/g,"_"));

    useEffect(() => {
        console.log(data);
    }, []);

    const savePreferences = () => {
        //console.log(filterStatus);
    }

    const closeMenu = () => {
        toggleDisplay();
    }

    const onChangeFileName = (e) => {
        // check if e.target.value is a value string for a filename
        if (
            e.target.value.length > 0 &&
            e.target.value.length < 256 &&
            e.target.value.match(/^[a-zA-Z0-9_\-\.]*$/)            
         ) {
            setFilename(e.target.value);
         }
    }

    async function downloadCSV(data) {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
    
        // Get current datetime
        const date = new Date();
        const timestamp = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate() + "_" + date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds();
    
        a.setAttribute('hidden','');
        a.setAttribute('href', url);
        a.setAttribute('download', filename); // Append timestamp to file name
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    
    const clickDownload = async () => {
        
        setIsLoading(true);
        try {
            const csvData = objectToCSV(data);
            await downloadCSV(csvData);
            setIsLoading(false);
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }
    }


    return (
        <Popup>
        <div class="flex absolute top-5 right-5">
            <button onClick={closeMenu} class="h-10 w-10 bg-gray-200 hover:bg-gray-300 rounded-full flex m-2">
                <BsX class="m-auto" size={25}/>
            </button>
        </div>
        <div id="innerEditMenu" class="h-3/4 w-1/3 !mt-12 pt-3 pb-0 inline-block bg-gray-200 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
            
            <div class="max-h-18 w-100 px-6 pb-3 border-b-2 border-black">
                <h3 class="text-black mb-0 pb-0">Download Table Data</h3>
                <sub>Download records from current table as data is shown.</sub>
            </div>                        

            <div class="flex h-[calc(100%-9rem)] overflow-y-scroll pt-2">

                <div class="h-100 flex flex-col w-full px-12 py-6 ml-6 border-black">

                    <div class="p-0 mb-4">                    
                        <label class="font-bold text-black block uppercase tracking-wide text-xs mb-2" for="grid-first-name">
                            Data Type
                        </label>

                        <Dropdown
                            options={[
                                { value: "csv", label: "CSV", icon: <FaFileCsv color={"red"} size={34} />, info: "Comma Seperated Values" },
                                { value: "xlsx", label: "XLSX", icon: <RiFileExcel2Fill color={"green"} size={34}/>, info: "Microsoft Excel Spreadsheet" },
                                { value: "json", label: "JSON", icon: <VscJson style={{strokeWidth:"1.5px"}} color={"blue"} size={34}/>, info: "Javascript Object Notation" },
                            ]}
                            value={{value: "csv", label: "CSV", icon: <FaFileCsv color={"red"} size={34}/>, info: "Comma Seperated Values"}}
                            onChange={(value) => console.log(value)}
                        />
                    </div>

                    <div class="mb-4">
                        <label class="font-bold text-black block uppercase tracking-wide text-xs mb-2" for="grid-first-name">
                           File Name
                        </label>
                        <input 
                            class="border-2 border-black rounded w-100 h-12 indent-2 text-black outline-none" 
                            type="text" 
                            name="filename"
                            // set value as date .csv with underscores
                            value={filename}
                            onChange={(e) => onChangeFileName(e)}
                        />
                    </div>                      

                    {/* <div class="flex flex-row">
                        <Checkbox onClick={null} checked={false}/>
                        <p class="align-middle ml-2 my-auto flex">Delivered</p>
                    </div> */}

                    <div class="mb-4">

                        <div class="inline-block">
                            <label class="font-bold text-black block uppercase tracking-wide text-xs mb-2" for="grid-first-name">
                                INFORMATION
                            </label>
                            <div class="w-100 px-2"><p class=" font-mono" style={{fontFamily:"monospace"}}>Number of Rows: {data.length}</p></div>
                        </div>

                    </div>

                                                
                   
                </div>
                
            </div>

            <div class="flex">

                <div class="max-h-18 flex w-100 px-6 pt-2">
                    <div class="w-100 mt-auto flex">
                        <div 
                            onClick={clickDownload}                             
                            class="mx-auto w-56 h-10  flex bg-emerald-600 hover:bg-emerald-700 text-white font-bold  " type="button"
                        >
                            {isLoading?
                             <button id={isLoading?"loading":"loaded"} type="submit" class="w-full flex justify-center !bg-emerald-700 text-white p-2  rounded-2 tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500">{"Loading"}</button>
                        :
                            <p class="flex m-auto">Download<IoMdDownload class="ml-1" size={24}/></p>
                        }
                        </div> 
                    </div> 
                </div>

            </div>
                
        </div>
      </Popup>
    )
}