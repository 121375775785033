import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';

import LayoutLanding from '../context/layoutLanding.jsx';

import { FiMapPin, FiCheckCircle, FiPlusCircle, FiSun, FiMoon, FiChevronRight } from 'react-icons/fi';
import { GiNightSky } from 'react-icons/gi';
import { GiTrade } from 'react-icons/gi';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';


export default function LandingTransport() {

  const childRef = useRef();
  let location = useLocation();

  //useEffect(() => {
  //  const page = location.pathname;
  //  document.body.classNameList.add('is-loaded')
  //  childRef.current.init();
  //  trackPage(page);
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [location]);

  return (

    <LayoutLanding>
      

      <svg width="0" height="0">
        <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
          <stop stopColor="#6e70ff" offset="0%" />
          <stop stopColor="#271aa1" offset="100%" />
        </linearGradient>
      </svg>
      
      <svg width="0" height="0">
        <linearGradient id="green-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
          <stop stopColor="#45cc89" offset="0%" />
          <stop stopColor="#2aa359" offset="100%" />
        </linearGradient>
      </svg>

      <svg width="0" height="0">
        <linearGradient id="red-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
          <stop stopColor="#f5776e" offset="0%" />
          <stop stopColor="#99263d" offset="100%" />
        </linearGradient>
      </svg>
    
        
        <div class="min-w-0 max-w-2xl flex-auto px-4 py-6 lg:max-w-none lg:pr-0 lg:pl-8 xl:px-16">

            <section class="container pt-12 shadow-lg h-100 bg-white rounded">
              <header class="mb-9 space-y-1 px-6">
                <p class="font-display text-sm font-medium text-indigo-600 ">WE HAVE AN EXCELLENT TRACK RECORD THROUGHOUT THE INDUSTRY</p>
                <h2 class="mb-8 text-4xl tracking-tight font-extrabold  text-gray-800" style={{color: "url(#blue-gradient)"}}>
                  Transportation
                </h2>
              </header>
              <div class="prose prose-slate px-6 max-w-none dark:prose-invert dark:text-slate-600 prose-headings:scroll-mt-28 prose-headings:font-display prose-headings:font-normal lg:prose-headings:scroll-mt-[8.5rem] prose-lead:text-slate-500 dark:prose-lead:text-slate-400 prose-a:font-semibold dark:prose-a:text-sky-400 prose-a:no-underline prose-a:shadow-[inset_0_-2px_0_0_var(--tw-prose-background,#fff),inset_0_calc(-1*(var(--tw-prose-underline-size,4px)+2px))_0_0_var(--tw-prose-underline,theme(colors.sky.300))] hover:prose-a:[--tw-prose-underline-size:6px] dark:[--tw-prose-background:theme(colors.slate.900)] dark:prose-a:shadow-[inset_0_calc(-1*var(--tw-prose-underline-size,2px))_0_0_var(--tw-prose-underline,theme(colors.sky.800))] dark:hover:prose-a:[--tw-prose-underline-size:6px] prose-pre:rounded-xl prose-pre:bg-slate-900 prose-pre:shadow-lg dark:prose-pre:bg-slate-800/60 dark:prose-pre:shadow-none dark:prose-pre:ring-1 dark:prose-pre:ring-slate-300/10 dark:prose-hr:border-slate-800">
                
                <p>
                We believe that nothing should get in the way of efficient and timely transportation, which is why we provide our 
                services anywhere and anytime. From major urban centers to the remote corners of the country, we have the  
                resources to provide you with the transportation services you require. 
                </p>
                <hr></hr>  
        
                <h2 class="flex my-6 text-2xl tracking-tight font-extrabold  text-gray-700">
                  People and Products
                  </h2>
                <p>
                  Our experience makes us the best choice for transporting people or products. From delivering large medical equipment to hospitals to transporting sailors to their ships, 
                  we really do do it all. We don't believe in applying a one-size-fits-all approach; every job is different and every client is different.
                  <br></br>
                  <br></br>
                  Our team has the ability to securely transport a wide range of products and ensure that they 
                  arrive at their destination safely. We take the time to understand the needs of our clients 
                  and provide customized transportation solutions. Please see our <a href="/delivery" class="mr-2">Delivery page</a>
                  for more information.           
                </p>

                <h2 class="flex my-6 text-2xl tracking-tight font-extrabold  text-gray-700">
                  
                  Anywhere In Canada</h2>              
                <p >
                  We offer our services across provincial borders. We do pick ups and deliveries in 
                  major cities as well as smaller areas. Just tell
                  us where to pick up and where to deliver and we will take care of the rest.
                  <br></br>
                  <br></br>
                  We can also forward your shipments for you. If you need to have something shipped to a different location, we can
                  easily expedite that process. We have a network of trusted partners that we work with to ensure that your shipment is handled with
                  care and attention. We can help you get your products where they need to be at the most competitive prices.
                </p>

                <h2 class="flex my-6 text-2xl tracking-tight font-extrabold  text-gray-700">                
                  Any Time Of The Day
                  </h2>
                <p >
                  At LDF, we take pride in our ability to offer unparalleled levels of flexibility and convenience to our clients. 
                  We understand that the modern world demands transportation services that are available round the clock, and that is why we provide our 
                  services 24/7, 365 days a year. Whether you require transportation during regular business hours or in the early hours of the morning, 
                  our team is always ready to provide you with the a timely and reliable service.              
                  <br></br>
                  <br></br>
                  We make it very easy to quickly get something or someone transported. Just tell us what you need and we will get it done. Thats why we have
                  one of the highest retention rates in the industry and why our clients continue to use our services year after year.
                  
                  <br></br>
                  <br></br>
                </p>
                


              </div>
              <div class="p-4 border-t-2 border-gray-700 prose prose-slate max-w-none dark:prose-invert dark:text-slate-400 prose-headings:scroll-mt-28 prose-headings:font-display prose-headings:font-normal lg:prose-headings:scroll-mt-[8.5rem] prose-lead:text-slate-500 dark:prose-lead:text-slate-400 prose-a:font-semibold dark:prose-a:text-sky-400 prose-a:no-underline prose-a:shadow-[inset_0_-2px_0_0_var(--tw-prose-background,#fff),inset_0_calc(-1*(var(--tw-prose-underline-size,4px)+2px))_0_0_var(--tw-prose-underline,theme(colors.sky.300))] hover:prose-a:[--tw-prose-underline-size:6px] dark:[--tw-prose-background:theme(colors.slate.900)] dark:prose-a:shadow-[inset_0_calc(-1*var(--tw-prose-underline-size,2px))_0_0_var(--tw-prose-underline,theme(colors.sky.800))] dark:hover:prose-a:[--tw-prose-underline-size:6px] prose-pre:rounded-xl prose-pre:bg-slate-900 prose-pre:shadow-lg dark:prose-pre:bg-slate-800/60 dark:prose-pre:shadow-none dark:prose-pre:ring-1 dark:prose-pre:ring-slate-300/10 dark:prose-hr:border-slate-800">
                <p class="text-center font-bold text-black my-4">
                    We want to hear from you. Get in touch with us to learn more about our B2B transport, warehousing and logistics services. 
                </p>
                
                <div class="w-100 flex">
                  <a href="/contact" class="mx-auto inline-flex no-underline items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-black hover:!bg-gray-800 rounded sm:w-auto sm:mb-0">
                        Contact Us
                        <span class="ml-2">
                          <IoIosArrowForward />
                        </span>
                        {/* <svg class="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg> */}
                    </a>
                  </div>
              </div>


            </section>
            
 
        </div>
      


    </LayoutLanding>

  );
}
















