import React, { useState } from 'react'
import axios from 'axios'

import useAuth from '../hooks/useAuth';
import Layout from '../context/layoutPortal.jsx';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Loading from '../modules/loading.jsx';

import { FiX } from 'react-icons/fi'

function checkIfDuplicateExists(arr) {
  return new Set(arr).size !== arr.length
}

function checkEmail(str) {
  if (str.length>0) {    
      let val = str;
      if(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val)) {
          return true
      } else {
          return false
      }
  } else {
      return true;
  }
}

export default function RegisterForm({ notify }) {

  const auth = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [formData, setFormData] = useState({
    name: '',
    username: '',
    email: '',
    password: '',
    confirm_password: '',
    phone: '',
    address: '',
  })
  const { name, username, email, password, confirm_password, phone, address } = formData

  const [step, setStep] = useState(1);
  const [divisionList, setDivisionList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = e => {
    if (e.target.value.includes('"') || e.target.value.includes('`')) {}
    else {
      setFormData({ ...formData, [e.target.name]: e.target.value })
    }    
  }

  const addNewDivision = () => {
    const new_division = `${formData.username}Default${divisionList.length+1}`
    if (!divisionList.includes(new_division)) {
      
    }
    setDivisionList([...divisionList, new_division])
  }

  const removeDivision = (index) => {
    const new_divisionList = divisionList.filter((item, i) => i !== index)
    setDivisionList(new_divisionList)
  }

  const onChangeDivision = (index, e) => {
    const new_divisionList = divisionList.map((item, i) => {
      if (i === index) {
        if (e.target.value.includes('"') || e.target.value.includes('`')) {}
        else {
          return e.target.value
        }
      } else {
        return item
      }
    })
    setDivisionList(new_divisionList)
  }

  const checkUserInputs = () => {
    setIsLoading(true);
    if (name=='' || email=='' || password=='' || confirm_password=='' || phone=='' || address=='') {
      notify("Please fill all fields", "error")
      setIsLoading(false);
    }
    else {
      if(password!==confirm_password) {
        notify("Passwords do not match", "error")
      } else {
        setIsLoading(false);
        setDivisionList([`${username}Default1`]);
        setStep(2);
      }       
    }
  }

  const cleanDivisionsList = async () => {
    let cleanedList = []
    for (let i=0; i<divisionList.length; ++i) {
      if (divisionList[i]!=='') {        
        cleanedList.push(divisionList[i].replaceAll(" ",""))        
      } 
    }
    setDivisionList(cleanedList);    
  } 

  const checkDivisionsList = async () => {
    setIsLoading(true);
    const flag = checkIfDuplicateExists(divisionList);        
    if (flag) {
      notify("Duplicate division names found", "error")
      setIsLoading(false);
    }    
    else {
      if (divisionList.length==0) {
        notify("Please add at least one division", "error")
      } else {
        await cleanDivisionsList();
        setStep(3);
      }      
      setIsLoading(false);        
    }
  }

  const createBlobFromArray = async (arr) => {
    let blob = '';
    
    arr.forEach((item, index) => {
      if (index === 0) {
        blob = item;
      } else {
        blob = blob + ';' + item;
      }
    })
    return blob;
  }

  const createArrayMap = async (arr) => {
    let map = {}    
    for (let i=0; i<arr.length; i++) {
      map[arr[i]] = []
    }
    return map;
  }

  const checkEmailList = async () => {
    if (emailList.length>0) {
      let email_list = emailList.split(';')
      for (let i=0; i<email_list.length; ++i) {
        if (!checkEmail(email_list[i])) {
          notify("Invalid email address in list", "error")
          return false;
        }
      }
      return true;
    } else {
      return true;
    }
  }

  const submitRegistration = async (e) => {
    e.preventDefault()

    if (emailList.length>0) {

      if (await checkEmailList()===false) return;

      let division_blob = await createBlobFromArray(divisionList);  
      let email_map = await createArrayMap(divisionList)
      email_map = JSON.stringify(email_map);

      try {
        const newUser = {
          name,
          username,
          email,
          password,
          phone,
          address,
          division_blob,
          emailList,
          email_map,
        }

        const config = { headers: { 'Content-Type': 'application/json' } }
        const body = JSON.stringify(newUser)
        const response = await axiosPrivate.post('/register', body, config)
        //console.log(response.data);
        if (response?.data?.errno === 1062) {
          notify("Username already exists", "error")        
        }
        else {
          notify("Registration Successful", "success")      
          setStep(1);
          setDivisionList([]);
          setEmailList('');
          setFormData({
            name: '',
            username: '',
            email: '',
            password: '',
            confirm_password: '',
            phone: '',
            address: '',
          })   
        }       
      } 

      catch (err) { 
          console.log(err)
      }
    }      
    else {
      notify("Please add at least one email", "error")
    }
  }

  const checksubmit = async () => {
    let division_blob = await createBlobFromArray(divisionList);  
    let email_map = await createArrayMap(divisionList)
    email_map = JSON.stringify(email_map);
    let email_list = emailList.join(";");

    if (division_blob == "") { 
      division_blob = `${username}Default1`;
      email_map = `"{\"${username}Default1\":[]}`; 
    }
    

    try {
      const newUser = {
        name,
        username,
        email,
        password,
        phone,
        address,
        division_blob,
        email_list,
        email_map,
      }
      const body = JSON.stringify(newUser)
      //console.log(newUser)
      //console.log(body);
    }
    catch (error) {
      console.log(error);
    }
  }

  const onChangeEmailList = (e) => {
    if (e.target.value.includes('"') || e.target.value.includes('`')) {}
    else {
      setEmailList(e.target.value);
    }
  }


  return (
   
    <Layout>

      {step==1
      ?  
        <>
          <div class="w-full h-full p-8" >
            <h2>Register New User</h2>
            
            <div class="flex flex-wrap -mx-3 mb-1 py-6">

                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label for="name" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Name
                </label>
                <input value={name} name="name" onChange={(e) => onChange(e)} class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="name" type="text" placeholder="Client Name" />
                {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                </div>

                <div class="w-full md:w-1/2 px-3">
                <label for="username" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                    Username
                </label>
                <input value={username} name="username" onChange={(e) => onChange(e)} class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="username" type="text" placeholder="Login Username" />
              </div>

            </div>

            <div class="flex flex-wrap -mx-3 mb-6">           

                <div class="w-full px-3">
                <label for="address" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                    Address
                </label>
                <input value={address} name="address" onChange={(e) => onChange(e)} class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" placeholder="Full Address" />
                </div>             

            </div>

            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-full md:w-1/2 px-3">
              <label for="email" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                  Email
              </label>
              <input value={email} name="email" onChange={(e) => onChange(e)} class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" type="email" placeholder="john@email.com" />
              </div>

              <div class="w-full md:w-1/2 px-3">
              <label for="phone" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Phone
              </label>
              <input value={phone} name="phone" onChange={(e) => onChange(e)} class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="phone" type="text" placeholder="123-345-456" />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">

                <div class="w-full md:w-1/2 px-3">
                <label for="password" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                    Password
                </label>
                <input value={password} name="password" onChange={(e) => onChange(e)} class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="password" placeholder="*********" />
                </div>

                <div class="w-full md:w-1/2 px-3">
                <label for="confirm_password" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                    Confirm Password
                </label>
                <input value={confirm_password} name="confirm_password" onChange={(e) => onChange(e)} class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="confirm_password" type="password" placeholder="*********" />                
                </div>

            </div>

              {!isLoading?
              <div onClick={checkUserInputs} class="w-28 h-10 flex bg-blue-400 hover:bg-blue-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                  <p class="flex m-auto">Next</p>
              </div> 
              :   
              <div class="w-28 h-10 flex bg-blue-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                  <span class="flex m-auto"><Loading type={1} /></span>
              </div> 
              }
            
          </div>
        </>
      :
      step==2
      ?            
        <div class="h-100 w-100">
          <button class="text-xl ml-1 text-blue-600" onClick={() => setStep(1)}>← Back</button>
          <div class="flex w-100 pb-3">            
            <h3>Create List of Destinations</h3>
            <button onClick={addNewDivision} class="flex bg-gray-200 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded ml-auto" type="button">Add New</button>
          </div>

          <div class="w-100 h-3/4 p-1 bg-gray-400 overflow-scroll">
            {divisionList.map((division, index) => {
              return (
                <span class="w-100 relative">
                  <input onChange={(e) => onChangeDivision(index,e)} class="w-100 p-2 mb-2 bg-white rounded-md border border-black"value={division}></input>
                  <button onClick={() => {removeDivision(index)}} class=" absolute right-3 top-1 w-30 h-30 border-white bg-red-400 rounded-2xl">
                    <FiX color="white"/>
                  </button>
                </span>     
              )}
            )}
          </div>
                {!isLoading?
                <div onClick={checkDivisionsList} class="w-28 h-10 flex bg-blue-400 hover:bg-blue-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                    <p class="flex m-auto">Next</p>
                </div> 
                :   
                <div class="w-28 h-10 flex bg-blue-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                    <span class="flex m-auto"><Loading type={1} /></span>
                </div> 
                }
        </div>
      :
        <>
          <button class="text-xl ml-1 text-blue-600" onClick={() => setStep(2)}>← Back</button>
          <h3>Create Email List</h3>
          <textarea value={emailList} onChange={(e) => onChangeEmailList(e)} class="rounded-md border-2 w-100 h-3/4 bg-white overflow-scroll text-black p-2" placeholder="Add emails seperated by ';'. Example: emailA@email.com;emailB@email.com;...   Do not end with a ';'">
          </textarea>
          {isLoading?
              <Loading type={2} text={""}></Loading>
            :
             <button onClick={(e) => submitRegistration(e)} class=" mt-2 flex rounded-md px-10 ml-auto mr-1 text-center bg-blue-400 hover:bg-blue-500 text-white py-3">
               Confirm
             </button>       
            //<button onClick={checksubmit} class=" mt-2 flex rounded-md px-10 ml-auto mr-1 text-center bg-blue-400 hover:bg-blue-500 text-white py-3">
            //  Check
            //</button>   
                
          }
          
        </>
      }
       
    </Layout>
  )
}
