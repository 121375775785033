import React from "react"
import Sidebar from "../modules/sidebar"


const Popup = ({ children }) => {
    
    return (

        <div id="editMenu" class="w-100 h-100 fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" aria-modal="true">
            <div class="w-100 h-100 flex items-end min-h-screen text-center sm:block ">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity min-h-full" aria-hidden="true"></div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                {children}

            </div>  
        </div>

    )
}

export default Popup

