import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';

import LayoutLanding from '../context/layoutLanding';

import { FaBoxOpen } from 'react-icons/fa';

import { GoPackage } from 'react-icons/go';

import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';


export default function LandingWarehousing() {

  const childRef = useRef();
  let location = useLocation();

  //useEffect(() => {
  //  const page = location.pathname;
  //  document.body.classNameList.add('is-loaded')
  //  childRef.current.init();
  //  trackPage(page);
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [location]);

//Comprehensive Warehousing Solutions for All Your Goods
//Our warehousing services are designed to cater to the needs of businesses of all types and sizes. 
//Our state-of-the-art facilities are equipped with the latest technology to provide safe and secure 
//storage for all types of goods, including special items such as dangerous goods. Our experienced team of 
//logistics professionals is dedicated to ensuring that your items are stored in the most appropriate way, and can provide customized solutions to meet your specific warehousing needs. From short-term storage to long-term solutions, our warehousing services are designed to provide maximum flexibility and convenience, so you can focus on running your business with confidence.

//Quick Access to Your Inventory with Our Innovative Warehouse Management System
//At our company, we understand the importance of quick and easy access to your inventory. That's why we've developed an innovative warehouse management system that allows you to track your inventory in real-time, so you always know what you have in storage. Our advanced technology also allows you to easily request delivery of your items at any time, so you can rest assured that your items will be sent out for delivery as soon as possible. With our flexible warehousing services and state-of-the-art technology, you can manage your inventory with ease and efficiency.

//Customized Warehousing Solutions to Meet Your Unique Needs
//We know that every business has unique warehousing needs, which is why we offer customized solutions that can be tailored to your specific requirements. Our experienced logistics professionals work closely with you to create a custom warehousing plan that fits your business needs and budget. Whether you need specialized handling for dangerous goods or temperature-controlled storage for perishable items, we have the expertise and resources to get the job done right. With our flexible and customizable warehousing services, you can rest assured that your items are stored in the most appropriate way and

  return (
    
    <LayoutLanding>

      <svg width="0" height="0">
        <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
          <stop stopColor="#6e70ff" offset="0%" />
          <stop stopColor="#271aa1" offset="100%" />
        </linearGradient>
      </svg>


        <div class="min-w-0 max-w-2xl flex-auto px-4 py-6 lg:max-w-none lg:pr-0 lg:pl-8 xl:px-16">
                  <section class="container pt-12 shadow-lg h-100 bg-white rounded">
                    <header class="mb-9 space-y-1 px-6">
                      <p class="font-display text-sm font-medium text-indigo-600">OUR WAREHOUSING SOLUTIONS OFFER SOMETHING FOR EVERYONE.</p>
                      <h2 class="mb-8 text-4xl tracking-tight font-extrabold  text-gray-700">
                        Warehousing
                      </h2>
                    </header>
                    <div class="prose prose-slate px-6  max-w-none dark:prose-invert dark:text-slate-600 prose-headings:scroll-mt-28 prose-headings:font-display prose-headings:font-normal lg:prose-headings:scroll-mt-[8.5rem] prose-lead:text-slate-500 dark:prose-lead:text-slate-400 prose-a:font-semibold dark:prose-a:text-sky-400 prose-a:no-underline prose-a:shadow-[inset_0_-2px_0_0_var(--tw-prose-background,#fff),inset_0_calc(-1*(var(--tw-prose-underline-size,4px)+2px))_0_0_var(--tw-prose-underline,theme(colors.sky.300))] hover:prose-a:[--tw-prose-underline-size:6px] dark:[--tw-prose-background:theme(colors.slate.900)] dark:prose-a:shadow-[inset_0_calc(-1*var(--tw-prose-underline-size,2px))_0_0_var(--tw-prose-underline,theme(colors.sky.800))] dark:hover:prose-a:[--tw-prose-underline-size:6px] prose-pre:rounded-xl prose-pre:bg-slate-900 prose-pre:shadow-lg dark:prose-pre:bg-slate-800/60 dark:prose-pre:shadow-none dark:prose-pre:ring-1 dark:prose-pre:ring-slate-300/10 dark:prose-hr:border-slate-800">
                      <p>
                        At our warehouses, we understand that businesses' storage requirements can change rapidly based on their demand. 
                        Our flexible warehousing solutions offer scalability to help businesses adapt to changing circumstances. Whether you're 
                        expanding or downsizing, we can help you adjust your storage capacity to meet your needs. 
                        
                      </p>
                      <hr></hr>
                      <h2 class="flex my-6 text-2xl tracking-tight font-extrabold  text-gray-700">
                        Almost Anything</h2>              
                      <p>    
                        Our warehousing services are designed to accommodate almost anything you need stored, no matter how big or small. 
                        We have a variety of storage options to cater to 
                        different types of products, ensuring the safety and security of your goods. We can provide flexible arrangements for both long-term 
                        and short-term storage solutions.
                        <br></br>
                        <br></br>
                        Our storage options are designed to meet your specific business needs. Our team works closely with you to tailor solutions that  
                        help streamline your operations, reduce costs, and maximize 
                        efficiency. Our online platform also helps
                        businesses optimize their inventory management by providing visibility of their stock, helping them avoid stockouts
                        and minimize holding costs.
                        <br></br>
                        <br></br>
                        We also offer value-added services that can help businesses streamline their operations. 
                        We provide packing and unpacking, palletization, labeling, and repacking services. We can also handle returns, 
                        reverse logistics, and provide order fulfillment services. We are equipped to handle all your 
                        logistics needs and can help you save time and money by providing turnkey solutions that work for your business.
                      </p>
                      <h2 class="flex my-6 text-2xl tracking-tight font-extrabold  text-gray-700">
                        Complete Storage</h2>              
                      <p>
                        We know that managing storage and logistics can be a significant expense. We are able to handle dangerous goods and 
                        delicate materials. We use quality packaging material 
                        and secure storage options to prevent any damage during storage or transport. 
                        This flexibility allows businesses to adjust their storage requirements to meet changing demand, avoiding 
                        the need to invest in expensive infrastructure and equipment. 
                        <br></br>
                        <br></br>
                        We also take security very seriously at our warehouses. Our facilities are equipped with security measures to ensure the safety and 
                        protection of your goods. We have 24/7 surveillance cameras, access control systems, and alarm systems to monitor our facilities. 
                        You can trust that your goods are in safe hands.
                        
                      </p>
                      <h2 class="flex my-6 text-2xl tracking-tight font-extrabold  text-gray-700">
                        Quick Access</h2>              
                      <p>
                        We place a lot of importance on maintaining quick turnaround times. Our streamlined processes and experienced personnel 
                        ensure that your products are received, stored, and dispatched in the shortest time possible. We leverage technology to 
                        facilitate efficient order processing and tracking and our inventory management system provides real-time visibility of your 
                        goods. We offer flexible 
                        delivery options, including same-day and next-day delivery, to meet urgent or time-sensitive needs. 
                        This means that you can expect high levels of consistency and reliability from our warehousing services.
                        <br></br>
                        <br></br>                        
                        In summary, our warehousing services offer businesses flexible, scalable, and cost-effective solutions to manage their 
                        storage and logistics operations. With a wide range of storage options and value-added services, we can accommodate any 
                        business need. Our focus on security, reliability, and customer service ensures that your goods are in safe 
                        hands and that you can trust us to manage your logistics effectively. Contact us today to learn how we can help your 
                        business succeed.           
                      </p>

                    </div>


                    <div class="p-4 border-t-2 border-gray-700 prose prose-slate max-w-none dark:prose-invert dark:text-slate-400 prose-headings:scroll-mt-28 prose-headings:font-display prose-headings:font-normal lg:prose-headings:scroll-mt-[8.5rem] prose-lead:text-slate-500 dark:prose-lead:text-slate-400 prose-a:font-semibold dark:prose-a:text-sky-400 prose-a:no-underline prose-a:shadow-[inset_0_-2px_0_0_var(--tw-prose-background,#fff),inset_0_calc(-1*(var(--tw-prose-underline-size,4px)+2px))_0_0_var(--tw-prose-underline,theme(colors.sky.300))] hover:prose-a:[--tw-prose-underline-size:6px] dark:[--tw-prose-background:theme(colors.slate.900)] dark:prose-a:shadow-[inset_0_calc(-1*var(--tw-prose-underline-size,2px))_0_0_var(--tw-prose-underline,theme(colors.sky.800))] dark:hover:prose-a:[--tw-prose-underline-size:6px] prose-pre:rounded-xl prose-pre:bg-slate-900 prose-pre:shadow-lg dark:prose-pre:bg-slate-800/60 dark:prose-pre:shadow-none dark:prose-pre:ring-1 dark:prose-pre:ring-slate-300/10 dark:prose-hr:border-slate-800">
                      <p class="text-center font-bold text-black my-4">
                          We want to hear from you. Get in touch with us to learn more about our B2B transport, warehousing and logistics services. 
                      </p>
                      
                      <div class="w-100 flex">
                        <a href="/contact" class="mx-auto inline-flex no-underline items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-black hover:!bg-gray-800 rounded sm:w-auto sm:mb-0">
                              Contact Us
                              <span class="ml-2">
                                <IoIosArrowForward />
                              </span>
                              {/* <svg class="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg> */}
                          </a>
                        </div>
                    </div>


                  </section>

        </div>

    </LayoutLanding>
  );
}
















