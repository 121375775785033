import React, { useRef, useEffect, useState } from 'react';

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Checkbox from '@mui/material/Checkbox';

import Loading from './loading';

import { FiX } from 'react-icons/fi';
import { BsTrash, BsX } from 'react-icons/bs';

import Popup from '../context/layoutPopup.jsx';

import useRefreshToken from '../hooks/useRefreshToken';


function arrayAlphebetize(array) {
    return array.sort((a, b) => a.localeCompare(b));
}

function removeDuplicatesFromArray(array) {
    return array.filter((a, b) => array.indexOf(a) === b);
} 

function checkEmail(str) {
    if (str.length>0) {    
        let val = str;
        if(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val)) {
            return true
        } else {
            return false
        }
    } else {
        return true;
    }
}

function createMap(arr) {
    let map = {};
    for (let i=0; i<arr.length; ++i) {
        map[arr[i]] = false;
    }
    return map;
}


export default function MenuChecklist({ emailsObject, emailsBlob, client, toggleDisplay, updateParent, notify }) {

    const axiosPrivate = useAxiosPrivate();    
    const refresh = useRefreshToken();
    
    const [isLoading, setIsLoading] = useState(false);
    const [changesMade, setChangesMade] = useState(false);
    const [displayMenu,  setDisplayMenu] = useState(false);
    const [newEmailsBlob, setNewEmailsBlob] = useState(JSON.parse(JSON.stringify(emailsBlob)));
    const [newEmailsObject, setNewEmailsObject] = useState(JSON.parse(JSON.stringify(emailsObject)));

    const [displayConfirmationPopup, setDisplayConfirmationPopup] = useState(false);
    const [confirmCode, setConfirmCode] = useState(0);
    
    
    useEffect(() => {
        //console.log(newEmailsObject)
    }, []);   

    const onSubmitEditEmails = async () => {
        setIsLoading(true);
        try {
            const newEmailsList = newEmailsBlob.split(';');
            for (let i=0; i<newEmailsList.length; ++i) {                  
                if (!checkEmail(newEmailsList[i])) {
                    throw new Error("Invalid email in list");
                }                     
            };
            const body = { user:client, emails:newEmailsBlob } 
            //const body = JSON.stringify(emailsBlob)
            const response = await axiosPrivate.post(`/users/emails`, body, { method: "POST", credentials: "include" });
            setChangesMade(false);            
            const newEmailsObject = createMap(JSON.parse(JSON.stringify(newEmailsBlob)).split(';'));
            await refresh();
            await updateParent(newEmailsObject);            
            setNewEmailsObject(newEmailsObject);
            notify("Emails updated", "success") 
            setIsLoading(false);                    
        }
        catch (error) {
            //console.log(error)
            notify("Invalid email in list", 'error');
            setIsLoading(false);
        }
    }

    const editEmailBlob = (e) => {
        if (!changesMade) { setChangesMade(true); }
        if (e.target.value.includes('"') || e.target.value.includes('`')) {}
        else {
            setNewEmailsBlob(e.target.value);
        }
    }

    const continueWithoutSaving = () => {
        setDisplayConfirmationPopup(true);
        setConfirmCode(0);
    }

    const abortJobOrderChanges = () => {
        setDisplayConfirmationPopup(false);
        setDisplayMenu(0);
        setNewEmailsBlob(JSON.parse(JSON.stringify(emailsBlob)))
        setChangesMade(false);
    }

    const onSelectItem = (item) => {     
        //console.log("EMAIL OBJECT", newEmailsObject)   
        let newMap = JSON.parse(JSON.stringify(newEmailsObject));
        newMap[item] = !newMap[item];
        setNewEmailsObject(newMap);
    }

    const onConfirmSelectedEmails = async () => {
        setIsLoading(true);
        await updateParent(newEmailsObject);
        setIsLoading(false);
        toggleDisplay(false);
        //console.log(emailObject);

    }


    return (
        <Popup>

            {displayConfirmationPopup? // create a centered popup to confirm action
                <div id="popupMenu" class="absolute z-20 mx-auto my-auto inset-0 overflow-hidden h-1/4 p-2 w-1/3 bg-gray-500 text-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
                    <div class="flex bg-gray-500 text-white sm:p-6 h-full w-100 ">

                        {confirmCode==0
                        ? // Leave Without Saving Edit
                        <>
                            <div class="flex">         
                                <div class="ml-6 absolute">
                                    <h3 class="text-white w-100">Leave without saving?</h3>
                                    <p class="text-white w-100">Any changes to emails will not be saved if you leave now.</p>
                                </div>
                            </div>
                            <div class="flex ml-auto mt-auto">
                                <button onClick={isLoading?null:() => setDisplayConfirmationPopup(false)} class="bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded mr-2">Cancel</button>
                                <div class="">
                                    {!isLoading?
                                    <div onClick={abortJobOrderChanges} class="w-28 h-10 flex bg-red-400 hover:bg-red-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                                        <p class="flex m-auto">Confirm</p>
                                    </div> 
                                    :   
                                    <div class="w-28 h-10 flex bg-blue-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span>
                                    </div> 
                                    }
                                </div> 
                            </div>
                        </>                        
                        :null}

                    </div>
                </div>                                                    
            :null}

            <div class="flex absolute top-5 right-5">
                <button onClick={() => toggleDisplay(false)} class="h-10 w-10 bg-gray-200 hover:bg-gray-300 rounded-full flex m-2">
                    <BsX class="m-auto" size={25}/>
                </button>
            </div>
            <div id="innerEditMenu" class="h-2/3 w-1/2 pt-3 pb-0 inline-block bg-gray-200 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">

                {displayMenu==0
                ? // Select Email List
                <div class="bg-gray-200 pb-4 sm:p-6 h-full">
                    <div class="flex pb-1 pt-3">
                        <div class="align-center">
                            <h5 class="ml-3">Select Emails for this order</h5>                                    
                        </div>
                        <span onClick={() => setDisplayMenu(1)} class="ml-auto text-lg font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">Add/Edit Email List</span>
                    </div>  

                    <div class="flex flex-wrap -mx-3 px-6 py-4 h-3/4 max-h-3/4">
                        <div class="shadow w-100 px-3 bg-gray-100 h-100 border-black overflow-scroll py-2">                                        
                            
                            {newEmailsObject?
                                Object.keys(newEmailsObject)[0].length>0                                        
                                    ?Object.keys(newEmailsObject).map((key, index) => {
                                        //console.log(newEmailsObject[key])
                                        return (
                                            <div class="w-100 flex h-10"> 
                                            {   newEmailsObject[key]
                                                ?<Checkbox onChange={() => onSelectItem(key)} checked/>
                                                :<Checkbox onChange={() => onSelectItem(key)} />}
                                                <p class="font-bold align-middle ml-2 my-auto flex">{key}</p>
                                            </div>
                                        )}
                                    )
                                    :
                                    <div class="h-100 w-100 flex">
                                        <p class="flex m-auto text-red-500 font-bold">No Emails Found</p>
                                    </div>
                                :<p>???</p>
                            }
                                                                    
                        </div>
                    </div> 

                    <div class="w-full md:w-100 px-3 mt-2">
                        {!isLoading?
                        <div onClick={onConfirmSelectedEmails} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                            <p class="flex m-auto">Confirm</p>
                        </div> 
                        :   
                        <div class="w-36 h-10 flex bg-blue-700 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                            <span class="flex m-auto"><Loading type={1} /></span>
                        </div> 
                        }
                    </div> 

                </div>                
                :displayMenu==1
                ? // Add/Edit Email List
                <div class="bg-gray-200 pb-4 sm:p-6 h-full">                    
                    <div class="flex pb-1 pt-3">
                        <span onClick={changesMade?continueWithoutSaving:() => setDisplayMenu(0)} class="text-lg font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">← Back</span>
                        <div class="align-center flex mx-auto">
                            <h5 class="text-black">Add/Edit Emails List</h5>                                                                            
                        </div>                                    
                    </div>  

                    <div class="flex flex-wrap -mx-3 px-6 py-4 h-3/4 max-h-3/4">
                        <div class="shadow w-100 px-3 bg-gray-100 h-100 border-black overflow-scroll py-2">                                        

                            <textarea 
                                class="w-100 h-100 border-blue-400 border-2 rounded p-2"
                                onChange={(e) => editEmailBlob(e)} 
                                value={newEmailsBlob}
                                placeholder="Add emails seperated by ';'. Example: emailA@email.com;emailB@email.com;...   Do not end with a ';'"
                            />
                                                        
                        </div>
                    </div> 

                    <div class="w-full md:w-100 px-3 mt-2">
                        {!isLoading?
                        <div onClick={onSubmitEditEmails} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                            <p class="flex m-auto">Save</p>
                        </div> 
                        :   
                        <div class="w-36 h-10 flex bg-blue-700 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                            <span class="flex m-auto"><Loading type={1} /></span>
                        </div> 
                        }
                    </div> 

                </div>
                :null}

            </div>  

        </Popup>                    
    );
    
}