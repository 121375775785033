import axios from 'axios';

//const BASE_URL = 'http://localhost:9001';
//const BASE_URL = 'https://locksanddocks.com:8443';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    credentials: 'include'
});

export const axiosPrivateBlob = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    credentials: 'include',
    responseType: 'blob'
});





