import { axiosPrivate } from "../context/axios";
import { useState, useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { Navigate, useNavigate, Link } from 'react-router-dom';

const useAxiosPrivate = () => {

    const refresh = useRefreshToken();
    const { auth } = useAuth();
    const { setAuth, persist, setPersist } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {

        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if(!config.headers["Authorization"]) {
                config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        )

        
        const responseIntercept = axiosPrivate.interceptors.response.use(
            (response) => response,
            async (error) => { 
                console.log("INTERCEPTOR ERROR", error)
                const prevRequest = error?.config;
                if (error.response.status === 403 && !prevRequest?.sent) {
                    
                    // This code automatically resets the refresh token so that you dont need to reauthenticate
                    //prevRequest.sent = true;
                    //const newAccessToken = await refresh();
                    //prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    //return axiosPrivate(prevRequest);

                    // Need to reauthenticate because the refresh token has expired
                    setAuth({});
                    navigate('/login', { replace: true });

                }
            }
       )
       return () => {
          axiosPrivate.interceptors.request.eject(requestIntercept);
          axiosPrivate.interceptors.response.eject(responseIntercept);
       }
    },[auth, refresh])

    return axiosPrivate;

}

export default useAxiosPrivate;
