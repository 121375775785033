import React, { useRef, useEffect, useState } from 'react';

import Popup from '../context/layoutPopup.jsx';
import Loading from './loading.jsx';

import useAxiosPrivate from '../hooks/useAxiosPrivate';

import { FiX } from 'react-icons/fi';
import { BsX } from 'react-icons/bs';


function arrayAlphebetize(array) {
    console.log(array);
    return array.sort((a, b) => a.localeCompare(b));
}
  
function removeDuplicatesFromArray(array) {  
    return array.filter((a, b) => array.indexOf(a) === b);
} 



export default function MenuEditOrders({ data, divisionMap, onToggleDisplay, updateParent, notify}) {

    const axiosPrivate = useAxiosPrivate();    

    const [isLoading, setIsLoading] = useState(false);
    const [newEditData, setNewEditData] = useState(JSON.parse(JSON.stringify(data)));
    const [isSelected, setIsSelected] = useState(false);    
    const [customFrom, setCustomFrom] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [displayConfirmationPopup, setDisplayConfirmationPopup] = useState(false);

    const list_of_clients = arrayAlphebetize(removeDuplicatesFromArray(Object.keys(divisionMap)));
    const list_of_destinations = arrayAlphebetize(removeDuplicatesFromArray(divisionMap[newEditData.client].split(";")))

    
    useEffect(() => {        
        
        // if data.destination is not in list_of_destinations, set customFrom to true
        if (!list_of_destinations.includes(data.destination)) {
            setCustomFrom(true);
        }
       
    }, []);    


    const preFlightChecks = () => {
        if (newEditData.owner == "") {
            notify("Please select an owner", "error");
            return false;
        }
        if (newEditData.weight == "") {
            notify("Please enter a weight", "error");
            return false;
        }
        if (newEditData.quantity == "") {
            notify("Please enter a quantity", "error");
            return false;
        }
        if (newEditData.destination == "") {
            notify("Please enter a destination", "error");
            return false;
        }
        return true;
    }         

    const submitUpdateOrder = async () => {

        const checked = preFlightChecks();
        if (!checked) return;

        try{
            setIsLoading(true);
            const config = { headers: { 'Content-Type': 'application/json' } };
            const body = JSON.stringify(newEditData)
                    
            const fileData = new FormData()            
            fileData.append('name', newEditData.id);
            fileData.append('data', body)
            fileData.append('new_file_name','new_name_here.pdf')       
            fileData.append('file', selectedFile)

            const response = await axiosPrivate.post(`/orders/update`, fileData, {headers: { 'Content-Type': 'multipart/form-data' }});
            console.log(response.data);
            await updateParent();
            onToggleDisplay(false)
            setIsLoading(false); 
            notify("Order updated successfully", "success");   

        } catch (error) {
            console.error('Error updating order:', error);
            onToggleDisplay(false)
            setIsLoading(false);
            notify('Error updating order', 'error');
        }
               
    } 

    const submitDeleteOrder = async () => {

        try {
            setIsLoading(true);
            const config = { headers: { 'Content-Type': 'application/json' } };
            const body = JSON.stringify(newEditData)
            const response = await axiosPrivate.post(`/orders/delete`, body, { method: "POST", credentials: "include" });
            console.log(response.data);
            await updateParent();
            setDisplayConfirmationPopup(false);
            onToggleDisplay(false)
            setIsLoading(false);
            notify("Order deleted successfully", "success");
        }
        catch (error) {
            console.error('Error deleting order:', error);
            setDisplayConfirmationPopup(false);
            onToggleDisplay(false)
            setIsLoading(false);
            notify('Error updating order', 'error');
        }
    }

    const uploadFile = (event) => {
        setSelectedFile(event.target.files[0 ]);
        setIsSelected(true);
    };    

    const onChange = (e) => {    
        if (e.target.value.includes('"') || e.target.value.includes('`')) {}
        else {
            setNewEditData({...newEditData, [e.target.name]: e.target.value});
        }
    }

    const changeCustom = (bool) => {

        setCustomFrom(bool);

        if (!bool) {
            setNewEditData({...newEditData, destination: list_of_destinations[0]});            
        } else {
            
            setNewEditData({...newEditData, destination: ""});
        }
    }

        
    return (

        <Popup>

            <div class="flex absolute top-5 right-5">
                <button onClick={() => onToggleDisplay(false)} class="h-10 w-10 bg-gray-200 hover:bg-gray-300 rounded-full flex m-2">
                    <BsX class="m-auto" size={25}/>
                </button>
            </div>

            {displayConfirmationPopup? // create a centered popup to confirm deletion
                <div id="popupMenu" class="absolute z-20 mx-auto my-auto inset-0 overflow-hidden h-1/4 p-2 w-1/2  bg-gray-500 text-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
                    <div class="bg-gray-500 text-white sm:p-6 h-full w-100 flex">

                            <div class="flex">
                                <div class="ml-6">
                                    <h3 class="">Delete Order #{data.id}</h3>
                                    <p class="text-white">Are you sure you want to delete this order?</p>
                                </div>
                            </div>
                            <div class="flex ml-auto mt-auto">
                                <button onClick={isLoading?null:() => setDisplayConfirmationPopup(false)} class="bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded mr-2">Cancel</button>                                      
                                <div class="">
                                    {!isLoading?
                                    <div onClick={submitDeleteOrder} class="w-28 h-10 flex bg-red-400 hover:bg-red-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                                        <p class="flex m-auto">Confirm</p>
                                    </div> 
                                    :   
                                    <div class="w-28 h-10 flex bg-red-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span>
                                    </div> 
                                    }
                                </div> 

                            </div>

                    </div>
                </div>                                                    
            :null}

            <div id="innerEditMenu" class="h-3/4 pt-3 pb-0 w-3/4 inline-block bg-gray-200 rounded-lg text-left overflow-scroll shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">            

                <div class="flex">

                    <h3 class="ml-6">Edit Order</h3>

                    {/* <div class="w-64 ml-auto mr-3 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                        Date
                    </label>
                    <input autoComplete="off" defaultValue={date} class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" />
                    </div> */}
                </div>                    

                <div class="flex flex-wrap -mx-3 px-6 py-4">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                        Client *
                    </label>
                    <select name="client" onChange={(e) => onChange(e)} class="shadow block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="client" type="text">
                        {divisionMap?
                        list_of_clients.map(d => {
                            return <option value={d} selected={d==newEditData.client?true:false}>{d}</option> 
                        })
                    :
                    <option value="default">default</option>
                    }
                    </select>
                    {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                    </div>

                    <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Destination *
                            {customFrom
                            ?<button onClick={() => changeCustom(false)} class="text-blue-500 ml-4 hover:text-blue-600 cursor-pointer hover:underline">PREDEFINED</button>
                            :<button onClick={() => changeCustom(true)} class="text-blue-500 ml-4 hover:text-blue-600 cursor-pointer hover:underline">CUSTOM</button>                            
                            } 
                        </label>


                        {customFrom?
                         <input autoComplete='off' value={newEditData.destination} name="destination" onChange={(e) => onChange(e)} placeholder="Destination" class="shadow block w-full bg-white text-gray-700 black border-black border-solid border-2 py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text"></input>
                         :
                         <select name="destination" onChange={(e) => onChange(e)} class="shadow block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="destination" type="text" >
                            {divisionMap?.[newEditData.client]?
                                divisionMap[newEditData.client].split(";").map(d => {
                                    if (d==newEditData.destination) {                                        
                                        return <option selected value={d}>{d}</option>
                                    }
                                    else {                        
                                        
                                        return <option value={d}>{d}</option>
                                    }
                                })                                
                        :
                        <option value="default">default</option>
                        }
                        </select>}


                    </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-2 px-6">
                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                        Reference
                    </label>
                    <input value={newEditData.reference} maxLength={50} name="reference" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reference" type="text" placeholder="" />
                    </div>

                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                        Supplier
                    </label>
                    <input value={newEditData.supplier} maxLength={50} name="supplier" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="supplier" type="text" placeholder="" />
                    </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-2 px-6">
                    <div class="w-full md:w-1/3 px-3">
                    <label for="quantity"  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Quantity
                    </label>
                    <input value={newEditData.quantity} min={1} step={1} maxLength={9} name="quantity" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="quantity" type="number" />
                    </div>

                    <div class="w-full md:w-1/3 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="unit">
                        Unit
                    </label>
                    <input value={newEditData.unit} min={0.1} step={0.1} maxLength={50} name="unit" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="unit" type="text" />
                    </div>

                    <div class="w-full md:w-1/3 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="weight">
                        Weight    ( <button value={newEditData.weightType} name="weightType" onClick={(e) => setNewEditData({...newEditData, weightType: "LBS"})} style={newEditData.weightType=="LBS"?{color:"blue"}:{color:"black"}} class="hover:underline">LBS</button> / <button value={newEditData.weightType} name="weightType" class="hover:underline" onClick={(e) => setNewEditData({...newEditData, weightType: "KGS"})} style={newEditData.weightType=="KGS"?{color:"blue"}:{color:"black"}}>KGS</button> )
                    </label>

                    <input value={newEditData.weight} maxLength={9} name="weight" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" min={0.1} id="weight" />
                    </div>                    
                </div>

                <div class="flex flex-wrap -mx-3 mb-2 px-6">
                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="carrier">
                        Carrier
                    </label>
                    <input autoComplete="off" value={newEditData.carrier} maxLength={50} name="carrier" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="carrier" type="text" placeholder="" />
                    </div>

                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="waybill">
                        Waybill
                    </label>
                    <input value={newEditData.waybill} name="waybill" maxLength={50} onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="waybill" type="text" placeholder="" />
                    </div>
                </div>        

                <div class="w-full h-32 mr-3 px-7">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="note">
                        Description
                    </label>
                    <input value={newEditData.description} maxLength={250} onChange={(e) => onChange(e)} name="description" autoComplete="off" placeholder="Order description..." class=" shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="description" type="text" />
                </div>          

                <div class="w-full h-32 mr-3 px-7">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="notes">
                        Notes
                    </label>
                    <textarea style={{resize:'none'}} value={newEditData.note} name="note" maxLength={250} onChange={(e) => onChange(e)} autoComplete="off" placeholder="Additional notes..." class="h-full shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="notes" type="text" />
                </div> 

                <div class="flex flex-wrap -mx-3 mb-2 px-6 mt-5">

                    <div class="w-full md:w-1/3 px-3">
                        <label for="status" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            Status
                        </label>
                        <select name="status" onChange={(e) => setNewEditData({...newEditData, status: e.target.value})} class="shadow block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="status" >
                            <option selected={data.status=="Inventory"?true:false} value="Inventory">Inventory</option>
                            <option selected={data.status=="Pending"?true:false} value="Pending">Pending</option>
                            <option selected={data.status=="Delivered"?true:false} value="Delivered">Delivered</option>
                            <option selected={data.status=="Damaged"?true:false} value="Damaged">Damaged</option>
                            <option selected={data.status=="Lost"?true:false} value="Lost">Lost</option>
                            <option selected={data.status=="Delayed"?true:false} value="Delayed">Delayed</option>
                        </select>
                    </div>

                    <div class="w-full md:w-1/3 px-3 mt-4">
                    <form action="#" className="upload_button_div">
                        <label class="font-bold">Upload Packing Slip</label>                            
                        <br></br>
                        <input 
                            /*ref={this.resetFile.ref0}*/  
                            style={isSelected?{color:"green",fontWeight:"bold"}:{color:"red",fontWeight:"bold"}} 
                            className={isSelected?"upload_file_input_selected":"upload_file_input_none"} 
                            type="file" 
                            name="file"
                             onChange={uploadFile} 
                        />
                        {/* <button className="upload_button" onClick={(e) => this.handleSubmission(e)}>Upload</button> */}
                    </form>
                    </div>

                    <div class="flex w-full md:w-1/3 px-3 mt-4">
                        <div onClick={() => setDisplayConfirmationPopup(true)} class="w-36 h-10 flex bg-red-400 hover:bg-red-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                            <p class="flex m-auto">Delete</p>
                        </div>                               
                        {!isLoading?
                        <div onClick={displayConfirmationPopup?null:submitUpdateOrder} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                            <p class="flex m-auto">Update</p>
                        </div> 
                        :   
                        <div class="w-36 h-10 flex bg-blue-700 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                            <span class="flex m-auto"><Loading type={1} /></span>
                        </div> 
                        }
                    </div> 

                
                </div>  
            </div>             

        </Popup>


    );
    
}