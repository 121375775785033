import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';

import { GiExtraTime } from 'react-icons/gi';
import { CiCircleChevRight } from 'react-icons/ci';

import LayoutLanding from '../context/layoutLanding';
import { FiMapPin, FiChevronRight } from 'react-icons/fi';
import { IoIosArrowForward } from 'react-icons/io';


export default function LandingDelivery() {

  const childRef = useRef();
  let location = useLocation();

  //useEffect(() => {
  //  const page = location.pathname;
  //  document.body.classNameList.add('is-loaded')
  //  childRef.current.init();
  //  trackPage(page);
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [location]);

  return (

    <LayoutLanding>

        {/* <p>        
        Locks and Docks is the courier service of the marine industry. Using our secure weatherproof bags, we provide pick up and delivery of your ships mail between vessels and your offices, and all points in between.
        Need your linens cleaned? We handle all laundry needs by providing pick up and delivery of linens.
        </p> */}

        {/* <section class="pt-32 mb-12 justify-center">

          <div class="w-full text-center">
            <h1 class="text-5xl font-bold text-gray-800">Delivery</h1>
            <p class="text-lg mt-4 text-gray-500 font-medium">
              We have an excellent track record throughout the industry.
            </p>
          </div>

          <div class="container px-5 py-6 mx-auto flex flex-wrap">          
            
            <div class="flex relative p-10 mb-4 sm:items-center md:w-2/3 mx-auto bg-gray-800 rounded-3xl">            
              <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">                
                <div class="p-2 bg-blue-500 pb-2 rounded-full">
                  <GiExtraTime class="bg-gray-500 rounded-full" size={100} />
                </div>
                <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
                  <h2 class="font-medium title-font text-gray-100 mb-1 text-xl">Reliability</h2>
                  <p class="leading-relaxed text-gray-200">
                    Reliable equipment for reliable results.
                  </p>
                </div>
              </div>
            </div>

            <div class="flex relative p-10 mb-4 sm:items-center md:w-2/3 mx-auto bg-gray-800 rounded-3xl ">
              <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                <div class="p-2 bg-blue-500 pb-2 rounded-full">
                  <GiExtraTime class="bg-gray-500 rounded-full" size={100} />
                </div>
                <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
                  <h2 class="font-medium title-font text-gray-100 mb-1 text-xl">Timely</h2>
                  <p class="leading-relaxed text-gray-200">If the job changes at the last minute, we adjust to change with it.</p>
                </div>
              </div>
            </div>

            <div class="flex relative p-10 sm:items-center md:w-2/3 mx-auto bg-gray-800 rounded-3xl">
                <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                  <div class="p-2 bg-blue-500 pb-2 rounded-full">
                    <GiExtraTime class="bg-gray-500 rounded-full" size={100} />
                  </div>
                  <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
                    <h2 class="title-font text-gray-100 mb-1 text-xl font-bold">Safety</h2>
                    <p class="leading-relaxed text-gray-200 ">Minimizing risks is a priority we take seriously.</p>
                  </div>
                </div>
            </div>       

          </div>
        </section> */}
        

        <div class="min-w-0 max-w-2xl flex-auto px-4 py-6 lg:max-w-none lg:pr-0 lg:pl-8 xl:px-16">
          <section class="container pt-12 shadow-lg h-100 bg-white rounded ">
            <header class="mb-9 space-y-1 px-6">
              <p class="font-display text-sm font-medium text-indigo-700">THE MOST CONVENIENT DELIVERY SERVICE AROUND.</p>
              <h2 class="mb-8 text-4xl tracking-tight font-extrabold  text-gray-700">
                Delivery
              </h2>
            </header>
            <div class="prose prose-slate px-6 max-w-none dark:prose-invert dark:text-slate-600 prose-headings:scroll-mt-28 prose-headings:font-display prose-headings:font-normal lg:prose-headings:scroll-mt-[8.5rem] prose-lead:text-slate-500 dark:prose-lead:text-slate-400 prose-a:font-semibold dark:prose-a:text-sky-400 prose-a:no-underline prose-a:shadow-[inset_0_-2px_0_0_var(--tw-prose-background,#fff),inset_0_calc(-1*(var(--tw-prose-underline-size,4px)+2px))_0_0_var(--tw-prose-underline,theme(colors.sky.300))] hover:prose-a:[--tw-prose-underline-size:6px] dark:[--tw-prose-background:theme(colors.slate.900)] dark:prose-a:shadow-[inset_0_calc(-1*var(--tw-prose-underline-size,2px))_0_0_var(--tw-prose-underline,theme(colors.sky.800))] dark:hover:prose-a:[--tw-prose-underline-size:6px] prose-pre:rounded-xl prose-pre:bg-slate-900 prose-pre:shadow-lg dark:prose-pre:bg-slate-800/60 dark:prose-pre:shadow-none dark:prose-pre:ring-1 dark:prose-pre:ring-slate-300/10 dark:prose-hr:border-slate-800">
              <p>
                Are you in need of a delivery service that can get your items where they need to be quickly and reliably? 
                Look no further. We offer comprehensive and customizable transportation solutions 
                for any item, big or small and we operate every day of the week to ensure that they arrive at their 
                destination when you need them to.
              </p>
              <hr></hr>              
              <h2 class="flex my-6 text-2xl tracking-tight font-extrabold  text-gray-700">                
                Fast, Flexible and Reliable</h2>              
              <p>
                With our fast and reliable delivery service, you can trust that your items will be delivered safely and efficiently. 
                Our drivers and logistics team are dedicated to providing the highest quality service possible, 
                ensuring that your goods are always handled with care and delivered on time. Whether you need to move a small package across 
                town or transport large, bulky items across provincial lines, we have the expertise and resources to make it happen.
                <br></br>
                <br></br>                
                We work closely with our clients to ensure that their unique requirements are met. From same-day or scheduled deliveries, 
                we offer a wide range of options to suit your needs.
                <br></br>
                <br></br>
                In addition to our fast and flexible delivery services, we also prioritize customer service and communication. Someone is
                available any time of day to answer questions or concerns you may have. You can trust that when you choose to work with us, you'll receive 
                the highest level of service and attention to detail.
              </p>
              <h2 class="flex my-6 text-2xl tracking-tight font-extrabold  text-gray-700">
                
                 Comprehensive Delivery Solutions for Businesses</h2>              
              <p>
                What is of utmost importance to us is offering a comprehensive delivery service that prioritizes the protection of your items.
                From delicate equipment to big machinery, we make sure to handle all items with extreme care. No excuses.                
                <br></br>
                <br></br>
                We understand the importance of transparency and communication when it comes to working with our clients.
                Our delivery services are fully customizable, allowing you to choose exactly 
                when and where you want your merchandise to be so that you can focus on running your business 
                while we take care of all the legwork.  
                <br></br>
                <br></br>
                We know that shipping can be a stressful process, which is why we make it our mission to provide 
                a hassle-free experience for those who trust us with their merchandise. We are all about results.
              </p>
              <h2 class="flex my-6 text-2xl tracking-tight font-extrabold  text-gray-700">
                
                 The Benefits of Choosing Our Transport Company</h2>              
              <p>
                We have been hired to provide a variety of services, such as transporting large delicate medical equipment to hospitals,
                providing courrier services for the marine industry, laundry services, forwarding emergency equipment, 
                and much more. We have a wide range of experience and are always ready to take on new challenges. 
                <br></br>
                <br></br>
                Data is something that is important for all companies. We are aware of that and we make sure to keep all of our clients 
                shipping and order data on hand. On our user dashboard you'll have access to all types of metrics and time series datasets 
                to help analyze your workflow. Check out our <a href="/logistics">Logistics page</a> for more information.
                <br></br>
                <br></br>
              </p>


            </div>


            <div class="p-4 border-t-2 border-gray-700 prose prose-slate max-w-none dark:prose-invert dark:text-slate-400 prose-headings:scroll-mt-28 prose-headings:font-display prose-headings:font-normal lg:prose-headings:scroll-mt-[8.5rem] prose-lead:text-slate-500 dark:prose-lead:text-slate-400 prose-a:font-semibold dark:prose-a:text-sky-400 prose-a:no-underline prose-a:shadow-[inset_0_-2px_0_0_var(--tw-prose-background,#fff),inset_0_calc(-1*(var(--tw-prose-underline-size,4px)+2px))_0_0_var(--tw-prose-underline,theme(colors.sky.300))] hover:prose-a:[--tw-prose-underline-size:6px] dark:[--tw-prose-background:theme(colors.slate.900)] dark:prose-a:shadow-[inset_0_calc(-1*var(--tw-prose-underline-size,2px))_0_0_var(--tw-prose-underline,theme(colors.sky.800))] dark:hover:prose-a:[--tw-prose-underline-size:6px] prose-pre:rounded-xl prose-pre:bg-slate-900 prose-pre:shadow-lg dark:prose-pre:bg-slate-800/60 dark:prose-pre:shadow-none dark:prose-pre:ring-1 dark:prose-pre:ring-slate-300/10 dark:prose-hr:border-slate-800">
                <p class="text-center font-bold text-black my-4">
                    We want to hear from you. Get in touch with us to learn more about our B2B transport, warehousing and logistics services. 
                </p>
                
                <div class="w-100 flex">
                  <a href="/contact" class="mx-auto inline-flex no-underline items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-black hover:!bg-gray-800 rounded sm:w-auto sm:mb-0">
                        Contact Us
                        <span class="ml-2">
                          <IoIosArrowForward />
                        </span>
                        {/* <svg class="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg> */}
                    </a>
                  </div>
              </div>


          </section>

          </div>
      
    </LayoutLanding>
  );
}

































