import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import useLogout from '../hooks/useLogout';

import useAuth from '../hooks/useAuth';
import useRefreshToken from '../hooks/useRefreshToken';

import Sidebar from '../modules/sidebar';
import Table from '../modules/table';

import Layout from '../context/layoutPortal.jsx';
import Plot from '../modules/plot.jsx';


export default function Data({ notify}) {

  const auth = useAuth();
  const refresh = useRefreshToken();
  const navigate = useNavigate();
  const childRef = useRef();
  //let location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);  
  const [showEditMenu, setShowEditMenu] = useState(false);
  

  useEffect(() => {
    //const page = location.pathname;
    //console.log(auth);    
  }, []);

  const fetchBaseData = async () => {


  } 

  return (

      <Layout>

        


        {/* <section className="flex items-center h-full bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"> */}
          <section class="flex items-center h-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
            <div className="container bg-gray-100 p-6 rounded-3xl flex flex-col items-center justify-center px-5 mx-auto my-8">
                <div className="max-w-md text-center">
                    <h2 className="mb-8 font-extrabold text-6xl dark:text-gray-900">
                        <span className="sr-only">Error</span>Coming Soon
                    </h2>
                    <p className="text-2xl font-semibold md:text-3xl">This feature is currently in testing.</p>
                    <p className="mt-4 mb-8 dark:text-gray-900">It will be available in a following update.</p>
                    {/* <a rel="noopener noreferrer" href="/" className="px-8 py-3 font-semibold rounded dark:bg-violet-400 dark:text-gray-900">Back to homepage</a> */}
                </div>
            </div>
        </section>
          {/* <Plot data={data} />                 */}
      </Layout>

  );
}