import React, { useRef, useEffect } from 'react';
//import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

import '../../static/css/home.css';

import Navbar from '../modules/navbar';
import Footer from '../modules/footer';

import LayoutLanding from '../context/layoutLanding';

// import video
import landing_video from '../../static/assets/videos/landing-video-hd.webm';
//import logo from '../../static/assets/images/logo_big_gray.png';
import logo from '../../static/assets/images/image832_white.png';

//import vid_shipping from '../../assets/97854-imprint-genius-hero.mp4'

//ReactGA.initialize(process.env.REACT_APP_GA_CODE);

//const trackPage = page => {
//  ReactGA.set({ page });
//  ReactGA.pageview(page);
//};

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};


export default function Home({ notify }) {

  //const childRef = useRef();
  //let location = useLocation();

  useEffect(() => {
    //const page = location.pathname;
    //document.body.classNameList.add('is-loaded')
    //childRef.current.init();
    //trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);


  return (

        <div class="h-100 w-100 overflow-hidden">

        <div class="absolute bg-black w-100 z-20">          
            <div class="px-12 flex items-center justify-between py-3">

              <div class="flex items-center space-x-6">
                <a href="/" class="flex"><img src={logo} height={100} width={100} alt="logo"></img></a>
                <div class="w-12 text-white pl-6">|</div>
                <a href="/transport" class="navbar_link hover:text-gray-200 no-underline font-medium tracking-wide cursor-pointer">Transport</a>
                <a href="/delivery" class="navbar_link hover:text-gray-200 no-underline font-medium tracking-wide cursor-pointer">Delivery</a>
                <a href="/warehousing" class="navbar_link hover:text-gray-200 no-underline font-medium tracking-wide cursor-pointer">Warehousing</a>
                <a href="/logistics" class="navbar_link hover:text-gray-200 no-underline font-medium tracking-wide cursor-pointer">Logistics</a>
                <a href="/about" class="navbar_link hover:text-gray-200 no-underline font-medium tracking-wide cursor-pointer">About</a>
                <a href="/contact" class="navbar_link hover:text-gray-200 no-underline font-medium tracking-wide cursor-pointer">Contact</a>                
              </div>

              <div  class="flex items-center space-x-8 ">
              </div>
      
              <div class="flex items-center space-x-6">
                <a href="/login" class="navbar_link text-gray-700 hover:text-gray-400 hover:no-underline font-medium tracking-wide no-underline">Sign In</a>
                <a href="/info" class="bg-gray-100 text-black hover:bg-blue-300 hover:text-black font-medium no-underline tracking-wide py-2 px-4 rounded transition duration-300 ease-in-out">Get Started</a>
                {/* <a href="#" class="text-gray-300 hover:text-white font-medium tracking-wide no-underline">English</a> */}
              </div>
            </div>
          </div>   

            {/* video goes here */}
            <div class="video-container">
              <video 
                  onContextMenu={e => e.preventDefault()}
                  id="landing_video" 
                  class="w-full h-full overflow-hidden" autoPlay muted loop>
                {/* <source src="https://www.shutterstock.com/shutterstock/videos/1028333156/preview/stock-footage-the-camera-follows-a-white-delivery-van-driving-on-a-desert-highway-into-the-sunset-low-angle-rear.webm" type="video/mp4" /> */}
                {/* <source src="https://www.shutterstock.com/shutterstock/videos/1028333153/preview/stock-footage-the-camera-follows-a-white-delivery-van-driving-on-a-desert-highway-into-the-sunset-side-view.webm"  type="video/mp4" /> */}
                <source src={landing_video} type="video/mp4" />
              </video>
            </div>   
          
          <div class="flex flex-col w-100 h-100 overflow-hidden justify-center bg-transparent">

               
          {/* <svg id="dot_svg" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" style={{zIndex: 10, position: 'absolute', marginBottom: '15px'}}>
            <pattern id="dot-pattern" patternUnits="userSpaceOnUse" width="80" height="40">

                <circle fill="#ddd" cx="20" cy="20" r="2"></circle>
                <circle fill="#ddd" cx="60" cy="20" r="2"></circle>
            </pattern>
            <rect width="160%" height="100%" fill="url(#dot-pattern)">
                <animateTransform attributeName="transform" type="translate" from="-80 0" to="0 0" dur="5s" repeatCount="indefinite" />
            </rect>
         </svg> */}




            {/* <div className="absolute flex flex-col w-100 top-auto transform -translate-y-1/2 text-center" style={{zIndex: 9999}}>              */}
            <div className="absolute flex flex-col w-100 top-auto transform text-center pl-20" style={{zIndex: 9999}}>             
                <p class="text-6xl text-white font-semibold">
                  Transport Anything and Anyone 
                  <br></br>
                  <span class="font-regular text-rose-500 ml-3 mr-3 font-semibold">Anytime</span> 
                  in Canada.
                </p>    

                {/* <p class="text-2xl text-white font-semibold py-2">
                  24 hours a day. 7 days a week. Holidays. Weekends.
                </p> */}

                <p id="home_text" class="relative font-bold py-2">
                  Custom solutions for transporting goods, equipment and personnel.
                  <br></br>
                  Warehousing. Forwarding. Analytics. Lowest prices in the industry.
                  <br></br>                  
                  <p class="font-bold">Since 1988.</p>
                </p>     

                <div class="flex w-100 h-20 mt-2 justify-center">
                  <div class="">
                      {/* create a contact and sign in button */}

                      <div class="flex justify-center w-100 h-12">
                        <button style={{width:"130px"}} className=" bg-white outline-double rounded px-3 w-200 hover:!bg-gray-200 ">
                          <a href="/info" className="no-underline">
                            <span className="main_btn text-black">Get Quote</span>
                          </a>
                        </button>

                        <div class="w-6"></div>

                        <button style={{width:"130px"}} className=" outline-double bg-blue-600 rounded px-3 hover:bg-blue-700 w-200">
                          <a href="/login" className="no-underline">
                            <span className="main_btn text-white">Sign In</span>
                          </a>
                        </button>
                      </div>

                  </div>
                </div>                    


            </div>

            <div class="absolute w-100 justify-center top-30">

              {/* <div class="w-100 text-center">
                <p id="home_text" class="font-bold top-100 ">
     2            Custom solutions for transportation of goods, equipment and personnel.
                  <br></br>
                  Warehousing. Forwarding. Analytics. Lowest prices in the industry.
                </p>
              </div> */}

              {/* <div class="w-100 flex h-12 mx-auto justify-center">
                <button className="bg-blue-800 rounded px-3 hover:bg-blue-900 ">
                  <a href="/info" className="no-underline">
                    <span className="text-white">Get a quote</span>
                  </a>
                </button> 
              </div> */}

            </div>

            <div class="absolute flex bottom-0 bg-transparent h-6 w-100 p-1">
              <p class="flex ml-auto text-white text-sm mr-1">Locks & Docks Forwarding Inc. {new Date().getFullYear()}</p>
          </div> 

          </div>
        </div>

  
  );
}














