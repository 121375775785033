import React, { useRef, useEffect, useState } from 'react';

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Checkbox from '@mui/material/Checkbox';

import MenuChecklist from './menuChecklist';

import Loading from './loading';

import useAuth from '../hooks/useAuth';

import { FiX } from 'react-icons/fi';
import { BsX } from 'react-icons/bs';

import { BsTrash } from 'react-icons/bs';

import Popup from '../context/layoutPopup.jsx';


function arrayAlphebetize(array) {
    return array.sort((a, b) => a.localeCompare(b));
}

function removeDuplicatesFromArray(array) {
    return array.filter((a, b) => array.indexOf(a) === b);
} 

function createMap(arr) {
    let map = {};
    for (let i=0; i<arr.length; ++i) {
        map[arr[i]] = false;
    }
    return map;
}

function checkEmail(str) {
    if (str.length>0) {    
        let val = str;
        if(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val)) {
            return true
        } else {
            return false
        }
    } else {
        return true;
    }
}

export default function MenuEditJobs({ data, divisionMap, onToggleDisplay, updateParent, notify }) {

    const auth = useAuth();

    const axiosPrivate = useAxiosPrivate();

    const client_list = arrayAlphebetize(Object.keys(divisionMap));

    const [isLoading, setIsLoading] = useState(false);
    
    const [newEditData, setNewEditData] = useState(JSON.parse(JSON.stringify(data)));
    const [editOrders, setEditOrders] = useState(JSON.parse(JSON.stringify(data.orders)));
    const [isSelected, setIsSelected] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [displayMenu, setDisplayMenu] = useState(0);
    const [newOrders, setNewOrders] = useState([]);
    const [newScannedOrders, setNewScannedOrders] = useState([]);
    const [confirmCode, setConfirmCode] = useState("");
    const [displayConfirmationPopup, setDisplayConfirmationPopup] = useState(false);
    const [retreatMenu, setRetreatMenu] = useState(0);
    const [changesMade, setChangesMade] = useState(false);
    const [deleteOrderID, setDeleteOrderID] = useState("");
    const [clientList, setClientList] = useState(client_list);
    const [destinationList, setDestinationList] = useState(removeDuplicatesFromArray(arrayAlphebetize(client_list.flatMap((division) => division.split(';')).slice(1))));
    const [deleteOrdersList, setDeleteOrdersList] = useState([]);
    const [addedOrdersList, setAddedOrdersList] = useState([]);
    const [personnelList, setPersonnelList] = useState([]);
    const [itemList, setItemList] = useState([]);

    // For the outgoing orders email selection
    const [displayMenuEmails, setDisplayMenuEmails] = useState(0);
    const [emailObject, setEmailObject] = useState(createMap(auth.auth.emails[data.client].split(";")));
    const [newEmailsObject, setNewEmailsObject] = useState(JSON.parse(JSON.stringify(emailObject)));
    const [newEmailsBlob, setNewEmailsBlob] = useState(JSON.parse(JSON.stringify(auth.auth.emails[data.client])));

    useEffect(() => {
        console.log(data);

        // if newEditData.emailsList doesn't exist, create it
        setNewEditData({...newEditData, emailsList: []});
        
    }, []);   

    const onSubmitEditJobInfo = async () => {

        try {            

            console.log("SUBMITTING NEW EDIT DATA")
            console.log(newEditData);
            
            setIsLoading(true);
                      
            const config = { headers: { 'Content-Type': 'application/json' } };
            const editData = {...newEditData, orders: editOrders};
            const statusChanged = editData.status!=data.status?true:false;     
            
            //console.log("email map =====================????")
            //console.log("---------------------------------------------")
            //console.log(newEmailsObject)
            //console.log("---------------------------------------------")
            //console.log(auth.auth.emailMap[data.client])

            let emailBlob = JSON.parse(auth.auth.emailMap[data.client].replace(/""/g, '"').replace(/"{/, '{').replace(/}"$/, '}')) ///

            //console.log("email blob --------------")
            //console.log(emailBlob) 

            let emailList = emailBlob[data.destination]

            //console.log("EMAIL LIST =====================????")
            //console.log(emailList)

            // if the job type is outgoing => default to using the emailList not the client list
            if (data.type == 4) {
                emailList = JSON.stringify(newEditData.emailsList)
            }
            else {
                emailList = JSON.stringify(emailList)
            }                
            
            //console.log("SUBMIT EMAIL LIST ------????")
            //console.log(emailList)

            // ----------------------            
            
            const body = JSON.stringify({...editData, statusChanged: statusChanged, emailsList: emailList})            

            const fileData = new FormData()            
            fileData.append('name', newEditData.id);
            fileData.append('data', body)
            fileData.append('new_file_name','new_name_here.pdf')       
            fileData.append('file', selectedFile)
            
            const response = await axiosPrivate.post(`/jobs/updateInfo`, fileData, {headers: { 'Content-Type': 'multipart/form-data' }});
            console.log(response);
            updateParent();
            setNewEditData({});
            onToggleDisplay(false);
            setChangesMade(false);
            setIsLoading(false);
            notify("Job updated successfully", "success");
            

        } catch (error) {
            console.log(error);
            setIsLoading(false);
            onToggleDisplay(false);
            setChangesMade(false);
            notify("Error updating job", "error");
        }
        
    }

    const onSubmitEditJobOrders = async () => {

        try {
            setIsLoading(true);
            const config = { headers: { 'Content-Type': 'application/json' } };
            const editData = {...newEditData, orders: editOrders};
            const deleteOrderIDs = [...deleteOrdersList];
            const addedOrderIDs = [...addedOrdersList];
            const statusChanged = editData.status!=data.status?true:false;
            const body = JSON.stringify({...editData, deleteOrderIDs: deleteOrderIDs, addedOrderIDs: addedOrderIDs, statusChanged: statusChanged, });

            console.log(body);
            const response = await axiosPrivate.post(`/jobs/updateOrders`, body, { method: "POST", credentials: "include" });
            console.log(response);
            setDeleteOrdersList([]);
            setAddedOrdersList([]);
            setChangesMade(false);
            updateParent();
            setNewScannedOrders([]);
            setIsLoading(false);
            notify("Job orders saved successfully", "success");
        } catch (error) {
            console.log(error);
            setChangesMade(false);
            setIsLoading(false);
            notify("Error saving job orders", "error");
        }
    }

    const onSubmitEditJobPersonnel = async () => {
        try {
            setIsLoading(true);
            const config = { headers: { 'Content-Type': 'application/json' } };
            const editData = {...newEditData};
            const deleteOrderIDs = [];
            const addedOrderIDs = [];        
            const body = JSON.stringify({...editData, deleteOrderIDs: deleteOrderIDs, addedOrderIDs: addedOrderIDs, statusChanged: false});
            console.log("(*(*(*(*(*(*(*(*",body)
            const response = await axiosPrivate.post(`/jobs/updateOrders`, body, { method: "POST", credentials: "include" });
            console.log(response);
            setChangesMade(false);
            updateParent();
            setIsLoading(false);
            notify("Job orders saved successfully", "success");
        } catch (error) {
            console.log(error);
            setChangesMade(false);
            setIsLoading(false);
            notify("Error saving job orders", "error");
        }
    }

    const onSubmitEditJobPickup = async () => {
        try {
            setIsLoading(true);
            const config = { headers: { 'Content-Type': 'application/json' } };
            const editData = {...newEditData};
            const deleteOrderIDs = [];
            const addedOrderIDs = [];        
            const body = JSON.stringify({...editData, deleteOrderIDs: deleteOrderIDs, addedOrderIDs: addedOrderIDs, statusChanged: false});
            console.log("(*(*(*(*(*(*(*(*",body)
            const response = await axiosPrivate.post(`/jobs/updateOrders`, body, { method: "POST", credentials: "include" });
            console.log(response);
            setChangesMade(false);
            updateParent();
            setIsLoading(false);
            notify("Job orders saved successfully", "success");
        } catch (error) {
            console.log(error);
            setChangesMade(false);
            setIsLoading(false);
            notify("Error saving job orders", "error");
        }
    }

    const submitDeleteJob = async () => {
        try {
            setIsLoading(true);
            console.log("ATTEMPTING DELETE TO JOB...", data.type)
            const config = { headers: { 'Content-Type': 'application/json' } };
            const body = JSON.stringify(data)

            let delete_endpoint = `/jobs/delete`;
            if (data.type==1) delete_endpoint = `/jobs/delete`;
            if (data.type==2) delete_endpoint = `/jobs/delete/personnel`;
            if (data.type==3) delete_endpoint = `/jobs/delete/pickup`;
            if (data.type==4) delete_endpoint = `/jobs/delete/outgoing`;

            console.log("DELETE ENDPOINT =>", delete_endpoint)

            const response = await axiosPrivate.post(delete_endpoint, body, { method: "POST", credentials: "include" });
            console.log(response.data);
            setDisplayConfirmationPopup(false);
            await updateParent();
            setIsLoading(false);
            onToggleDisplay(false);
            notify("Job deleted successfully", "success");
            return response;
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            onToggleDisplay(false);
            notify("Error deleting job", "error");
        }
    }

    const startDeleteJob = () => {
        setDisplayConfirmationPopup(true);
        setConfirmCode(0)
    }    

    const fetchNewOrders = async () => {
        setIsLoading(true);
        const config = { headers: { 'Content-Type': 'application/json' } };
        const params = { client: data.client, destination: data.destination };
        const response = await axiosPrivate.get(`/orders/destination/${data.destination.replace("/","%2F")}`, {params: params}, { method: "GET", credentials: "include" });
        console.log(response.data);
        if (response.data.length == 0) {
            setNewScannedOrders(["No orders were found."])
        } else {
            setNewScannedOrders(response.data);
        }
        
        setIsLoading(false);
    }

    const fetchNewOutgoingOrders = async () => {
        setIsLoading(true);
        const config = { headers: { 'Content-Type': 'application/json' } };
        const params = { client: data.client, destination: data.destination };

        const response = await axiosPrivate.get(`/outgoing/client`, {params: params}, { method: "GET", credentials: "include" });

        console.log(response.data);
        if (response.data.length == 0) {
            setNewScannedOrders(["No orders were found."])
        } else {
            setNewScannedOrders(response.data);
        }
        
        setIsLoading(false);
    }

    const selectOrder = (id) => {
        console.log(data.orders);
        const newOrders = {...editOrders};
        newOrders[id].checked = !newOrders[id].checked;
        setEditOrders(newOrders);
        if(!changesMade) {
            setChangesMade(true);
            notify("Don't forget to save", "info")
        }
    }

    const startDeleteOrder = (id) => {
        setDeleteOrderID(id);
        setDisplayConfirmationPopup(true);        
        setConfirmCode(1)        
    }

    const deleteOrder = () => {
        console.log(data.orders);
        console.log(deleteOrderID)
        const newOrders  = {...editOrders};
        delete newOrders[deleteOrderID];     
        console.log(newOrders);   
        setEditOrders(newOrders);
        setDeleteOrdersList([...deleteOrdersList, deleteOrderID]);
        setDeleteOrderID("");
        setDisplayConfirmationPopup(false);
        setConfirmCode("");
        if(!changesMade) {
            setChangesMade(true);
            notify("Don't forget to save", "info")
        }
    }

    const continueWithoutSaving = () => {
        setDisplayConfirmationPopup(true);
        setConfirmCode(2);
    }

    const abortJobOrderChanges = () => {
        console.log(data.orders);
        setDisplayConfirmationPopup(false);
        setConfirmCode("");
        setDisplayMenu(0);
        setChangesMade(false);
        setEditOrders(JSON.parse(JSON.stringify(data.orders)));
    }

    const selectScannedOrder = (order) => {
        const updateScannedOrders = [...newScannedOrders];
        for(let i=0; i<updateScannedOrders.length; ++i) {
            if(updateScannedOrders[i].id==order.id) {
                updateScannedOrders[i].selected = !updateScannedOrders[i].selected;
                const changeEditOrders = {...editOrders};
                if(updateScannedOrders[i].selected) {
                    changeEditOrders[updateScannedOrders[i].id] = updateScannedOrders[i];
                    setAddedOrdersList([...addedOrdersList, updateScannedOrders[i].id]);
                } else {
                    delete changeEditOrders[updateScannedOrders[i].id];
                }
                setNewScannedOrders(updateScannedOrders);
                setEditOrders(changeEditOrders);
            }
        };
        //console.log(editOrders);                       
    }

    const addNewPersonnel = () => {
        let newPersonnelData = newEditData.orders;
        newPersonnelData.push({name:"",description:""});
        setNewEditData({...newEditData, orders: newPersonnelData})
    }
    
    const removePersonnel = (index) => {
        let newPersonnelData = JSON.parse(JSON.stringify(newEditData.orders));
        newPersonnelData = newPersonnelData.filter((item, i) => i !== index)
        setNewEditData({...newEditData, orders: newPersonnelData})
    }
    
    const onChangePersonnel = (index, e) => {
        let newPersonnelList = newEditData.orders.map((item, i) => {
          if (i === index) {
            if (e.target.value.includes('"') || e.target.value.includes('`')) {}
            else {
                let newItem = item;
                newItem[e.target.name] = e.target.value
                return newItem
            }
          } else {
            return item
          }
        })
        setNewEditData({...newEditData, orders: newPersonnelList})
    }

    const addNewItem = () => {
        let newItemData = newEditData.orders;
        newItemData.push({quantity:"",weight:1,weightType:"LBS",unit:"",to:"",reference:"",description:""});
        setNewEditData({...newEditData, orders: newItemData})
    }
    
    const removeItem = (index) => {
        let newItemData = JSON.parse(JSON.stringify(newEditData.orders));
        const newItemList = itemList.filter((item, i) => i !== index)
        setNewEditData({...newEditData, orders: newItemList})
    }
    
    const onChangeItem = (index, e) => {
        let newItemList = newEditData.orders.map((item, i) => {
          if (i === index) {
            if (e.target.value.includes('"') || e.target.value.includes('`')) {}
            else {
              let newItem = item;
              newItem[e.target.name] = e.target.value
              return newItem
            }
          } else {
            return item
          }
        })
        setNewEditData({...newEditData, orders: newItemList})
    }

    const uploadFile = (e) => {
        setSelectedFile(e.target.files[0]);
        setIsSelected(true);
    }

    const onChange = (e) => {
        if (e.target.value.includes('"') || e.target.value.includes('`')) {}
        else {
            setNewEditData({...newEditData, [e.target.name]: e.target.value});
        }
    }

    const onSelectItem = (item) => {     
        //console.log("EMAIL OBJECT", newEmailsObject)   
        let newMap = JSON.parse(JSON.stringify(newEmailsObject));
        newMap[item] = !newMap[item];
        setNewEmailsObject(newMap);
        console.log("NEW EMAIL OBJECT", newMap);
        setChangesMade(true);
    }

    const onConfirmSelectedEmails = () => {   
       let emailsList = Object.keys(newEmailsObject).filter(e => newEmailsObject[e]);
       setNewEditData({...newEditData, emailsList: emailsList});
       setDisplayMenu(0);
       setChangesMade(false);
    }

    const editEmailBlob = (e) => {
        if (!changesMade) { setChangesMade(true); }
        if (e.target.value.includes('"') || e.target.value.includes('`')) {}
        else {
            setNewEmailsBlob(e.target.value);
        }
    }

    const saveEmailBlob = () => {
        console.log("EMAIL BLOB", newEmailsBlob);
        let newList = newEmailsBlob.split(";").map(e => e.trim());   
        console.log("NEW LIST", newList);                
        for (let i=0; i<newList.length; ++i) {                  
            if (!checkEmail(newList[i])) {
                notify("Ignoring Invalid Emails", "error");
                newList.splice(i, 1);
            }                     
        };
        setNewEmailsObject(createMap(newList));
        setDisplayMenuEmails(0);
        
    }

    const cancelEmailMapping = () => {

        let keys = Object.keys(newEmailsObject)

        //console.log("newEditData.emailsList ======> ", newEditData.emailsList)
        //console.log("newEmailsObject keys =======> ", keys)
        
        if (newEditData.emailsList) {
            let newMap = createMap(newEmailsObject);
            for (let i=0; i<keys.length; ++i) {
                if (newEditData.emailsList.includes(keys[i])) {
                    //console.log(keys[i], "is in newEditData.emailsList")
                    newMap[keys[i]] = true;
                } else {
                    //console.log(keys[i], "is not in newEditData.emailsList")
                    newMap[keys[i]] = false;
                }
            }
            setNewEmailsObject(newMap);
            setDisplayMenu(0)
        } else {
            let newMap = createMap(newEmailsObject);
            for (let i=0; i<keys.length; ++i) {
                newMap[keys[i]] = false;
            }
        
            setDisplayMenu(0);        
        }
    }

    const startUpdateJob = () => {

        if((newEditData?.emailsList?.length==0 || !newEditData.emailsList) && newEditData.status=="Completed" && data.type == 4) {
            setDisplayConfirmationPopup(true);
            setConfirmCode(3);
        }
        else {
            onSubmitEditJobInfo();
        }
    }

        
    return (
        <Popup>

            <div class="flex absolute top-5 right-5">
                <button onClick={() => onToggleDisplay(false)} class="h-10 w-10 bg-gray-200 hover:bg-gray-300 rounded-full flex m-2">
                    <BsX class="m-auto" size={25}/>
                </button>
            </div>

            {displayConfirmationPopup? // create a centered popup to confirm deletion
                <div id="popupMenu" class="absolute z-20 mx-auto my-auto inset-0 overflow-hidden h-1/4 p-2 w-1/2  bg-gray-500 text-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
                    <div class="bg-gray-500 text-white sm:p-6 h-full w-100 flex">

                        {confirmCode==0
                        ? // Confirm Delete Job
                        <>
                            <div class="flex">
                                <div class="ml-6">
                                    <h3 class="">Delete Job #{data.id}</h3>
                                    <p class="text-white">Are you sure you want to delete this job?</p>
                                </div>
                            </div>
                            <div class="flex ml-auto mt-auto">
                                <button onClick={isLoading?null:() => setDisplayConfirmationPopup(false)} class="bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded mr-2">Cancel</button>                                      
                                <div class="">
                                    {!isLoading?
                                    <div onClick={submitDeleteJob} class="w-28 h-10 flex bg-red-400 hover:bg-red-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                                        <p class="flex m-auto">Confirm</p>
                                    </div> 
                                    :   
                                    <div class="w-28 h-10 flex bg-red-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span>
                                    </div> 
                                    }
                                </div> 

                            </div>
                        </>
                        :confirmCode==1
                        ? // Confirm Delete Order
                        <>
                            <div class="flex">
                                <div class="ml-6">
                                    <h3 class="">Remove Order #{deleteOrderID}</h3>
                                    <p class="text-white">Are you sure you want to remove this order from the job?</p>
                                </div>
                            </div>
                            <div class="flex ml-auto mt-auto">
                                <button onClick={isLoading?null:() => setDisplayConfirmationPopup(false)} class="bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded mr-2">Cancel</button>
                                <div class="">
                                    {!isLoading?
                                    <div onClick={deleteOrder} class="w-28 h-10 flex bg-red-400 hover:bg-red-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                                        <p class="flex m-auto">Confirm</p>
                                    </div> 
                                    :   
                                    <div class="w-28 h-10 flex bg-red-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span>
                                    </div> 
                                    }
                                </div> 
                            </div>
                        </>
                        :confirmCode==2
                        ? // Leave Without Saving?
                        <>
                            <div class="flex">
                                <div class="ml-6">
                                    <h3 class="">Leave without saving?</h3>
                                    <p class="text-white">Any changes to job orders will not be saved if you leave now.</p>
                                </div>
                            </div>
                            <div class="flex ml-auto mt-auto">
                                <button onClick={isLoading?null:() => setDisplayConfirmationPopup(false)} class="bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded mr-2">Cancel</button>
                                <div class="">
                                    {!isLoading?
                                    <div onClick={abortJobOrderChanges} class="w-28 h-10 flex bg-red-400 hover:bg-red-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                                        <p class="flex m-auto">Confirm</p>
                                    </div> 
                                    :   
                                    <div class="w-28 h-10 flex bg-blue-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span>
                                    </div> 
                                    }
                                </div> 
                            </div>
                        </>
                        :confirmCode==3
                        ? // Confirm Without Emails Selected
                        <>
                            <div class="flex">
                                <div class="ml-6">
                                    <h3 class="">No emails have been selected.</h3>
                                    <p class="text-white">
                                        Are you sure you want to complete this job without selecting any emails?
                                        If you confirm, no emails will be sent to notify the client of the job's completion.
                                    </p>
                                </div>
                            </div>
                            <div class="flex ml-auto mt-auto">
                                <button onClick={isLoading?null:() => setDisplayConfirmationPopup(false)} class="bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded mr-2">Cancel</button>
                                <div class="">
                                    {!isLoading?
                                    <div onClick={onSubmitEditJobInfo} class="w-28 h-10 flex bg-red-400 hover:bg-red-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                                        <p class="flex m-auto">Confirm</p>
                                    </div> 
                                    :   
                                    <div class="w-28 h-10 flex bg-blue-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span>
                                    </div> 
                                    }
                                </div> 
                            </div>
                        </>
                        :null}

                    </div>
                </div>                                                    
            :null}

            <div id="innerEditMenu" class="h-3/4 pb-0 w-3/4 inline-block bg-gray-200 rounded-lg text-left overflow-scroll shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
                {displayMenu==0
                ? // Edit Job Menu                                        
                <div class="bg-gray-200 pb-4 sm:p-6 h-full">
                    <div class="flex">
                        <div class="ml-6">
                            <h3 >Edit Job #{data.id}</h3>
                            {data.type=="1"
                            ?<span onClick={() => setDisplayMenu(1)} class="font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">Edit job orders</span>
                            :data.type=="2"
                            ?<span onClick={() => setDisplayMenu(3)} class="font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">Edit job personnel</span>
                            :data.type=="3"
                            ?<span onClick={() => setDisplayMenu(4)} class="font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">Edit job pickup</span>
                            :data.type=="4"                            
                            ?<div class="flex space-x-4">
                                <span onClick={() => setDisplayMenu(1)} class="font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">Edit job outgoing orders</span>
                                <span onClick={() => setDisplayMenu(5)} class="font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">Map Emails</span>
                            </div>
                            :<span onClick={() => setDisplayMenu(4)} class="font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">Edit job orders</span>
                            }
                        </div>
                        {/* <button onClick={() => onToggleDisplay(false)} class="flex ml-auto hover:bg-gray-200"><FiX/></button> */}
                    </div>                    

                    <div class="flex flex-wrap -mx-3 px-6 py-4">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                            Client *
                        </label>
                        <select name="client" onChange={(e) => setNewEditData({...data, client: e.target.value})} class="shadow block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="client" type="text">
                            {divisionMap?
                            Object.keys(divisionMap).map(d => {
                                if(d!=='admin') {
                                    if(data.client===d) {
                                        return <option selected value={d}>{d}</option> 
                                    }
                                    return <option value={d}>{d}</option> 
                                }
                            })
                        :
                        <option value="default">default</option>
                        }
                        </select>
                        {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                        </div>

                        <div class="w-full md:w-1/2 px-3">                            
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                Destination *
                            </label>
                            {data.type==1
                            ? // Edit Job Menu - Type 1
                            <select name="destination" onChange={(e) => setNewEditData({...data, destination: e.target.value})} class="shadow block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="destination" type="text" >
                                {divisionMap?
                                divisionMap[data.client].split(";").map(d => {
                                    if(data.destination===d) {
                                        return <option selected value={d}>{d}</option>
                                    }
                                    return <option value={d}>{d}</option>
                                })
                            :
                            <option value="default">default</option>
                            }
                            </select>
                            : // Edit Job Menu - Type 2
                            <input value={newEditData.destination} placeholder="Enter a custom destination..." onChange={(e) => onChange(e)} name="destination" autoComplete="off" class=" shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="destination" type="text" />
                            }

                        </div>
                    </div>

                    <div class="flex flex-wrap -mx-3 mb-2 px-6">
                        <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                            Service
                        </label>
                        <input value={newEditData.service} name="service" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="supplier" type="text" placeholder="" />
                        </div>

                        <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                            Location
                        </label>
                        <input value={newEditData.location} name="location" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reference" type="text" placeholder="" />
                        </div>                        
                    </div>

                    {data.type=="3"?
                        <div class="flex flex-wrap -mx-3 mb-2 px-6">
                            <div class="w-full md:w-1/2 px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                Picked Up From 
                            </label>
                            <input value={newEditData.pickedUp} name="pickedUp" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="supplier" type="text" placeholder="" />
                            </div>

                            <div class="w-full md:w-1/2 px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                Picked Up Location
                            </label>
                            <input value={newEditData.pickedUpLocation} name="pickedUpLocation" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reference" type="text" placeholder="" />
                            </div>                        
                        </div>
                    :null}

                    <div class="flex flex-wrap -mx-3 mb-2 px-6">
                        <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                            Client Reference
                        </label>
                        <input value={newEditData.clientRef} name="clientRef" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="supplier" type="text" placeholder="" />
                        </div>

                        <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                            Employee
                        </label>
                        <input value={newEditData.employee} name="employee" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reference" type="text" placeholder="" />
                        </div>                        
                    </div>

                    <div class="flex flex-wrap -mx-3 mb-2 px-6">
                        {/* <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="carrier">
                            Carrier
                        </label>
                        <input autoComplete="off" defaultValue={data.carrier} name="carrier" onChange={(e) => setNewEditData({...data, carrier: e.target.value})} class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="carrier" type="text" placeholder="" />
                        </div> */}

                        <div class="w-full md:w-100 px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="description">
                            Description
                        </label>
                        <input value={newEditData.description} name="description" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="description" type="text" placeholder="" />
                        </div>
                    </div>                 

                    <div class="w-full h-32 mr-3 px-7">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="notes">
                            Notes
                        </label>
                        <textarea value={newEditData.notes} name="notes" onChange={(e) => onchange(e)} autoComplete="off" placeholder="Additional notes..." class="h-full shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="note" type="text" />
                    </div> 

                    <div class="flex flex-wrap -mx-3 mb-2 px-6 mt-5">

                        <div class="w-full md:w-1/3 px-3">
                            <label for="status" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Status
                            </label>
                            <select 
                                disabled={data.status=="Cancelled"?true:false} 
                                name="status" 
                                onChange={(e) => setNewEditData({...newEditData, status: e.target.value})} 
                                class="shadow block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" 
                                id="status" 
                                style={data.status=="Cancelled"?{backgroundColor:"silver",color:"grey"}:{color:"black"}}
                            >
                                <option selected={data.status=="Completed"?true:false} value="Completed">Completed</option>
                                <option selected={data.status=="Pending"?true:false} value="Pending">Pending</option>
                                <option selected={data.status=="Delayed"?true:false} value="Delayed">Delayed</option>
                                <option selected={data.status=="Cancelled"?true:false} value="Cancelled">Cancelled</option>
                            </select>
                        </div>

                        <div class="w-full md:w-1/3 px-3 mt-4">
                            <form action="#" className="upload_button_div">
                                <label class="font-bold">Upload Proof of Delivery</label>
                                <input /*ref={this.resetFile.ref0}*/ style={isSelected?{color:"green",fontWeight:"bold"}:{color:"red",fontWeight:"bold"}} type="file" name="file" onChange={uploadFile} />
                                {/* <button className="upload_button" onClick={(e) => this.handleSubmission(e)}>Upload</button> */}
                            </form> 
                            
                        </div>

                        <div class="w-full md:w-1/3 px-3 mt-4">
                            {data.type == 4?
                                newEditData?.emailsList?.length ?
                                    <p class="text-green-500 font-bold text-lg">[ {newEditData.emailsList.length} Emails Selected ]</p>
                                    :<p class="text-black font-bold text-lg">[ 0 Emails Selected ]</p>                                
                            :null}
                        </div>

                        <div class="flex w-full md:w-100 px-3 mt-4 pb-4">
                            <div onClick={startDeleteJob} class="w-36 h-10 flex bg-red-400 hover:bg-red-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                                <p class="flex m-auto">Delete</p>
                            </div>                               
                            {!isLoading?
                            <div onClick={displayConfirmationPopup?null:startUpdateJob} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                                <p class="flex m-auto">Update</p>
                            </div> 
                            :   
                            <div class="w-36 h-10 flex bg-blue-700 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                <span class="flex m-auto"><Loading type={1} /></span>
                            </div> 
                            }
                        </div> 
                        
                    </div>  
                </div>                        
                :displayMenu==1
                ? // Select Orders
                <div class="bg-gray-200 pb-4 sm:p-6 h-full">
                    <div class="flex w-100">
                        <span onClick={changesMade?continueWithoutSaving:() => setDisplayMenu(0)} class="text-lg font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">← Back</span>                        
                        {/* <button onClick={() => onToggleDisplay(false)} class="flex ml-auto hover:bg-gray-200"><FiX/></button> */}
                    </div>
                    <div class="flex pb-1 pt-3">
                        <div class="text-center flex">
                            <h5 class="ml-6">Select Orders</h5>                              
                        </div>
                        <span onClick={() => setDisplayMenu(2)} class="ml-auto text-lg font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">Add New Orders</span>
                    </div>  

                    <div class="flex flex-wrap -mx-3 px-6 py-4 h-3/4 max-h-3/4">
                        <div class="shadow w-100 px-3 bg-gray-100 h-100 border-black overflow-scroll py-2">
                            {Object.keys(editOrders).map((id, j) => {                                
                                return (
                                    <div className="flex w-100">
                                        <Checkbox onClick={() => selectOrder(id)} checked={editOrders[id].checked?true:false}/>
                                        <button onClick={() => startDeleteOrder(id)} class="p-1 px-2 my-1 rounded bg-red-400 hover:bg-red-500 text-white" ><BsTrash></BsTrash></button>
                                        <div class="flex ml-2 text-md align-middle my-auto">
                                            <span class="font-bold flex text-blue-500">{id}</span> 
                                            <p class="ml-2 mb-0">
                                                {editOrders[id].reference} - {editOrders[id].quantity} {editOrders[id].unit} {editOrders[id].supplier} - {editOrders[id].carrier}  {editOrders[id].waybill}
                                            </p>
                                        </div>
                                    </div> 
                                )    
                            })
                        } 
                        </div>
                    </div>

                    {/* <div class="flex w-full md:w-100 px-3 mt-4 pb-4">
                        {changesMade
                        ?<p class="absolute top- flex text-red-500 font-bold">* To undo changes: simply leave without saving and come back.</p>
                        :<p class="absolute top- flex text-red-500 font-bold"></p>}
                    </div> */}

                    
                    <div class="flex w-full md:w-100 px-3 mt-4 pb-4">                                
                        {!isLoading?
                        <div onClick={onSubmitEditJobOrders} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                            <p class="flex m-auto">Save</p>
                        </div> 
                        :   
                        <div class="w-36 h-10 flex bg-blue-700 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                            <span class="flex m-auto"><Loading type={1} /></span>
                        </div> 
                        }
                    </div> 

                    

                </div>                        
                :displayMenu==2
                ? // Add New Orders
                <div class="bg-gray-200 pb-4 sm:p-6 h-full">

                    <div class="flex w-100">
                        <span onClick={changesMade?continueWithoutSaving:() => setDisplayMenu(1)} class="text-lg font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">← Back</span>                        
                        {/* <button onClick={() => onToggleDisplay(false)} class="flex ml-auto hover:bg-gray-200"><FiX/></button> */}
                    </div>

                    <div class="flex pb-1 pt-3">
                        <div class="text-center flex">
                            <h5 class="ml-6">Add New Orders to Job</h5>                              
                        </div>
                        {/* <span onClick={() => setDisplayMenu(2)} class="ml-auto text-lg font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">Add New Orders</span> */}
                    </div> 

                    <div class="flex flex-wrap -mx-3 px-6 py-4 h-3/4 max-h-3/4">
                        <div class="shadow w-100 px-3 bg-gray-100 h-100 border-black overflow-scroll py-2">                                    
                            {isLoading?
                                <section className="flex items-center h-full p-16 relative w-100">
                                    <div className="container w-100 flex flex-col items-center justify-center px-5 mx-auto my-20">
                                        <div className="max-w-md text-center">
                                            <h2 className="mb-8 font-extrabold text-5xl dark:text-gray-600">
                                                <span className="sr-only"></span>LOADING
                                            </h2>
                                            <p className="text-2xl font-semibold md:text-3xl">Searching for new orders...</p>
                                            {/* <p className="mt-4 mb-8 dark:text-gray-400">But dont worry, you can find plenty of other things on our homepage.</p> */}
                                            <div className="max-w-md text-center h-4 w-100 pt-4 px-12">
                                            <Loading type={3} class="w-100 h-32"/>
                                            </div>
                                        </div>
                                    </div>
                                </section>                              
                            :
                            newScannedOrders.length>0?
                                typeof newScannedOrders[0] == 'string'
                                ? 
                                <div class="flex w-100 h-100">
                                    <p class="flex m-auto text-red-500 font-bold">{newScannedOrders[0]}</p>
                                </div>
                                :
                                newScannedOrders.map((order, j) => {
                                    return (
                                        <div className="flex w-100">
                                            <Checkbox onClick={() => selectScannedOrder(order)} checked={order.selected?true:false}/>
                                            {/* <button onClick={null} class="p-1 px-2 my-1 rounded bg-red-400 hover:bg-red-500 text-white" ><BsTrash></BsTrash></button> */}
                                            <div class="flex ml-2 text-md align-middle my-auto">
                                                <span class="font-bold flex text-blue-500">{order.id}</span> 
                                                <p class="ml-2 mb-0">
                                                    {data.type==1?
                                                        `${order.reference} - ${order.quantity} ${order.unit} ${order.supplier} - ${order.carrier}  #${order.waybill}`
                                                    :data.type==4?
                                                        `FROM: ${order.from}, TO: ${order.to}; ${order.reference} - ${order.quantity} ${order.unit}, ${order.weight} ${order.weightType} `
                                                    :null}
                                                </p>
                                            </div>
                                        </div>
                                    )    
                                }) 
                            :<p class="h-100 font-bold">Scan for new orders for this destination and add them to the job.</p>
                            }
                        </div>
                    </div> 

                    <div class="flex w-full md:w-100 px-3 mt-4">
                        <div class="w-full md:w-1/2 px-3">
                           {!isLoading?
                            <div onClick={data.type==1?fetchNewOrders:data.type==4?fetchNewOutgoingOrders:null} class="w-36 h-10 flex bg-gray-500 hover:bg-gray-600 text-white font-bold mr-6 rounded mx-auto" type="button">
                                <p class="flex m-auto">Scan</p>
                            </div> 
                            :   
                            <div class="w-36 h-10 flex bg-gray-600 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                <span class="flex m-auto"><Loading type={1} /></span>
                            </div> 
                            }                               
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                            {!isLoading?
                            <div onClick={onSubmitEditJobOrders} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                                <p class="flex m-auto">Add Selected</p>
                            </div> 
                            :   
                            <div class="w-36 h-10 flex bg-gray-600 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                <span class="flex m-auto"><Loading type={1} /></span>
                            </div> 
                            }                                                                    
                        </div>
                    </div> 
                    

                </div> 
                :displayMenu==3
                ? // Edit Personnel
                <div class="bg-gray-200 pb-4 sm:p-6 h-full">
                    <div class="h-100 w-100">
                        <div class="w-100 h-18 pb-2 mx-4">
                            <button class="text-xl font-bold text-blue-600" onClick={() => setDisplayMenu(0)}>← Back</button>
                            <div class="flex w-100 mt-2">
                                <h3 class="text-black">
                                    Add Personnel List
                                </h3>
                                <button onClick={addNewPersonnel} class="ml-auto mr-8 flex bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded " type="button">Add New</button>
                            </div> 
                            
                        </div>

                        <div class="flex flex-wrap -mx-3 px-6 py-4 h-3/4 max-h-3/4">
                            <div class="shadow w-100 px-3 bg-gray-100 h-100 border-black overflow-scroll py-2"> 
                                {newEditData.orders.map((personnel, index) => {
                                return (
                                    <div class="w-100 flex h-10 my-2">
                                    <p class="w-6 mx-1 h-100 p-2 bg-white rounded">{index+1}</p>
                                    <div class="w-100 mx-auto">
                                        <input onChange={(e) => onChangePersonnel(index,e)} name="name" placeholder="Personnel name" class="w-2/12 p-2 mb-2 bg-white rounded-md border border-black" value={personnel.name}></input>
                                        <input onChange={(e) => onChangePersonnel(index,e)} name="description" placeholder="Additional details" class="w-9/12 p-2 mb-2 ml-1 bg-white rounded-md border border-black" value={personnel.description}></input>
                                    </div>
                                    <button onClick={() => {removePersonnel(index)}} class="p-2 my-1 hover:bg-red-500 bg-red-400 rounded mx-1">
                                        <BsX color="white"/>
                                    </button>
                                    </div>     
                                    )}
                                )}
                            </div>                        
                        </div>

                        <div class="w-full px-3">
                            {!isLoading?
                            <div onClick={onSubmitEditJobPersonnel} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                                <p class="flex m-auto">Save</p>
                            </div> 
                            :   
                            <div class="w-36 h-10 flex bg-gray-600 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                <span class="flex m-auto"><Loading type={1} /></span>
                            </div> 
                            }                                                                    
                        </div>
                    </div>                 
                
                </div>
                :displayMenu==4
                ? // Edit Pickup
                <div class="bg-gray-200 pb-4 sm:p-6 h-full">
                    <div class="h-100 w-100">
                        <div class="w-100 h-18 pb-2 mx-4">
                            <button class="text-xl font-bold text-blue-600" onClick={() => setDisplayMenu(0)}>← Back</button>
                            <div class="flex w-100 mt-2">
                                <h3 class="text-black">
                                    Add Orders List
                                </h3>
                                <button onClick={addNewItem} class="ml-auto mr-8 flex bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded " type="button">Add New</button>
                            </div> 
                            
                        </div>

                        <div class="flex flex-wrap -mx-3 px-6 py-4 h-3/4 max-h-3/4"> 
                                    
                            <div class="w-100 h-5/6 p-1 bg-gray-200 overflow-scroll">
                                {newEditData.orders.map((item, index) => {
                                console.log(newEditData.orders);
                                return (
                                    <div class="w-100 flex my-1 mb-2 p-2 bg-white rounded relative">
                                    {/* <p class="w-6 x-1 h-100 p-2 mt-2 bg-transparent text-black text-xl rounded">
                                        {index+1}
                                    </p> */}

                                    <div class="flex flex-grow -mx-3 mb-2 ml-2">
                                        <div class="w-full md:w-32 px-1">
                                        <label for="quantity" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1">
                                            Quantity
                                        </label>
                                        <input maxLength={9} value={item.quantity} min={1} step={1} name="quantity" autoComplete="off" onChange={(e) => onChangeItem(index, e)} class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="quantity" type="number" />
                                        </div>

                                        <div class="w-full md:w-48 px-1">
                                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" for="unit">
                                            Unit
                                        </label>
                                        <input maxLength={60} value={item.unit} name="unit" autoComplete="off" onChange={(e) => onChangeItem(index, e)} class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="unit" type="text" />
                                        </div>

                                        <div class="w-full md:w-48 px-1">
                                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" for="weight">
                                            <button name="weightType" onClick={(e) => onChangeItem(index, e)} value={"LBS"} class="" style={item.weightType=="LBS"?{color:"blue"}:{color:"black"}}>LBS</button> / <button onClick={(e) => onChangeItem(index, e)} value={"KGS"} name="weightType" class="" style={item.weightType=="KGS"?{color:"blue"}:{color:"black"}}>KGS</button>
                                        </label>
                                        <input maxLength={9} value={item.weight} min={0.1} step={0.1} autoComplete="off" name="weight" onChange={(e) => onChangeItem(index, e)} class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" id="weight" />
                                        </div>  

                                        <div class="w-full md:w-48 px-1">
                                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" for="reference">
                                            Reference
                                        </label>
                                        <input maxLength={60} value={item.reference} name="reference" autoComplete="off" onChange={(e) => onChangeItem(index, e)} class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reference" type="text" />
                                        </div>

                                        <div class="w-full md:w-48 px-1">
                                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" for="to">
                                            To
                                        </label>
                                        <input maxLength={60} value={item.to} name="to" autoComplete="off" onChange={(e) => onChangeItem(index, e)} class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="to" type="text" />
                                        </div> 

                                        <div class="w-full md:w-4/6 px-1 mr-4">
                                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" for="description">
                                            Description
                                        </label>
                                        <input onChange={(e) => onChangeItem(index,e)} name="description" placeholder="Additional details" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" value={item.description}></input>
                                        </div>
                                        
                                    </div>

                                    <button 
                                        onClick={() => {removeItem(index)}} 
                                        class="absolute top-1 right-0 p-1 hover:bg-red-500 bg-red-400 rounded ">
                                        <BsX color="white"/>
                                        </button>
                                    
                                    </div>     
                                )}
                                )}
                            </div>                            
                                                   
                        </div>

                        <div class="w-full px-3">
                            {!isLoading?
                            <div onClick={onSubmitEditJobPickup} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                                <p class="flex m-auto">Save</p>
                            </div> 
                            :   
                            <div class="w-36 h-10 flex bg-gray-600 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                <span class="flex m-auto"><Loading type={1} /></span>
                            </div> 
                            }                                                                    
                        </div>
                    </div>                 
                
                </div>
                :displayMenu==5
                ? // Map Emails for Outgoing Job
                <div class="bg-gray-200 pb-4 sm:p-6 h-full">
                    <div class="h-100 w-100">
                        <div class="w-100 h-full pb-2 mx-4">
                            <button class="text-xl font-bold text-blue-600" onClick={() => cancelEmailMapping()}>← Back</button>
                            <div class="flex w-100 mt-2">
                                <h3 class="text-black">
                                    Map Emails
                                </h3>

                                {/* if newEmailsObject where keys == true are not equal to newEditData.emailsList show red text "nope" */}
                                {
                                    JSON.stringify(Object.keys(newEmailsObject).filter(key => newEmailsObject[key] === true).sort()) !== JSON.stringify(newEditData.emailsList?.sort())
                                    ? <span class="text-red-400 font-bold my-auto ml-4">Click "Confirm" to save changes.</span>
                                    : null
                                }
                                
                            </div>
                            <div id="innerEditMenu" class="h-100 w-100 pt-3 pb-0 inline-block bg-gray-200 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">

                                {displayMenuEmails==0
                                ? // Select Email List
                                <div class="bg-gray-200 pb-4 sm:p-6 h-full">
                                    <div class="flex pb-1 pt-3">
                                        <div class="align-center">
                                            <h5 class="ml-3">Select Emails for this order</h5>                                    
                                        </div>
                                        <span onClick={() => setDisplayMenuEmails(1)} class="ml-auto text-lg font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">Add/Edit Email List</span>
                                    </div>  

                                    <div class="flex flex-wrap -mx-3 px-6 py-4 h-3/4 max-h-3/4">
                                        <div class="shadow w-100 px-3 bg-gray-100 h-100 border-black overflow-scroll py-2">                                        
                                            
                                            {newEmailsObject?
                                                Object.keys(newEmailsObject)[0].length>0                                        
                                                    ?Object.keys(newEmailsObject).map((key, index) => {
                                                        //console.log(newEmailsObject[key])
                                                        return (
                                                            <div class="w-100 flex h-10"> 
                                                            {   newEmailsObject[key]
                                                                ?<Checkbox onChange={() => onSelectItem(key)} checked/>
                                                                :<Checkbox onChange={() => onSelectItem(key)} />}
                                                                <p class="font-bold align-middle ml-2 my-auto flex">{key}</p>
                                                            </div>
                                                        )}
                                                    )
                                                    :
                                                    <div class="h-100 w-100 flex">
                                                        <p class="flex m-auto text-red-500 font-bold">No Emails Found</p>
                                                    </div>
                                                :<p>???</p>
                                            }
                                                                                    
                                        </div>
                                    </div> 
                                    <div class="w-full md:w-100 px-3 mt-2">
                                        {!isLoading?
                                        <div onClick={onConfirmSelectedEmails} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                                            <p class="flex m-auto">Confirm</p>
                                        </div> 
                                        :   
                                        <div class="w-36 h-10 flex bg-blue-700 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                            <span class="flex m-auto"><Loading type={1} /></span>
                                        </div> 
                                        }
                                    </div> 
                                </div>                
                                :displayMenuEmails==1
                                ? // Add/Edit Email List
                                <div class="bg-gray-200 pb-4 sm:p-6 h-full">                    
                                    <div class="flex pb-1 pt-3">                                        
                                        <div class="align-center flex mr-auto">
                                            <h5 class="text-black">Add/Edit Emails List</h5>                                                                            
                                        </div>                  
                                        <span onClick={() => setDisplayMenuEmails(0)} class="ml-auto text-lg font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">Select Emails</span>                  
                                    </div>  

                                    <div class="flex flex-wrap -mx-3 px-6 py-4 h-3/4 max-h-3/4">
                                        <div class="shadow w-100 px-3 bg-gray-100 h-100 border-black overflow-scroll py-2">                                        

                                            <textarea 
                                                class="w-100 h-100 border-blue-400 border-2 rounded p-2"
                                                onChange={(e) => editEmailBlob(e)} 
                                                value={newEmailsBlob}
                                                placeholder="Add emails seperated by ';'. Example: emailA@email.com;emailB@email.com;...   Do not end with a ';'"
                                            />
                                                                        
                                        </div>
                                    </div> 

                                    <div class="w-full md:w-100 px-3 mt-2">
                                        {!isLoading?
                                        <div onClick={saveEmailBlob} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                                            <p class="flex m-auto">Save</p>
                                        </div> 
                                        :   
                                        <div class="w-36 h-10 flex bg-blue-700 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                            <span class="flex m-auto"><Loading type={1} /></span>
                                        </div> 
                                        }
                                    </div> 
                                </div>
                                :null}
                            </div>  
                        </div>
                    </div>
                </div>



                :null}                    
            </div>    

        </Popup>                    
    );
    
}