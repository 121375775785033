import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';

import moment from 'moment';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';

import Loading from '../modules/loading.jsx';

const { resetPage } = React;

export default function DataTable({
  username, 
  loading, 
  editQuantities, 
  totalWeight,
  data, 
  columns, 
  editFunc, 
  fileFunc, 
  assignFunc, 
  selectData, 
  onEditQuantity, 
  editTableData, 
  onSort, 
  readMessage, 
  deleteMessage 
}) {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [tableColor, setTableColor] = useState(JSON.parse(localStorage.getItem("tableColor")) || false);

    //const [dataCopy, setDataCopy] = useState(JSON.parse(JSON.stringify(data)));

    // when data changes, reset page to 0
    useEffect(() => {
      setPage(0);
      //alert("ALERT")

      let table_color = JSON.parse(localStorage.getItem("tableColor"))
      //console.log("DATA", data);
      //console.log(table_color)
      //console.log(tableColor)

    }, [data]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //<div class="ml-auto h-full flex-col flex-grow">
    //<Paper sx={{ position:"relative", height: "calc(100% - 6rem)", width:'100%', overflow:"hidden" }}>
    //  <TableContainer sx={{ maxHeight: "94%", height:"100%" }}>
        
    return (
      <div class="ml-auto h-full flex-col flex-grow">
          <Paper sx={{ position:"relative", height: "calc(100% - 0rem)", width:'100%', overflow:"hidden" }}>
            <TableContainer sx={{ maxHeight: "91%", height:"100%",  }}>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead  sx={{"& th": {
                    color: "rgba(96, 96, 96)",
                    backgroundColor: "rgba(240, 240, 240, 1.0)",
                    cursor: "pointer",
                    fontWeight: "bold",
                    borderBottom: "2px solid slategray"                
                  },
                  "& th:hover": {
                    backgroundColor: "rgba(220, 220, 220, 1.0)"
                  }
                }}>
                  {data?.length == 0 ? // IF THERE IS NO DATA
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center"></TableCell>
                    </TableRow>
                  :
                  <TableRow>
                    {columns.map((column) => {
                      if (
                          (username !== 'admin' && column.id == 'edit') || 
                          (username !== 'admin' && column.id == 'client') || 
                          (username !== 'admin' && column.id == 'assign')) {
                        return null
                      }                       
                      if (column.id == 'quantity') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: "50px" }}
                            onClick={() => onSort(column.id)}
                          >
                            {column.label}
                          </TableCell>
                        )                                                    
                      }

                      else {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            onClick={() => onSort(column.id)}
                          >
                            {column.label}
                          </TableCell>
                        )
                      }
                    })}
                  </TableRow>
                  }
                </TableHead>
                <TableBody sx={{height:"100%"}}>
                  {data?.length == 0?// IF THERE IS NO DATA
                   loading?
                    <section className="flex items-center h-full p-16 relative w-100">
                        <div className="container w-100 flex flex-col items-center justify-center px-5 mx-auto my-20">
                            <div className="max-w-md text-center">
                                <h2 className="mb-8 font-extrabold text-5xl dark:text-gray-600">
                                    <span className="sr-only"></span>LOADING
                                </h2>
                                <p className="text-2xl font-semibold md:text-3xl">Searching for results...</p>
                                {/* <p className="mt-4 mb-8 dark:text-gray-400">But dont worry, you can find plenty of other things on our homepage.</p> */}
                                <div className="max-w-md text-center h-4 w-100 pt-4 px-12">
                                  <Loading type={3} class="w-100 h-32"/>
                                </div>
                            </div>
                        </div>
                      </section>
                    :
                    <section className="flex items-center h-full p-16 relative w-100">
                      <div className="container w-100 flex flex-col items-center justify-center px-5 mx-auto my-20">                          
                          <div className="max-w-md text-center">                              
                              <h2 className="mb-8 font-extrabold text-5xl dark:text-gray-600">                                  
                                  <span className="sr-only">Error</span>NO DATA
                              </h2>
                              <p className="text-2xl font-semibold md:text-3xl">No data available for this search.</p>
                              
                              {/* <p className="mt-4 mb-8 dark:text-gray-400">But dont worry, you can find plenty of other things on our homepage.</p> */}
                          </div>
                      </div>
                    </section>

                    :null
                  }
                  {data?
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {                      
                      return (
                        <TableRow 
                          hover 
                          role="checkbox" 
                          class={
                            tableColor?
                              row["status"]=="Inventory"?"bg-white":
                              row["status"]=="Pending"?"bg-gray-100":
                              row["status"]=="Delivered"?"bg-blue-100":
                              row["status"]=="Delayed"?"bg-yellow-100":
                              row["status"]=="Damaged"?"bg-red-200":
                              row["status"]=="Lost"?"bg-orange-200":
                              "bg-white"   
                            : "bg-white"                         
                           }
                           tabIndex={-1} 
                           key={row.code}>                                                   
                          {columns.map((column) => {  
                            const value = row[column.id];
                            if (!(
                              column.id == 'client' ||
                              column.id == 'edit' || 
                              column.id == 'read' ||
                              column.id == 'doc' || 
                              column.id == 'selected' ||
                              column.id == 'assign' ||
                              column.id == 'delete_mail' ||
                              column.id == 'editDescription' ||
                              (column.id == 'quantity' && editQuantities))) {                           
                              return (
                                  <TableCell key={column.id} align={column.align}>
                                      {column.format && typeof value === 'text'
                                      ? column.format(value)
                                      : value}
                                  </TableCell>                            
                              );
                            } 
                            if (column.id == 'editDescription') {
                              return (
                                  <TableCell key={column.id} align={column.align}>
                                      <input 
                                        value={row.description}
                                        class="border-blue-500 border-2 indent-2 rounded p-1 bg-white focus:!outline-none"
                                        type="text"
                                        maxLength={120}
                                        style={{width:"350px"}}
                                        onChange={(e) => editTableData(row.id, column.id, e.target.value)}                                      
                                      />
                                  </TableCell>
                              )
                            }
                                  
                            if (column.id == 'quantity' && editQuantities) {
                              return (
                                  <TableCell style={{width: '50px'}} key={column.id} align={column.align}>
                                      <input style={{width:"70px"}} value={value} min={1} class="border-blue-500 border-2 indent-2 rounded p-1 bg-white focus:!outline-none" type="number" onChange={(e) => onEditQuantity(row, e.target.value)}/>
                                  </TableCell>
                              )                            
                            } 
                            if (column.id == 'selected') {
                              return (
                                  <TableCell key={column.id} align={column.align}>
                                      <Checkbox onChange={() => selectData(row)} checked={row.selected?true:false}/>
                                  </TableCell>
                              )
                            }   
                            if (column.id == 'edit' && username=="admin") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                    <span onClick={() => editFunc(row, row.code)} class="flex text-red-400 hover:underline cursor-pointer">
                                      {column.label}
                                    </span>
                                </TableCell> 
                              )
                            }
   
                            if (column.id == 'read' && username=="admin") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                    <span onClick={() => readMessage(row, row.code)} class="flex text-blue-400 hover:underline cursor-pointer">
                                      {column.label}
                                    </span>
                                </TableCell> 
                              )
                            }
                            if (column.id == 'delete_mail' && username=="admin") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                    <span onClick={() => deleteMessage(row, row.code)} class="flex text-red-400 hover:underline cursor-pointer">
                                      {column.label}
                                    </span>
                                </TableCell> 
                              )
                            }
                            if (column.id == 'assign' && username=="admin") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                    <span onClick={() => assignFunc(row, row.code)} class="flex text-green-600 hover:underline cursor-pointer">
                                      {column.label}
                                    </span>
                                </TableCell> 
                              )
                            }
                            if (column.id == 'client' && username=="admin") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                     {column.format && typeof value === 'text'
                                      ? column.format(value)
                                      : value}
                                </TableCell> 
                              )
                            }
                            if (column.id == 'doc') {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                    <span onClick={() => fileFunc(row, row.code)} class="flex text-blue-600 hover:underline cursor-pointer">
                                      {column.label}
                                    </span>
                                </TableCell> 
                              )
                            }
                          })}
                        </TableRow>
                      );
                    })
                  :null}
                </TableBody>
              </Table>
            </TableContainer>
            
            <div class="p-2 flex mt-2">
              {totalWeight?
                <span class="font-bold text-sm">TOTAL: {totalWeight} LBS</span>
              :null}
            </div>
            
            <TablePagination
              pageSize={100}
              rowsPerPageOptions={[100]}
              //rowsPerPageOptions={[50, 100, 200]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}              
              sx={{position:"absolute",bottom:0,right:0}}
            />
          </Paper>
      </div>
    );
    
}