import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import useAuth from '../hooks/useAuth';
import useRefreshToken from '../hooks/useRefreshToken';

import Layout from '../context/layoutPortal.jsx';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

import Loading from '../modules/loading.jsx';

import MenuChecklist from '../modules/menuChecklist.jsx';

import Popup from '../context/layoutPopup.jsx';
import { Checkbox } from '@mui/material';

function arrayAlphebetize(array) {
    return array.sort((a, b) => a.localeCompare(b));
}
  
function removeDuplicatesFromArray(array) {  
    return array.filter((a, b) => array.indexOf(a) === b);
} 

function createMap(arr) {
    let map = {};
    for (let i=0; i<arr.length; ++i) {
        map[arr[i]] = false;
    }
    return map;
}

function correctJsonInObject(obj) {
    const keys = Object.keys(obj);
    keys.forEach(key => {
        const rawValue = obj[key];
        // Check if value is a string and looks like JSON
        if (typeof rawValue === 'string' && rawValue.trim().startsWith('{') && rawValue.trim().endsWith('}')) {
            try {
                // Correct common JSON string errors
                let correctedValue = rawValue
                    .replace(/\\"/g, '"')       // Unescape \"
                    .replace(/""/g, '"')        // Replace "" with "
                    .replace(/\\\\"/g, '\\"');  // Correct excessively escaped quotes

                // Remove incorrect extra quotes around keys and arrays
                correctedValue = correctedValue.replace(/"\[\"/g, '["').replace(/\"\]"/g, '"]');
                correctedValue = correctedValue.replace(/"{"/g, '{"').replace(/}"/g, '}');

                // Attempt to parse the JSON to check if it's valid
                const parsedJson = JSON.parse(correctedValue);
                // Re-encode to ensure correct formatting
                obj[key] = JSON.stringify(parsedJson);
            } catch (error) {
                console.error(`Error correcting JSON for key "${key}": ${error}`);
            }
        }
    });
    return obj;
}



export default function Form1({ notify }) {

    const axiosPrivate = useAxiosPrivate();    

    var new_date = new Date();      
    var date = moment().format('Y-M-D');
    var datetime = moment().format('YYYY-MM-DD HH:mm:ss');

    const auth = useAuth();
    const refresh = useRefreshToken();
    const navigate = useNavigate();
    const childRef = useRef();
    const fileRef = useRef();
    //let location = useLocation();

    const list_of_clients = arrayAlphebetize(Object.keys(auth.auth.divisionMap));
    const list_of_divisions = arrayAlphebetize(Object.keys(auth.auth.divisionMap));

    const [isLoading, setIsLoading] = useState(false);
    const [orderType, setOrderType] = useState(1);
    const [isSelected, setIsSelected] = useState(false);    
    const [selectedFile, setSelectedFile] = useState(null);
    const [customDestination, setCustomDestination] = useState(true);
    const [displaySelectEmailMenu, setDisplaySelectEmailMenu] = useState(false);
    const [emailObject, setEmailObject] = useState(createMap(auth.auth.emails[list_of_clients[0]].split(";")));
    const [emailOverride, setEmailOverride] = useState(false);
    
    const [popupMessage, setPopupMessage] = useState([]);
    const [showPopup, setShowPopup] = useState(false);

    const [displayAddDestinationPopup, setDisplayAddDestinationPopup] = useState(false);

    const [newDestinationBypass, setNewDestinationBypass] = useState(false);

    const [formData, setFormData] = useState({
        id: Math.round(new Date()),
        client: list_of_clients[0],
        owner: "LOCKS AND DOCKS",
        destination: auth.auth.divisionMap[list_of_clients[0]].split(";")[0],   
        reference: '',    
        supplier: '',
        from: auth.auth.divisionMap[list_of_clients[0]].split(";")[0],
        to: '',
        quantity: 1,        
        unit: '',
        weightType: 'LBS',
        weight: 1.0,
        status: 'Inventory',
        carrier: '',
        waybill: '',
        description: '',//`From ... via ... ###`,
        note: '', 
        emailsSelected: [],
        emailmap: [], // emailmap = JSON.parse(this.props.client_object[this.state.client_key].emailmap)[this.state.order_destination];
    })


    //useEffect(() => {
    //    //const page = location.pathname;
    //    console.log("LOGGIN ALL DATA HERE ==========")
    //    console.log(auth);      
    //}, []);


    const onChange = e => {
        console.log(orderType);
        if (orderType !== '3') {
            switch (e.target.name) {            
                case 'client':
                if (!e.target.value.includes('"') && !e.target.value.includes('`') && !e.target.value.includes("'")) {
                    setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                    destination: auth.auth.divisionMap[e.target.value].split(";")[0]
                    });
                }
                break;
                case 'supplier':
                if (!e.target.value.includes('"') && !e.target.value.includes('`') && !e.target.value.includes("'")) {
                    setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                    description: `From ${e.target.value} via ${formData.carrier ? formData.carrier : '...'} #${formData.waybill ? formData.waybill : '...'}`
                    });
                }
                break;
                case 'carrier':
                if (!e.target.value.includes('"') && !e.target.value.includes('`') && !e.target.value.includes("'")) {
                    setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                    description: `From ${formData.supplier ? formData.supplier : '...'} via ${e.target.value} #${formData.waybill ? formData.waybill : '...'}`
                    });
                }
                break;
                case 'waybill':
                if (!e.target.value.includes('"') && !e.target.value.includes('`') && !e.target.value.includes("'") ) {
                    setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                    description: `From ${formData.supplier ? formData.supplier : '...'} via ${formData.carrier ? formData.carrier : '...'} #${e.target.value}`
                    });
                }
                break;
                default:
                    console.log("default---------------")
                if (!e.target.value.includes('"') && !e.target.value.includes('`') && !e.target.value.includes("'")) {
                    setFormData({ ...formData, [e.target.name]: e.target.value });
                }
            }
        } else {
            if (!e.target.value.includes('"') && !e.target.value.includes('`') && !e.target.value.includes("'")) {
                setFormData({ ...formData, [e.target.name]: e.target.value });
            }
        }
          
    }

    const onChangeClient = e => {
        if (e.target.value.includes('"') || e.target.value.includes('`')) {}
        else {
            setFormData({ ...formData, [e.target.name]: e.target.value, destination: auth.auth.divisionMap[e.target.value].split(";")[0], from: auth.auth.divisionMap[e.target.value].split(";")[0] })
            setEmailObject(createMap(auth.auth.emails[e.target.value].split(";")));
        }
    }

    const uploadFile = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };
    
    const checkIfInfoIsComplete = async () => {

        console.log(orderType);
        console.log(formData);
        
        // MERCHANDISE
        if (orderType==1) {
            if (formData.client === '' || 
                formData.destination === '' || 
                formData.reference === '' || 
                formData.supplier === '' || 
                formData.quantity === '' || 
                formData.unit === '' ||
                formData.weight === '' ||
                formData.status === '' ||
                formData.carrier === '' ||
                formData.waybill === '' ) {
                    notify('Please fill out all fields', 'warning')
                    return false;
            } else {
                return true;
            }
        
        // OUTGOING
        } else if (orderType==2) {
            if (formData.client === '' ||             
            formData.from === '' || 
            formData.reference === '' || 
            formData.quantity === '' || 
            formData.unit === '' ||
            formData.weight === '' ||
            formData.status === '' ) {
                notify('Please fill out all fields', 'warning')
                return false;
            } else {
                return true;
            } 
        
        // 
        } else if (orderType==3) {
            if (formData.owner === '' || 
            formData.reference === '' || 
            formData.supplier === '' || 
            formData.quantity === '' || 
            formData.unit === '' ||
            formData.weight === '' ||
            formData.status === '' ) {
                notify('Please fill out all fields', 'warning')
                return false;
            } else {
                return true;
            }
        }
    }

    const addDestinationConfirmation = async () => {
        
        //setIsLoading(true);               

        console.log(auth.auth)
        
        let divisions = (auth.auth.divisionMap[formData.client]).split(";")
        let emailMap = JSON.parse(auth.auth.emailMap[formData.client])

        let new_divisions = [...divisions, formData.destination]         
        new_divisions = new_divisions.join(";")  

        let new_emailMap = {...emailMap, [formData.destination]: []}
        new_emailMap = JSON.stringify(new_emailMap)

        // division_blob = `${username}Default1`;
        // email_map = `"{\"${username}Default1\":[]}`; 

        const data = {
            client: formData.client,
            divisions: new_divisions,
            emailmap: new_emailMap,            
        }
        
        const body = JSON.stringify(data);

        console.log(body)
        try {
            const response = await axiosPrivate.post(`/users/addDestination`, body, { credentials: "include" });
            //notify("Destination Added", "success")
            setIsLoading(false);

            // Need to update the user object in the auth context
            const newAuth = {...auth.auth}
            newAuth.divisionMap[formData.client] = new_divisions
            newAuth.emailMap[formData.client] = new_emailMap
            auth.setAuth(newAuth)

            console.log(auth)

            await refresh();

        }
        catch {
            notify("Destination adding failed", "error")
            setIsLoading(false);
        }
        
        setDisplayAddDestinationPopup(false);
        setNewDestinationBypass(true) 
        submitOrder();
        
    }

    const submitOrder = async () => {   
        
        const checked = await checkIfInfoIsComplete();

        console.log("CHECKED", checked)

        if (checked) {

            setShowPopup(true)
            setPopupMessage(["","Authenticating"])            
            const checkJWT = await axiosPrivate.get('/checkJWT', {headers: { 'Content-Type': 'multipart/form-data' }})
            console.log(checkJWT);

            if (checkJWT?.data?.success) {

                setPopupMessage(["Authenticated.","Uploading File"])                            
                setIsLoading(true);

                try {            
                    //throw new Error('test error')            
                    const config = { headers: {'Content-Type': 'application/json'}}
                    const newID = String(Math.round(new Date()));                                           

                    let emailBlob = JSON.parse(auth.auth.emailMap[formData.client])
                    console.log(emailBlob)
                    console.log(formData.destination)
                    let emailList = emailBlob[formData.destination]
                    emailList = JSON.stringify(emailList)

                    //console.log("EMAIL LIST +++++++++++++", emailList);
                    //console.log("BYPASS ++++++++++", newDestinationBypass);

                    // if emailList is undefined, means that destination doesn't exist. Prompt to add destination
                    if (emailList === undefined && !newDestinationBypass) {
                        setIsLoading(false)                
                        setShowPopup(false)        
                        setDisplayAddDestinationPopup(true)

                    } else {

                        const body = JSON.stringify({...formData, id: newID, emailsList: emailList, emailOverride: emailOverride, from:"", owner: ""})

                        //console.log("EMAIL LIST ==========", emailList)

                        const fileData = new FormData()            
                        fileData.append('name', newID);
                        fileData.append('data', body)
                        fileData.append('new_file_name','new_name_here.pdf')       
                        fileData.append('file', selectedFile)
                        
                        const res = await axiosPrivate.post('/orders', fileData, {headers: { 'Content-Type': 'multipart/form-data' }})
                        console.log(res.data)
                        setFormData({
                            ...formData,
                            reference: '',    
                            supplier: '',
                            quantity: 1,        
                            unit: '',
                            weightType: 'LBS',
                            weight: 1.0,
                            status: 'Inventory',
                            carrier: '',
                            waybill: '',
                            description: '',
                            notes: '', 
                        })
                        
                        fileRef.current.value = "";
                        setIsSelected(false);
                        setSelectedFile(null);
                        setIsLoading(false);
                        setShowPopup(false)
                        notify('Order Created', 'success');
                    }
                } catch (err) {
                    setIsSelected(false);
                    setSelectedFile(null);
                    setIsLoading(false);
                    setShowPopup(false)
                    notify('Connection Refused', 'error');
                    console.log(err)
                }
            } else {
                notify('Authentication Failed', 'error')
                setIsSelected(false);
                setSelectedFile(null);
                setIsLoading(false);
                setShowPopup(false)
            }
        } else {
            setIsSelected(false);
            setSelectedFile(null);
            setIsLoading(false);
            setShowPopup(false)
            
            // REFRESH TOKEN...
        }
          
    }

    const submitInventoryOrder = async () => {

        const checked = await checkIfInfoIsComplete();
        if (checked) {

            setShowPopup(true)
            setPopupMessage(["","Authenticating"]) 
            const checkJWT = await axiosPrivate.get('/checkJWT', {headers: { 'Content-Type': 'multipart/form-data' }})
            console.log(checkJWT); 

            if (checkJWT?.data?.success) {
                setPopupMessage(["Authenticated.","Uploading File"])   
                setIsLoading(true);

                try {
                    //throw new Error('test error')            
                    const config = { headers: {'Content-Type': 'application/json'} }
                    const newID = String(Math.round(new Date()));                                
                    let emailBlob = JSON.parse(auth.auth.emailMap[formData.client])
                    console.log(emailBlob)
                    console.log(formData.destination)
                    let emailList = emailBlob[formData.destination]                
                    emailList = JSON.stringify(emailList)
                    const body = JSON.stringify({...formData, id: newID, emailsList: emailList, emailOverride: emailOverride})

                    //console.log("EMAIL LIST ==========", emailList)

                    const fileData = new FormData()            
                    fileData.append('name', newID);
                    fileData.append('data', body)
                    fileData.append('new_file_name','new_name_here.pdf')       
                    fileData.append('file', selectedFile)
                    
                    const res = await axiosPrivate.post('/inventory', fileData, {headers: { 'Content-Type': 'multipart/form-data' }})
                    console.log(res.data)
                    setFormData({
                        ...formData,
                        reference: '',    
                        supplier: '',
                        quantity: 1,        
                        unit: '',
                        weightType: 'LBS',
                        weight: 1.0,
                        status: 'Inventory',
                        carrier: '',
                        waybill: '',
                        description: '',
                        notes: '', 
                    })
                    
                    fileRef.current.value = "";
                    setIsSelected(false);
                    setSelectedFile(null);
                    setIsLoading(false);
                    setShowPopup(false)
                    notify('Order Added to Inventory', 'success');
                } catch (err) {
                    console.log(err)
                    setIsSelected(false);
                    setSelectedFile(null);
                    setIsLoading(false);
                    setShowPopup(false)
                    notify('Connection Refused', 'error');
                }       
            } else {
                notify('Authentication Failed', 'error')
                setIsSelected(false);
                setSelectedFile(null);
                setIsLoading(false);
                setShowPopup(false)
            }
        } else {
            setIsSelected(false);
            setSelectedFile(null);
            setIsLoading(false);
            setShowPopup(false)
            // REFRESH TOKEN...
        }

    }

    const submitOutgoingOrder = async () => {
       
        const checked = await checkIfInfoIsComplete();
        if (checked) {

            setShowPopup(true)
            setPopupMessage(["","Authenticating"])            
            const checkJWT = await axiosPrivate.get('/checkJWT', {headers: { 'Content-Type': 'multipart/form-data' }})
            console.log(checkJWT); 

            if (checkJWT?.data?.success) {

                setPopupMessage(["Authenticated.","Uploading File"])   
                setIsLoading(true);

                try {
                    //throw new Error('test error')            
                    const config = { headers: {'Content-Type': 'application/json'}}
                    const newID = String(Math.round(new Date()));                                
                    let emailBlob = JSON.parse(auth.auth.emailMap[formData.client])
                    let emailList = emailBlob[formData.destination]
                    emailList = JSON.stringify(emailList)
                    const body = JSON.stringify({...formData, id: newID, emailsList: emailList, emailOverride: emailOverride})

                    //console.log("EMAIL LIST ==========", emailList)

                    const fileData = new FormData()            
                    fileData.append('name', newID);
                    fileData.append('data', body)
                    fileData.append('new_file_name','new_name_here.pdf')       
                    fileData.append('file', selectedFile)
                    
                    const res = await axiosPrivate.post('/outgoing', fileData, {headers: { 'Content-Type': 'multipart/form-data' }})
                    console.log(res.data)
                    setFormData({
                        ...formData,
                        reference: '',    
                        supplier: '',
                        quantity: 1,        
                        unit: '',
                        weightType: 'LBS',
                        weight: 1.0,
                        status: 'Inventory',
                        carrier: '',
                        waybill: '',
                        description: '',
                        notes: '', 
                    })
                    
                    fileRef.current.value = "";
                    setIsSelected(false);
                    setSelectedFile(null);
                    setIsLoading(false);
                    setShowPopup(false)
                    notify('Outgoing Order Created', 'success');

                } catch (err) {
                    console.log(err.response.data)
                    setIsSelected(false);
                    setSelectedFile(null);
                    setIsLoading(false);
                    setShowPopup(false)
                    notify('Connection Refused', 'error');
                }
            }
            else {
                setIsSelected(false);
                setSelectedFile(null);
                setIsLoading(false);
                setShowPopup(false)
                notify('Authentication Failed', 'error')
                // REFRESH TOKEN...
            }
        } else {
            setIsSelected(false);
            setSelectedFile(null);
            setIsLoading(false);
            setShowPopup(false)
            // REFRESH TOKEN...
        }        

    }

    const selectEmailList = () => {        

        // if there is no client/from chosen, notify

        if (formData.client === '') {
            notify('No Client Selected', 'error');
            return;
        } else {
            setDisplaySelectEmailMenu(true);
        }       
    }

    const receiveEmailList = (newEmailObject) => {
        console.log("OLD", emailObject);
        console.log("NEW", newEmailObject);
        setEmailObject(newEmailObject);
    }

    const handleCustomDestination = () => {
        
        if (customDestination) {
            setCustomDestination(false);
            setFormData({...formData, destination: auth.auth.divisionMap[formData.client].split(";")[0]})
        } else {
            setCustomDestination(true);
            setFormData({...formData, destination: ""})
        }
    }


    return (

        <Layout>  
        
            {displayAddDestinationPopup? // create a centered popup to confirm deletion
                <Popup>
                <div id="popupMenu" class="absolute z-20 mx-auto my-auto inset-0 overflow-hidden h-1/4 p-2 w-1/2  bg-gray-500 text-white outline-double outline-black text-left shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
                    <div class="bg-gray-500 text-white sm:p-6 h-full w-100 flex">

                        <>
                            <div class="flex">
                                <div class="ml-6">
                                    <h3 class="">Add new destination: <span>{formData.destination}</span></h3>
                                    <p class="text-white">
                                        You are creating an order with a destination that isn't connected to this
                                        client. Would you like to add this destination to the client's account?                                    
                                    </p>
                                </div>
                            </div>
                            <div class="flex ml-auto mt-auto">
                                <button onClick={isLoading?null:() => setDisplayAddDestinationPopup(false)} class="bg-gray-200 w-28 h-10 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded mr-2">
                                    No
                                </button>
                                <div class="">
                                    {!isLoading?
                                    <div onClick={addDestinationConfirmation} class="w-28 h-10  flex bg-blue-500 hover:bg-blue-600 text-white font-bold mr-6 rounded mx-auto" type="button">
                                        <p class="flex m-auto">Yes</p>
                                    </div> 
                                    :   
                                    <div class="w-28 h-10 flex bg-blue-600 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span>
                                    </div> 
                                    }
                                </div> 

                            </div>
                        </>
                    </div>
                </div>                                                    
                </Popup>
            :null}


            {showPopup
            ? <Popup>
                
                    <div 
                        id="innerEditMenu" 
                        class="h-1/6 w-1/4 inline-block bg-white rounded-lg border-blue-500 border-2 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" 
                        role="dialog" 
                        aria-modal="true" 
                        aria-labelledby="modal-title"
                    >
                    
                    <div class="max-h-12 w-100 px-6 mt-4 flex flex-col ">
                        <h3 class="text-green-500 font-bold w-100">{popupMessage[0]}</h3>                        
                        <h3 id="loading" class="text-black text-md bg-transparent">{popupMessage[1]}</h3>
                    </div>                        
                        
                </div>
                </Popup>                
            :null} 


            <div className="form_header w-100 flex h-12 mt-6 mb-10 ">  
                <h3 class="flex ml-6">
                    {"Add Order For"} 
                    {orderType==1?" Merchandise"
                    :orderType==2?" Outgoing"
                    :orderType==3?" Inventory"
                    :" Custom"}
                </h3>

                <div class="ml-auto mr-6 w-1/3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                    Select Order Type
                </label>
                <select value={orderType} onChange={(e) => setOrderType(e.target.value)} class="shadow block w-full bg-white text-gray-700 black border-blue-500 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="client" type="text">
                    <option value={1} selected={orderType==1?true:false}>Merchandise</option> 
                    <option value={2} selected={orderType==2?true:false}>Outgoing</option> 
                    <option value={3} selected={orderType==3?true:false}>Inventory</option> 
                </select>
                    
                </div>
            </div>           

            {displaySelectEmailMenu?
                <MenuChecklist                 
                    emailsObject={emailObject}
                    emailsBlob={auth.auth.emails[formData.client]}
                    client={formData.client}
                    toggleDisplay={setDisplaySelectEmailMenu}
                    updateParent={receiveEmailList}
                    notify={notify}
                />
            :null}        

            {orderType==1
            ? // MERCHANDISE
            <>
                <div class="flex flex-wrap -mx-3 px-6 py-4">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                            Client *
                        </label>
                        <select name="client" onChange={(e) => onChange(e)} class="black border-black rounded shadow block w-full bg-white text-gray-700 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="client" type="text">
                            {auth.auth?
                            list_of_clients.map(d => {
                            return <option value={d}>{d}</option> 
                            })
                        :
                        <option value="default">default</option>
                        }
                        </select>
                        {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                    </div>

                    <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Destination *
                            {customDestination
                            ?<button onClick={handleCustomDestination} class="text-blue-500 ml-4 hover:text-blue-600 cursor-pointer hover:underline">CUSTOM</button>
                            :<button onClick={handleCustomDestination} class="text-blue-500 ml-4 hover:text-blue-600 cursor-pointer hover:underline">PREDEFINED</button>
                            }
                        </label>
                        {customDestination
                        ?
                        <select name="destination" onChange={(e) => onChange(e)} class="shadow block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="destination" type="text" >
                            {auth.auth?
                            
                            arrayAlphebetize(auth.auth.divisionMap[formData.client].split(";")).map(d => {                                
                                return <option value={d}>{d}</option>
                            })
                            :
                            <option value="default">default</option>
                            }
                        </select>
                        :
                        <input autoComplete='off' name="destination" onChange={(e) => onChange(e)} placeholder="Input custom destination..." class="shadow block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text"></input>
                        }
                    </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-2 px-6">

                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                        Supplier
                    </label>
                    <input maxLength={60} value={formData.supplier} name="supplier" autoComplete="off" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="supplier" type="text" placeholder="" />
                    </div>

                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                        Reference
                    </label>
                    <input maxLength={60} value={formData.reference} name="reference" autoComplete="off" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reference" type="text" placeholder="" />
                    </div>
                    
                </div>

                <div class="flex flex-wrap -mx-3 mb-2 px-6">
                    <div class="w-full md:w-1/3 px-3">
                    <label for="quantity"  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Quantity
                    </label>
                    <input maxLength={9} value={formData.quantity} min={1} step={1} name="quantity" autoComplete="off" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="quantity" type="number" />
                    </div>

                    <div class="w-full md:w-1/3 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="unit">
                        Unit
                    </label>
                    <input maxLength={60} value={formData.unit} name="unit" placeholder="Unit type" autoComplete="off" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="unit" type="text" />
                    </div>

                    <div class="w-full md:w-1/3 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="weight">
                        Weight    ( <button name="weightType" onClick={(e) => onChange(e)} value={"LBS"} class="" style={formData.weightType=="LBS"?{color:"blue"}:{color:"black"}}>LBS</button> / <button onClick={(e) => onChange(e)} value={"KGS"} name="weightType" class="" style={formData.weightType=="KGS"?{color:"blue"}:{color:"black"}}>KGS</button> )
                    </label>

                    <input maxLength={9} value={formData.weight} min={0.1} step={0.1} autoComplete="off" name="weight" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" id="weight" />
                    </div>                    
                </div>

                <div class="flex flex-wrap -mx-3 mb-2 px-6">
                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="carrier">
                        Carrier
                    </label>
                    <input value={formData.carrier} name="carrier" autoComplete="off" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="carrier" type="text" placeholder="" />
                    </div>

                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="waybill">
                        Waybill
                    </label>
                    <input value={formData.waybill} name="waybill" autoComplete="off" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="waybill" type="text" placeholder="" />
                    </div>
                </div>                 

                {/* <div className="form_row_5">
                    <p>Notes</p>
                    <textarea type="text" maxLength={500} defaultValue={formData.note} className="add_form_input" id="input_notes" autoComplete="off" name="note" onChange={(e) => this.handle_change(e)}  />
                </div> */}

                <div class="w-full h-24 mr-3 px-7">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="note">
                        Description
                    </label>
                    <input value={formData.description} maxLength={250} onChange={(e) => onChange(e)} name="description" autoComplete="off" placeholder="Description" class="black border-black rounded shadow appearance-none block w-full bg-white text-gray-700  py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="description" type="text" />
                </div> 

                <div class="w-full h-32 mr-3 px-7">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="note">
                        Notes
                    </label>
                    <textarea style={{resize:'none'}} value={formData.note} onChange={(e) => onChange(e)} name="note" autoComplete="off" placeholder="Additional notes..." class="h-full shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="note" type="text" />
                </div> 

                <div class="flex flex-wrap -mx-3 mb-2 px-6 mt-5 pb-6">

                    <div class="w-full md:w-1/3 px-3">
                        <label for="status" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            Status
                        </label>
                        <select onChange={(e) => onChange(e)} name="status" class="black border-black rounded shadow block w-full bg-white text-gray-700 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="status" >
                            <option selected={true} value="Inventory">Inventory</option>
                            <option value="Delivered">Delivered</option>
                            <option value="Damaged">Damaged</option>
                            <option value="Lost">Lost</option>
                            <option value="Delayed">Delayed</option>
                        </select>
                    </div>

                    <div class="w-full md:w-1/3 px-3 mt-4">
                        <form action="#" className="upload_button_div">
                            <label class="font-bold">Upload Packing Slip</label>
                            <br></br>                            
                            <input 
                                style={isSelected?{color:"green",fontWeight:"bold"}:{color:"red",fontWeight:"bold"}}
                                ref={fileRef}
                                //key={isSelected} 
                                className={isSelected?"upload_file_input_selected":"upload_file_input_none"} 
                                type="file" 
                                name="file" 
                                onChange={uploadFile} />
                            {/* <button className="upload_button" onClick={(e) => this.handleSubmission(e)}>Upload</button> */}
                        </form>
                    </div>
  

                    <div class="w-full md:w-1/3 px-3 mt-4 flex">
                        
                        <div class="bg-slate-200 rounded p-3">
                            <div class="flex h-6">
                                <Checkbox 
                                    checked={emailOverride}
                                    onChange={() => setEmailOverride(!emailOverride)}                                                             
                                />
                                <p>Email Override</p>                                
                            </div>
                            <sub>If selected: submitting this order will not send email to client</sub>
                        </div>

                        {!isLoading?
                        <div onClick={submitOrder} class="w-28 h-10 flex bg-blue-600 hover:bg-blue-700 text-white font-bold rounded ml-auto" type="button">
                            <p class="flex m-auto">Submit</p>
                        </div> 
                        :   
                        <div class="w-28 h-10 flex bg-blue-500 text-white font-bold rounded ml-auto pointer-events-none" type="button">
                            <span class="flex m-auto"><Loading type={1} /></span>
                        </div> 
                        }
                    </div> 
                    
                </div>   
            </>
            :orderType==2
            ? // OUTGOING            
            <>
                <div class="flex flex-wrap -mx-3 px-6 py-4">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                        Client *
                    </label>
                    <select name="client" onChange={(e) => onChangeClient(e)} class="shadow block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="client" type="text">
                        {auth.auth?
                        list_of_clients.map(d => {
                            return <option value={d}>{d}</option> 
                        })
                    :
                    <option value="default">default</option>
                    }
                    </select>
                    {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                    </div>

                    <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            From *
                            {customDestination
                            ?<button onClick={handleCustomDestination} class="text-blue-500 ml-4 hover:text-blue-600 cursor-pointer hover:underline">CUSTOM</button>
                            :<button onClick={handleCustomDestination} class="text-blue-500 ml-4 hover:text-blue-600 cursor-pointer hover:underline">PREDEFINED</button>
                            }   
                        </label>

                        {customDestination
                        ?
                            <select name="from" onChange={(e) => onChange(e)} class="shadow block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="from" type="text" >
                                {auth.auth?
                                    auth.auth.divisionMap[formData.client].split(";").map(d => {
                                        return <option value={d}>{d}</option>
                                    })
                                :
                                <option value="default">default</option>
                                }
                            </select>
                            :
                            <input autoComplete='off' name="from" onChange={(e) => onChange(e)} placeholder="From " class="shadow block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text"></input>
                        }

                        {/* <select name="from" onChange={(e) => onChange(e)} class="shadow block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="from" type="text" >
                            {auth.auth?
                            auth.auth.divisionMap[formData.client].split(";").map(d => {
                                return <option value={d}>{d}</option>
                            })
                        :
                        <option value="default">default</option>
                        }
                        </select> */}

                    </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-2 px-6">
                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                        Reference
                    </label>
                    <input value={formData.reference} maxLength={60} name="reference" autoComplete="off" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reference" type="text" placeholder="Input reference..." />
                    </div>

                    <div class="w-full md:w-1/2 px-3">

                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            To *
                            {/* {customDestination
                            ?<button onClick={() => setCustomDestination(false)} class="text-blue-500 ml-4 hover:text-blue-600 cursor-pointer hover:underline">CUSTOM</button>
                            :<button onClick={() => setCustomDestination(true)} class="text-blue-500 ml-4 hover:text-blue-600 cursor-pointer hover:underline">PREDEFINED</button>                                                                                
                            } */}
                        </label>
                        <input autoComplete='off' value={formData.to} maxLength={60} name="to" onChange={(e) => onChange(e)} placeholder="Input destination..." class="shadow block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text"></input>
                        {/* {customDestination
                        ?
                        <select name="destination" onChange={(e) => onChange(e)} class="shadow block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="destination" type="text" >
                            {auth.auth?
                            auth.auth.divisionMap[formData.client].split(";").map(d => {
                                return <option value={d}>{d}</option>
                            })
                            :
                            <option value="default">default</option>
                            }
                        </select>
                        :
                        <input autoComplete='off' name="destination" onChange={(e) => onChange(e)} placeholder="Input custom destination..." class="shadow block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text"></input>
                        } */}
                    
                    </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-2 px-6">
                    <div class="w-full md:w-1/3 px-3">
                    <label for="quantity"  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Quantity
                    </label>
                    <input maxLength={9} min={1} step={1} value={formData.quantity} name="quantity" autoComplete="off" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="quantity" type="number" />
                    </div>

                    <div class="w-full md:w-1/3 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="unit">
                        Unit
                    </label>
                    <input maxLength={60} value={formData.unit} name="unit" placeholder="Unit type" autoComplete="off" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="unit" type="text" />
                    </div>

                    <div class="w-full md:w-1/3 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="weight">
                        Weight    ( <button name="weightType" onClick={(e) => onChange(e)} value={"LBS"} class="" style={formData.weightType=="LBS"?{color:"blue"}:{color:"black"}}>LBS</button> / <button onClick={(e) => onChange(e)} value={"KGS"} name="weightType" class="" style={formData.weightType=="KGS"?{color:"blue"}:{color:"black"}}>KGS</button> )
                    </label>

                    <input value={formData.weight} maxLength={9} min={0.1} step={0.1} autoComplete="off" name="weight" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" id="weight" />
                    </div>                    
                </div>

                <div class="w-full h-32 mr-3 px-7">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="note">
                        Description
                    </label>
                    <input value={formData.description} maxLength={250} onChange={(e) => onChange(e)} name="description" autoComplete="off" placeholder="Description" class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="description" type="text" />
                </div> 


                <div class="w-full h-32 mr-3 px-7">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="note">
                        Notes
                    </label>
                    <textarea style={{resize:'none'}} value={formData.note} maxLength={250} onChange={(e) => onChange(e)} name="note" autoComplete="off" placeholder="Additional notes..." class="h-full shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="note" type="text" />
                </div> 

                <div class="flex flex-wrap -mx-3 mb-2 px-6 mt-5">

                    <div class="w-full md:w-1/3 px-3">
                        <label for="status" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            Status
                        </label>
                        <select onChange={(e) => onChange(e)} name="status" class="shadow block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="status" >
                            <option selected={true} value="Inventory">Inventory</option>
                            <option value="Delivered">Delivered</option>
                            <option value="Damaged">Damaged</option>
                            <option value="Lost">Lost</option>
                            <option value="Delayed">Delayed</option>
                        </select>
                    </div>

                    <div class="w-full md:w-1/3 px-3 mt-4">
                    <form action="#" className="upload_button_div">
                        <label class="font-bold">Upload Packing Slip</label>
                        <br></br>                            
                        <input 
                            style={isSelected?{color:"green",fontWeight:"bold"}:{color:"red",fontWeight:"bold"}}
                            ref={fileRef}
                            //key={isSelected} 
                            className={isSelected?"upload_file_input_selected":"upload_file_input_none"} 
                            type="file" 
                            name="file" 
                            onChange={uploadFile} />
                        {/* <button className="upload_button" onClick={(e) => this.handleSubmission(e)}>Upload</button> */}
                    </form>
                    </div>                                        

                    <div class="w-full md:w-1/3 px-3 mt-4 flex">    

                        <div class="flex">
                            <div class="px-3">
                                <button onClick={selectEmailList} class="ml-auto rounded bg-gray-500 text-white hover:bg-gray-600 py-1 px-4 flex ">Select Email Addresses</button>
                                <div class="flex">                                
                                    {emailObject?
                                        Object.values(emailObject).filter(f => f==true).length>0
                                        ?<p class="ml-auto font-bold text-emerald-500">{Object.values(emailObject).filter(f => f==true).length} Emails Selected</p>
                                        :<p class="ml-auto font-bold text-red-500">No Emails Selected</p>
                                    :null}
                                </div>
                            </div>
                        </div>

                        {!isLoading?
                        <div onClick={submitOutgoingOrder} class="w-28 h-10 flex bg-blue-600 hover:bg-blue-700 text-white font-bold rounded ml-auto" type="button">
                            <p class="flex m-auto">Submit</p>
                        </div> 
                        :   
                        <div class="w-28 h-10 flex bg-blue-500 text-white font-bold rounded ml-auto pointer-events-none" type="button">
                            <span class="flex m-auto"><Loading type={1} /></span>
                        </div> 
                        }
                    </div> 
                    
                </div>   



            </>
            :orderType==3
            ? // INVENTORY
            <>
                <div class="flex flex-wrap -mx-3 mb-2 px-6 mt-4 ">

                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                        Owner *
                    </label>
                    <select name="owner" onChange={(e) => onChange(e)} class="shadow block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="owner" type="text">
                        <option value="LOCKS AND DOCKS">LOCKS AND DOCKS</option>
                        <option disabled>---------------</option>
                        {auth.auth?
                        list_of_clients.map(d => {
                           return <option value={d}>{d}</option> 
                        })
                    :
                    <option value="default">default</option>
                    }
                    </select></div>

                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="description">
                        Description
                    </label>
                    <input value={formData.description} onChange={(e) => onChange(e)} name="description" autoComplete="off" placeholder="Description" class=" shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="description" type="text" />
                    </div>

                </div>

                <div class="flex flex-wrap -mx-3 mb-2 px-6">

                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                        Supplier
                    </label>
                    <input value={formData.supplier} name="supplier" autoComplete="off" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="supplier" type="text" placeholder="" />
                    </div>

                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                        Reference
                    </label>
                    <input value={formData.reference} name="reference" autoComplete="off" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reference" type="text" placeholder="" />
                    </div>
                    
                </div>

                <div class="flex flex-wrap -mx-3 mb-2 px-6">
                    <div class="w-full md:w-1/3 px-3">
                        <label for="quantity"  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            Quantity
                        </label>
                        <input maxLength={9} min={1} step={1} value={formData.quantity} name="quantity" autoComplete="off" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="quantity" type="number" />
                    </div>

                    <div class="w-full md:w-1/3 px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="unit">
                            Unit
                        </label>
                        <input maxLength={50} value={formData.unit} name="unit" placeholder="Unit type" autoComplete="off" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="unit" type="text" />
                    </div>

                    <div class="w-full md:w-1/3 px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="weight">
                            Weight   ( <button name="weightType" onClick={(e) => onChange(e)} value={"LBS"} class="" style={formData.weightType=="LBS"?{color:"blue"}:{color:"black"}}>LBS</button> / <button onClick={(e) => onChange(e)} value={"KGS"} name="weightType" class="" style={formData.weightType=="KGS"?{color:"blue"}:{color:"black"}}>KGS</button> )
                        </label>
                        <input maxLength={9} min={0.1} step={0.1} value={formData.weight} autoComplete="off" name="weight" onChange={(e) => onChange(e)} class="shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" id="weight" />
                    </div>                    
                </div>

                <div class="w-full h-32 mr-3 px-7">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="note">
                        Notes
                    </label>
                    <textarea style={{resize:'none'}} maxLength={250} value={formData.note} onChange={(e) => onChange(e)} name="note" autoComplete="off" placeholder="Additional notes..." class="h-full shadow appearance-none block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="note" type="text" />
                </div> 

                <div class="flex flex-wrap -mx-3 mb-2 px-6 mt-5">

                    <div class="w-full md:w-1/3 px-3">
                        <label for="status" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            Status
                        </label>
                        <select onChange={(e) => onChange(e)} name="status" class="shadow block w-full bg-white text-gray-700 black border-black rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="status" >
                            <option selected={true} value="Inventory">Inventory</option>
                            <option value="Delivered">Delivered</option>
                            <option value="Damaged">Damaged</option>
                            <option value="Lost">Lost</option>
                            <option value="Delayed">Delayed</option>
                        </select>
                    </div>

                    <div class="w-full md:w-1/3 px-3 mt-4">
                        <form action="#" class="flex-col">
                            <label class="flex-row font-bold">Upload Packing Slip</label>                            
                            <br></br>
                            <input 
                                class="flex-row" 
                                style={isSelected?{color:"green",fontWeight:"bold"}:{color:"red",fontWeight:"bold"}} 
                                ref={fileRef}
                                type="file" 
                                name="file" 
                                onChange={uploadFile} 
                            />
                            {/* <button className="upload_button" onClick={(e) => this.handleSubmission(e)}>Upload</button> */}
                        </form>
                    </div>

                    <div class="w-full md:w-1/3 px-3 mt-4 flex">
                        
                        <div class="bg-slate-200 rounded p-3">
                            <div class="flex h-6 ">
                                <Checkbox 
                                    checked={emailOverride}
                                    onChange={() => setEmailOverride(!emailOverride)}                                                             
                                />
                                <p>Email Override</p>                                
                            </div>
                            <sub>If selected: submitting this order will not send email to client</sub>
                        </div>

                        {!isLoading?
                        <div onClick={submitInventoryOrder} class="w-28 h-10 flex bg-blue-600 hover:bg-blue-700 text-white font-bold rounded ml-auto" type="button">
                            <p class="flex m-auto">Submit</p>
                        </div> 
                        :   
                        <div class="w-28 h-10 flex bg-blue-500 text-white font-bold rounded ml-auto pointer-events-none" type="button">
                            <span class="flex m-auto"><Loading type={1} /></span>
                        </div> 
                        }
                    </div> 
                    
                </div>   
            </>
            :null}


            
        </Layout>
                    
    );
}


