import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import useInput from '../hooks/useInput';

import axios from "../context/axios";

import LayoutLLanding from '../context/layoutLanding.jsx';

import useRefreshToken from '../hooks/useRefreshToken';

//import logo from '../../static/assets/images/logo_big_gray.png';
import logo from '../../static/assets/images/image832_white.png';

export default function Login({ notify, isLogged }) {

    const refresh = useRefreshToken();

    const auth = useAuth();
    const { setAuth, persist, setPersist } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || "/";

    const usernameRef = useRef();
    const passwordRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [rememberMe, setRememberMe] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        usernameRef.current.focus();    
    }, []);

    useEffect(() => {
        //console.log("TRYING AUTH STUFF ------")
        //console.log(auth);
        if (auth?.auth?.accessToken) {
            // redirect to /dashboard

            //console.log("ACCESS TOKEN EXISTS!!!")
            //console.log(auth);
            
            navigate("/dashboard", { replace: true });
        }
    }, []);

    //useEffect(() => { setErrMsg(''); }, [user, pwd])

           
    const handleSubmit = async (event) => {
        setIsLoading(true)
        event.preventDefault();
        try {
            const response = await axios.post("/auth", 
                JSON.stringify({ username, password, rememberMe }),
                { 
                    headers: {'Content-Type':'application/json'}, 
                    withCredentials: true,
                    credentials: 'include',                    
                } 
            )

            //console.log(response.data);
            if (response?.data?.status==401) {
                console.log("invalid username or password")
                notify("Invalid username or password", "error");
                setIsLoading(false);
                return;
            }
            
            const accessToken = response?.data?.accessToken;
            const roles = ""//response?.data?.roles;  
            const divisionMap = response?.data?.divisionMap;            
            const emailMap = response?.data?.emailMap;
            const emails = response?.data?.emails;
            const name = response?.data?.name;

            setAuth({ name, username, password, roles, divisionMap, emailMap, emails, accessToken });
            setUsername("");
            setPassword("");
            navigate("/dashboard", { replace: true });
            setIsLoading(false);
            
        } catch (err) {
            if(!err?.response){
                console.log("no response");
                setIsLoading(false);
            } else if (err.response?.status == 400) {
                //setErrMsg(err.response.data.message);
                console.log("missing username or password")
                notify("Missing username or password", "error");
                setIsLoading(false);
            }
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    const togglePersist = () => { 
        setPersist(prev => !prev);
    }; 

    //useEffect(() => {
    //    localStorage.setItem('persist', persist);
    //},[persist])


    return (

            <div             
                class="h-full bg-no-repeat bg-cover bg-center relative" 
                //style={{backgroundImage:`url(https://images.unsplash.com/photo-1494412519320-aa613dfb7738?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)`}}
                style={{backgroundImage:`url(https://images.unsplash.com/photo-1519213887655-a4f199e3015b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80)`}}
            >
                <div id="image_mask" class="absolute bg-gradient-to-b inset-0 z-0 opacity-75 bg-gray-300"></div>

                {/* <svg id="dot_svg" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" style={{zIndex: 10, position: 'absolute'}}>
                    <pattern id="dot-pattern" patternUnits="userSpaceOnUse" width="80" height="40">

                        <circle fill="#ddd" cx="20" cy="20" r="2"></circle>
                        <circle fill="#ddd" cx="60" cy="20" r="2"></circle>
                    </pattern>
                    <rect width="160%" height="100%" fill="url(#dot-pattern)">
                        <animateTransform attributeName="transform" type="translate" from="-80 0" to="0 0" dur="5s" repeatCount="indefinite" />
                    </rect>
                </svg> */}

                <div class="absolute bg-black w-100 z-20">          
                    <div class="px-12 flex items-center justify-between py-3">

                    <div class="flex items-center space-x-6">
                        <a href="/" class="flex"><img src={logo} height={100} width={100} alt="logo"></img></a>
                    </div>              

                    <div  class="flex items-center space-x-8 ">
                    </div>
            
                    </div>
                </div> 

            {/* <div class="bg-no-repeat bg-cover bg-center relative" style={{backgroundImage:`url(https://source.unsplash.com/random)`}}><div class="absolute bg-gradient-to-b opacity-75 inset-0 z-0"></div> */}
            {/* <div class="bg-no-repeat bg-cover bg-center relative" style={{backgroundImage:`url(${bgpic})`}}><div class="absolute bg-gradient-to-b opacity-75 inset-0 z-0"></div> */}
                    <div class="h-full sm:flex sm:flex-row mx-0 justify-center">
                        
                        {/* <div class="bg-gray-300 rounded-xl mr-4 flex-col flex self-center p-10 sm:max-w-5xl xl:max-w-2xl z-10">
                            <div class="self-start hidden lg:flex flex-col  text-gray-800">
                                <h1 class="mb-3 font-bold text-5xl">Welcome Back</h1>
                                <p class="pr-3">

                                We're glad to see you again. 

                                Please enter your login details to access your account. 
                                If you're new here, please contact us to create an account. 
                                
                                </p>
                            </div>
                        </div> */}
                        
                        <div class="flex self-center z-10 w-1/3 border-blue-800 shadow-lg">
                            <div id="login_container" class="p-10 bg-white mx-auto w-100 sm:text-sm ">
                                <div class="mb-4">
                                    <h3 class="font-semibold text-2xl text-gray-800">Sign In </h3>
                                    <p class="text-gray-500">
                                    Welcome! Please enter your login details to access your account. 
                                    If you're new here, please contact us to create an account.  
                                    </p>
                                </div>

                                <form onSubmit={isLoading?null:handleSubmit}>
                                    <div class="space-y-5">
                                        <div class="space-y-2">
                                            <label class="text-sm font-medium text-gray-700 tracking-wide">Username</label>
                                            <input class=" w-full text-base px-4 py-2 border  border-gray-300 rounded-lg focus:outline-none focus:border-green-400" onChange={(e) => setUsername(e.target.value)} type="text" placeholder="Enter your username" value={username} autoComplete="off" ref={usernameRef} required/>
                                        </div>
                                        <div class="space-y-2">
                                            <label class="mb-1 text-sm font-medium text-gray-700 tracking-wide">Password</label>
                                            <div class="relative">
                                                <input class="w-full text-base px-4 py-2 border  border-gray-300 rounded-lg focus:outline-none focus:border-green-400" onChange={(e) => setPassword(e.target.value)} type={showPassword ? "text" : "password"} placeholder="Enter your password" value={password} ref={passwordRef} required/>
                                                <button class="absolute inset-y-0 right-0 pr-3 flex items-center" onClick={(e) => {e.preventDefault(); setShowPassword(!showPassword)}}>{showPassword ? 'Hide' : 'Show'}</button>
                                            </div>
                                        </div>
                                        <div class="flex items-center justify-between">
                                            <div class="flex items-center">
                                                <input onChange={togglePersist} id="remember_me" name="remember_me" type="checkbox" class="h-4 w-4 bg-blue-500 focus:ring-blue-400 border-gray-300 rounded" />
                                                <label for="remember_me" class="ml-2 block text-sm text-gray-800">Stay Logged In</label>
                                            </div>
                                            <div class="text-sm">
                                                {/* <a href="#" class="text-blue-500 hover:text-blue-700">Forgot your password?</a> */}
                                            </div>
                                        </div>
                                        <div class="bg-blue-800 rounded">
                                            <button id={isLoading?"loading":"loaded"} type="submit" class="w-full flex justify-center bg-black  hover:bg-gray-700 text-gray-100 p-3  rounded-2 tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500">{isLoading?"Signing in":"Sign in"}</button>
                                        </div>     
                                    </div>
                                </form>

                                {/* <button onClick={() => refresh()}>refresh</button> */}
                            
                            {/* <div class="pt-5 text-center text-gray-400 text-xs">
                                <span>Copyright © 2023 <a href="/" rel="" target="_blank" title="Ajimon" class="text-green hover:text-green-500 ">LDF</a></span>
                            </div> */}
                            </div>
                        </div>

                        <div class="absolute flex bottom-0 bg-transparent h-6 w-100 p-1">
                            <p class="flex ml-auto text-white text-sm mr-1">Locks & Docks Forwarding Inc. {new Date().getFullYear()}</p>
                        </div> 

                    </div>
            </div>

    );
}

















