import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';

import info_pic from '../../static/assets/images/info-hp.png';

import LayoutLanding from '../context/layoutLanding';

// import forward arrow icon
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

export default function LandingInfo() {

  const childRef = useRef();
  let location = useLocation();

  //useEffect(() => {
  //  const page = location.pathname;
  //  document.body.classNameList.add('is-loaded')
  //  childRef.current.init();
  //  trackPage(page);
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [location]);

  return (
    <LayoutLanding>

        <section class="h-100 flex w-100">
            <div class="h-100 gradient-bg py-2 px-2 rounded-xl flex w-100 ">

              <div class="flex w-1/2 h-full bg-white mx-auto text-left p-6 md:text-center">
                  {/* <h1 class="mb-8 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:text-6xl md:tracking-tight">
                      <span>LDF </span> 
                      <span class="block w-full py-2 text-transparent bg-clip-text leading-12 bg-gradient-to-r from-green-400 to-purple-500 lg:inline">
                        building a buzz
                      </span> 
                      <span>
                        around your product ?
                      </span>
                  </h1> */}

                  <div class="flex-col flex my-auto p-6">
                      <h2 class="text-left my-6 text-4xl tracking-tight font-extrabold  text-gray-700">
                        Get a quote
                      </h2>
                      {/* <p class="text-left mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Get a quote
                      </p> */}
                      <p class="px-0 mb-8 text-md text-gray-600 md:text-md lg:px-24 text-left">
                        For over 35 years we've been moving people and merchandise to and from any location in Canada.
                        LDF provides its services to businesses in multiple industries and handles all types
                        of transportation needs.                     
                      </p>
                      <p class="px-0 mb-8 text-md text-gray-600 md:text-md lg:px-24 text-left">
                          We offer a full service for warehousing, forwarding and inventory management
                          as well as handling shipping, direct delivery and general logistics.
                          We are setup to handle deliveries to specialized locations such as ports, airports and more.
                          Contact us about your needs and we will be happy to provide you with a quote.
                      </p>

                      <p class="px-0 mb-8 text-red-400 text-sm md:text-sm lg:px-24 text-left font-bold">
                          * At this time account creation is only available to our clients.
                          To get started please contact us directly.
                      </p>
                      <div class="mb-4 space-x-0 md:space-x-2 md:mb-8">
                          <a href="/contact" class="inline-flex no-underline items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-black hover:!bg-gray-800 rounded sm:w-auto sm:mb-0">
                              Contact Us
                              <span class="ml-2">
                                <IoIosArrowForward />
                              </span>
                              {/* <svg class="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg> */}
                          </a>
                          {/* <a href="#_" class="inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg bg-gray-100 rounded sm:w-auto sm:mb-0">
                              Learn More
                              <svg class="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
                          </a> */}
                      </div>
                  </div>
              </div>

              <div class="w-1/2 p-16 mx-auto my-auto text-center md:w-10/12">
                  <div class="relative z-0 w-full mt-8">
                      <div class="relative overflow-hidden shadow-2xl">
                          <div class="flex items-center flex-none px-4 bg-blue-500 rounded-b-none h-11 rounded-xl">
                              <div class="flex space-x-1.5">
                                  <div class="w-3 h-3 border-2 border-white rounded-full"></div>
                                  <div class="w-3 h-3 border-2 border-white rounded-full"></div>
                                  <div class="w-3 h-3 border-2 border-white rounded-full"></div>
                              </div>
                          </div>
                          {/* <img src="https://cdn.devdojo.com/images/march2021/green-dashboard.jpg" /> */}
                          <img src={info_pic} />
                      </div>
                  </div>
              </div>

          </div>
        </section>
      
    </LayoutLanding>
  );
}
















