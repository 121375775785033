import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import useLogout from '../hooks/useLogout';

import useAuth from '../hooks/useAuth';
import useRefreshToken from '../hooks/useRefreshToken';

import Sidebar from '../modules/sidebar';
import Table from '../modules/table';

import moment from 'moment';
import Checkbox from '@mui/material/Checkbox';

import Layout from '../context/layoutPortal.jsx';
import { FiLogOut } from 'react-icons/fi';

import useLocalStorage from '../hooks/useLocalStorage';
import Loading from '../modules/loading';


export default function Profile({ notify }) {

  const auth = useAuth();
  const refresh = useRefreshToken();
  const navigate = useNavigate();
  const childRef = useRef();
  //let location = useLocation();

  const [data, setData] = useLocalStorage("dataKey", "initValue");

  const [isLoading, setIsLoading] = useState(false);
  const userPreferences = JSON.parse(JSON.stringify(auth.userPreferences));

  const [theme, setTheme] = useState(userPreferences.theme);
  const [language, setLanguage] = useState(userPreferences.language);
  const [tableColor, setTableColor] = useState(userPreferences.tableColors);
  const [searchPreferences, setSearchPreferences] = useState(userPreferences.searchPreferences);
  const [notificationPreferences, setNotificationPreferences] = useState(userPreferences.notificationPreferences);


  useEffect(() => {
    //const page = location.pathname;
    //console.log(auth);
    //console.log(userPreferences);

    if (localStorage.getItem("theme") === null) {
      localStorage.setItem("theme", "light");         
    }
    const theme = localStorage.getItem("theme");

    if (localStorage.getItem("language") === null) {
      localStorage.setItem("language", "en");
    }
    const language = localStorage.getItem("language") 

    if (localStorage.getItem("tableColor") === null) {
      localStorage.setItem("tableColor", true);
    }
    const tableColor = localStorage.getItem("tableColor");

    if (localStorage.getItem("searchPreferences") === null) {
      localStorage.setItem(
        "searchPreferences",
        JSON.stringify({
          inventory: true,
          pending: true,
          delivered: false,
          delayed: false,
          damaged: false,
          lost: false
        })
      );
    }
    const searchPreferences = JSON.parse(
      localStorage.getItem("searchPreferences")
    );

    if (localStorage.getItem("notificationPreferences") === null) {
      localStorage.setItem(
        "notificationPreferences",
        JSON.stringify({
          createJob: true,
          createOrder: false,
          editJob: true,
          editOrder: false
        })
      );
    }
    const notificationPreferences = JSON.parse(
      localStorage.getItem("notificationPreferences")
    );

    setTheme(theme);
    setLanguage(language);
    setTableColor(tableColor);
    setSearchPreferences(searchPreferences);
    setNotificationPreferences(notificationPreferences);

    //console.log(tableColor);

  }, []);


  const logout = useLogout();
  
  const signOut = async () => {
    await logout();
    //navigate to logout
    navigate('/login', { replace: true }); 
  }

  const savePreferences = async () => {
    setIsLoading(true)
    try {
        
      localStorage.setItem("theme", theme);
      localStorage.setItem("language", language);
      localStorage.setItem("tableColor", tableColor);    
      localStorage.setItem("searchPreferences", JSON.stringify(searchPreferences));
      localStorage.setItem("notificationPreferences", JSON.stringify(notificationPreferences));     
      
      notify("Preferences saved successfully", "success");

    }
    catch {
      notify("Preferences were not saved", "warning");
    }
    setIsLoading(false);
  }


  return (

    <>    

      <Layout>

        <div class="flex mt-2">
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl flex">Profile</p>          
          <button onClick={signOut} class="rounded flex bg-red-600 hover:bg-red-700 mt-2 px-3 py-2 ml-auto text-white h-10">Log Out<FiLogOut class="mt-1 ml-2 text-white"/></button> 
        </div>

        <div class="shadow flex w-100 bg-white p-4 rounded border-2 mt-6 mb-4">

          <div class="flex-1 w-1/2">
              <p class="font-bold">Name: </p>
              <p class="font-bold">Username: </p>
              <p class="font-bold">Email: </p>
          </div>

          <div class="flex-2 w-1/2 text-right">
              <p class="text-black">{auth.auth.name}</p>
              <p class="text-black">{auth.auth.username}</p>
              <p class="text-black">{auth.auth.email}</p>
          </div>

        </div>

        <div class="flex mt-2">
          <h3 class="flex">User Preferences</h3>          
        </div>

        {/* THEME PREFERENCES */}
        <div class="shadow flex w-100 bg-white p-4 rounded border-2 mt-6">

          <div class="flex-1 w-1/2">
              <p class="font-bold">Select Theme</p>
              <p>Change the color palette of the application for all pages</p>
          </div>

          <div class="flex-2 w-1/2">
              <select disabled name="theme" onChange={(e) => setTheme(e)} class="bg-gray-200 text-gray-400          flex my-4 ml-auto rounded p-2 border-blue-500 border-2 w-1/2 ">
                <option value="1" selected={theme=="light"?true:false}>Light</option>
                <option value="2" selected={theme=="dark"?true:false}>Dark</option>
              </select>
          </div>

        </div>

        {/* LANGUAGE PREFERENCES */}
        <div class="shadow flex w-100 bg-white p-4 rounded border-2 mt-6">

          <div class="flex-1 w-1/2">
              <p class="font-bold">Select Default Language</p>
              <p>Change the default language of the application's text.</p>
          </div>

          <div class="flex-2 w-1/2">
              <select disabled name="language" onChange={(e) => setLanguage(e)} class="bg-gray-200 text-gray-400           flex my-4 ml-auto rounded p-2 border-blue-500 border-2 w-1/2 ">
                <option value="1" selected={language=="en"?true:false}>English</option>
                <option value="2" selected={language=="fr"?true:false}>French</option>
              </select>
          </div>

        </div>
        
        {/* SEARCH PREFERENCES */}
        <div class="shadow flex w-100 bg-white p-4 rounded border-2 mt-6">
          <div class="w-1/2">
              <p class="font-bold">Order Table Search Preferences</p>
              <p>Pre-select the "status" value for orders searches</p>
          </div>

          <div class=" flex max-w-1/2 ml-auto">
              <div class="ml-6 flex-col flex-1">
                <span class="flex flex-row"><Checkbox onClick={() => setSearchPreferences({...searchPreferences, inventory:!searchPreferences.inventory})} checked={searchPreferences.inventory?true:false}/><p class="mt-3">Inventory</p></span>
                <span class="flex flex-row"><Checkbox onClick={() => setSearchPreferences({...searchPreferences, pending:!searchPreferences.pending})} checked={searchPreferences.pending?true:false}/><p class="mt-3">Pending</p></span>
                <span class="flex flex-row"><Checkbox onClick={() => setSearchPreferences({...searchPreferences, delivered:!searchPreferences.delivered})} checked={searchPreferences.delivered?true:false}/><p class="mt-3">Delivered</p></span>
              </div>
              <div class="ml-6 flex-col flex-2">
                <span class="flex flex-row"><Checkbox onClick={() => setSearchPreferences({...searchPreferences, delayed:!searchPreferences.delayed})} checked={searchPreferences.delayed?true:false}/><p class="mt-3">Delayed</p></span>
                <span class="flex flex-row"><Checkbox onClick={() => setSearchPreferences({...searchPreferences, damaged:!searchPreferences.damaged})} checked={searchPreferences.damaged?true:false}/><p class="mt-3">Damaged</p></span>
                <span class="flex flex-row"><Checkbox onClick={() => setSearchPreferences({...searchPreferences, lost:!searchPreferences.lost})} checked={searchPreferences.lost?true:false}/><p class="mt-3">Lost</p></span>
              </div>
          </div>
        </div>


        {/* NOTIFICATION PREFERENCES */}
        <div class="shadow flex w-100 bg-white p-4 rounded border-2 mt-6">

          <div class="flex-1 w-1/2">
              <p class="font-bold">Order Table Color Coding</p>
              <p>Table rows are color coded based on the order status of the item.</p>
          </div>

          <div class="flex-2 w-1/2">
              <select value={tableColor} onChange={(e) => setTableColor(e.target.value)} class="flex my-4 ml-auto rounded p-2 border-blue-500 border-2 w-1/2 ">
                <option value="true" selected={tableColor?true:false}>On</option>
                <option value="false" selected={tableColor?false:true}>Off</option>
              </select>
          </div>

        </div>
               

        {/* NOTIFICATION PREFERENCES */}
        <div class="shadow flex w-100 bg-white p-4 rounded border-2 mt-6">

          <div class="flex-1 w-1/2">
              <p class="font-bold">Notification Preferences</p>
              <p>Specify which changes provide notifications.</p>
          </div>

          <div class="flex-2 w-1/2">
              <select disabled class="flex my-4 ml-auto rounded p-2 border-blue-500 border-2 w-1/2 ">
                <option value="1" selected>All</option>
                <option value="2" disabled>Jobs</option>
                <option value="2" disabled>Orders</option>
                <option value="2" disabled>Edits</option>
              </select>
          </div>

        </div>

        {/* Save button */}   
        <div class="shadow flex w-100 bg-white p-4 rounded mt-6 mb-4">

            <div class="flex-1 w-1/2">
                <p class="text-black font-bold">Save Preferences</p>
            </div>

            <div class="max-h-12 flex-2 w-1/2 ">
                <div class="w-100 mt-auto flex">

                    {!isLoading?
                    <div onClick={savePreferences} class=" ml-auto w-36 h-10 flex bg-blue-800 hover:bg-blue-900 text-white font-bold rounded" type="button">
                        <p class="flex m-auto">Save</p>
                    </div> 
                    :   
                    <div class="  w-36 h-10 flex bg-blue-700 text-white font-bold rounded pointer-events-none ml-auto" type="button">
                        <span class="flex m-auto"><Loading type={1} /></span> 
                    </div> 
                    }
                </div> 
            </div>

        </div>    
        
      </Layout>
                
    </>
  );
}


