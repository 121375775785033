import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';

import moment from 'moment';

import useAxiosPrivate from '../hooks/useAxiosPrivate';

import useAuth from '../hooks/useAuth';
import useRefreshToken from '../hooks/useRefreshToken';

import Layout from '../context/layoutPortal.jsx';

import Table from '../modules/table.jsx';
import Bill from '../modules/bill.jsx';
import BillPersonnel from '../modules/billPersonnel.jsx';
import BillPickup from '../modules/billPickup.jsx';
import Loading from '../modules/loading.jsx';

import { BsX } from 'react-icons/bs';
import { MdLocalShipping } from 'react-icons/md';
import { HiUserGroup } from 'react-icons/hi';


function arrayAlphebetize(array) {
  return array.sort((a, b) => a.localeCompare(b));
}

function removeDuplicatesFromArray(array) {  
  return array.filter((a, b) => array.indexOf(a) === b);
} 


export default function Form2({ notify }) {  

  const auth = useAuth();
  const refresh = useRefreshToken();
  const navigate = useNavigate();
  const childRef = useRef();
  //let location = useLocation();

  const axiosPrivate = useAxiosPrivate();

  const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  
  const list_of_clients = arrayAlphebetize(Object.keys(auth.auth.divisionMap));

  const [clientList, setClientList] = useState(list_of_clients);

  const [isLoading, setIsLoading] = useState(false);  
  const [billDatetime, setBillDatetime] = useState(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
  const [billDateString, setBillDateString] = useState(moment(new Date()).format('dddd, MMMM Do YYYY'));
  
  const [formData, setFormData] = useState({
    client: list_of_clients[0],
    destination: auth.auth.divisionMap[list_of_clients[0]].split(";")[0],
    pickedUp: "",
    pickedUpLocation:"",
    location: "",
    service: "",
    employee: "",
    clientRef: "",
  });

  const [orderType, setOrderType] = useState(1);
  const [personnelList, setPersonnelList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [billReference, setBillReference] = useState("");

  const [dataObject, setDataObject] = useState({});
  const [data, setData] = useState([]);
  const [step, setStep] = useState(1);

  const [tableDataEdits, setTableDataEdits] = useState({});

  const columns = [
    { 
        id: 'selected', 
        label: 'Select', 
        minWidth: 60,
        align: 'middle',
        format: (value) => value.toLocaleString('en-US'),
    },
    { 
        id: 'client', 
        label: 'Client', 
        minWidth: 120,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    { 
        id: 'destination', 
        label: 'Destination', 
        minWidth: 150,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'quantity',
        label: 'Quantity',
        maxWidth: 50,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'unit',
        label: 'Unit',
        minWidth: 100,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },        
    {
        id: 'weight',
        label: 'Weight',
        minWidth: 100,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
    {
      id: 'weightType',
      label: 'Weight Type',
      minWidth: 100,
      align: 'right',
      format: (value) => value.toFixed(2),
    },
    {
        id: 'supplier',
        label: 'Supplier',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'reference',
        label: 'Reference',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'editDescription',
        label: 'description',
        minWidth: 270,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    // {
    //     id: 'carrier',
    //     label: 'Carrier',
    //     minWidth: 170,
    //     align: 'left',
    //     format: (value) => value.toLocaleString('en-US'),
    // },
    // {
    //     id: 'waybill',
    //     label: 'Waybill',
    //     minWidth: 170,
    //     align: 'left',
    //     format: (value) => value.toLocaleString('en-US'),
    // },    
  ]; 

  const columnsOutgoing = [
    { 
        id: 'selected', 
        label: 'Select', 
        minWidth: 60,
        align: 'middle',
        format: (value) => value.toLocaleString('en-US'),
    },
    { 
        id: 'client', 
        label: 'Client', 
        minWidth: 120,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    { 
        id: 'to', 
        label: 'To', 
        minWidth: 150,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'quantity',
        label: 'Quantity',
        maxWidth: 50,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'unit',
        label: 'Unit',
        minWidth: 100,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },        
    {
        id: 'weight',
        label: 'Weight',
        minWidth: 100,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
    {
      id: 'weightType',
      label: 'Weight Type',
      minWidth: 100,
      align: 'right',
      format: (value) => value.toFixed(2),
    },
    {
        id: 'reference',
        label: 'Reference',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'editDescription',
        label: 'description',
        minWidth: 270,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    // {
    //     id: 'carrier',
    //     label: 'Carrier',
    //     minWidth: 170,
    //     align: 'left',
    //     format: (value) => value.toLocaleString('en-US'),
    // },
    // {
    //     id: 'waybill',
    //     label: 'Waybill',
    //     minWidth: 170,
    //     align: 'left',
    //     format: (value) => value.toLocaleString('en-US'),
    // },    
  ]; 

  useEffect(() => {
    //const page = location.pathname;
    console.log(auth);    
  }, []);

  const onChange = (e) => {
    console.log('aaaaaaaaaaaaaa')
    if (e.target.value.includes('"') || e.target.value.includes('`')) {}
    else {
        //if (e.target.name == 'client') {
        //    setFormData({ ...formData, client: e.target.value, destination: auth.auth.divisionMap[e.target.value].split(";")[0] })
        //} else {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        //}
    }
  }
  
  const transitionStep2 = () => {
    if (formData.service.length > 0) {
      setStep(2);
      if (orderType==1) fetchOrderData();
      if (orderType==2) setPersonnelList([{name:"",description:""}]);     
      if (orderType==3) setItemList([{quantity:"",weight:1,weightType:"LBS",unit:"",to:"",reference:"",description:""}]);   
      if (orderType==4) fetchOutgoingData();
      
    } else {
      notify("Please enter a service","warning");
    }
  }

  const transitionStep3 = () => {

    setBillReference(String(Math.round(new Date()/1000)))

    console.log("TABLE DATA EDITS" , tableDataEdits)   

    console.log("ITEM LIST", itemList);




    if(orderType==1 || orderType==4) {   
      if (Object.keys(tableDataEdits).length>0) {
        let newData = data.map((item) => {
          if (tableDataEdits[item.id]) {
            return {...item, ...tableDataEdits[item.id]};
          } else {
            return item;
          }
        });
        console.log(newData); 
        setData(newData);
        setStep(3);
      }

      else if (data.length>0) {      
        console.log(data);
        let selected = data.filter((item) => { if(item.selected==true) return item });
        console.log(selected);
        if (selected.length>0) {
          setStep(3);
        } else {
          notify("Please select at least one order","warning");          
        }
      } else {
        notify("No orders available.","warning");
      }
    } else if (orderType==2) {
      console.log(personnelList);
      if (personnelList.length>0) {
        setStep(3);
      } else {
        notify("No personnel available.","warning");
      }
    } else if (orderType==3) {
      // check if all of the data items need to have: description, quantity, reference, to, unit, weight, weightType,
      if (itemList.length>0) {
        for (let i=0; i<itemList.length; ++i) {
          if (itemList[i].description.length==0 || itemList[i].quantity.length==0 || itemList[i].reference.length==0 || itemList[i].to.length==0 || itemList[i].unit.length==0 || itemList[i].weight.length==0 || itemList[i].weightType.length==0) {
            notify("Please fill out all fields for each item.","warning");            
            return;
          }
        }       
          
        setStep(3);
      } else {
        notify("No items selected.","warning");
      }
    }

  }

  const onSelectClient = (e) => {
    setFormData({...formData, client:e.target.value, destination: auth.auth.divisionMap[e.target.value].split(";")[0]});    
  }

  const onSelectOrder = (row) => {
    console.log("SELECTED ROW ---> ")
    console.log(row);
    let newData = data.map((item) => {
        if (item.id==row.id) {
            item.selected = !item.selected;
        }
        return item;
    })
    setData(newData);
  }

  const fetchOrderData = async () => {
    setIsLoading(true);

    const params = {
      client: formData.client,
    }
    const response = await axiosPrivate.get(`/orders/destination/${formData.destination.replace("/","%2F")}`, {params: params}, { method: "GET", credentials: "include" });

    let newData = {};    

    //let total_weight = 0;      
    //let total_quantity = 0;          

    for (let i=0; i<response.data.length; ++i) {
        let id = response.data[i].id  
        newData[id] = response.data[i];
        newData[id].selected = true;

        //let weight = newData[i].weight.split(' ')
        //let weight_val = Number(weight[0])
        //let weight_label = weight[1]
        //if (weight_label=="LBS") {
        //    total_weight = total_weight + weight_val;
        //}
        //else {
        //    total_weight = total_weight + (weight_val*2.20);
        //}
        //total_quantity = total_quantity + newData[i].quantity;
    }
   
    setDataObject(newData);
    setData(Object.values(newData));
    setIsLoading(false);
  }

  const fetchOutgoingData = async () => {
    setIsLoading(true);
    const params = {
      client: formData.client,
    }
    const response = await axiosPrivate.get(`/outgoing/client`, {params: params}, { method: "GET", credentials: "include" });
        
    let newData = {}; 

    //let total_weight = 0;      
    //let total_quantity = 0;              

    for (let i=0; i<response.data.length; ++i) {

        let id = response.data[i].id  
        newData[id] = response.data[i];
        newData[id].selected = true;
        newData[id].destination = formData.destination;
        //newData[i].description
        
        //let weight = newData[i].weight.split(' ')
        //let weight_val = Number(weight[0])
        //let weight_label = weight[1]
        //if (weight_label=="LBS") {
        //    total_weight = total_weight + weight_val;
        //}
        //else {
        //    total_weight = total_weight + (weight_val*2.20);
        //}
        //total_quantity = total_quantity + newData[i].quantity;
    }

    setDataObject(newData);     
    setData(Object.values(newData));
    setIsLoading(false);
  }  

  const fetchInventoryData = async () => {
    setIsLoading(true);
    const params = {
      client: formData.client,
    }

    const response = await axiosPrivate.get(`/inventory/destination/${formData.destination}`, {params: params}, { method: "GET", credentials: "include" });

    let newData = response.data    

    let total_weight = 0;      
    let total_quantity = 0;          

    for (let i=0; i<newData.length; ++i) {
        newData[i].selected = true;        
        //let weight = newData[i].weight.split(' ')
        //let weight_val = Number(weight[0])
        //let weight_label = weight[1]
        //if (weight_label=="LBS") {
        //    total_weight = total_weight + weight_val;
        //}
        //else {
        //    total_weight = total_weight + (weight_val*2.20);
        //}
        total_quantity = total_quantity + newData[i].quantity;
    }
   
    setData(newData);
    setIsLoading(false);
  }

  const confirmWorkOrder = async (event) => {

    setIsLoading(true);

    let datetime = moment().format('YYYY-MM-DD HH:mm:ss');
    let json_order_obj = {};        
    let update_entry;    
    let batch_query = ``;
        
    for (let i=0; i<data.length; ++i) {
      if (data[i].selected) {
          let item = data[i];
          update_entry = `UPDATE \`master_ldf\`.\`new_orders\` SET \`status\`='Pending', \`checked\`='${datetime}' WHERE \`id\`='${item.id}';`;
          batch_query = update_entry + "#" + batch_query;
          json_order_obj[item.id] = item
      }
    }

    const body = {
      id: billReference,
      datetime: billDatetime,
      client: formData.client,
      destination: formData.destination,
      location: formData.location,
      clientRef: formData.clientRef,
      service: formData.service,
      employee: formData.employee,
      reference: formData.clientReference,
      ordersObject: JSON.stringify(json_order_obj).replace(/'/g,"''"),
      batchQuery: batch_query,
      description: `Delivery to ${formData.destination} at ${formData.location}.`,
      type: 1,
      note: ``,
    }

    console.log(body)

    const response = await axiosPrivate.post(`/jobs`, body, { method: "POST", credentials: "include" });
    console.log(response);
    
    setIsLoading(false); 
    setFormData({
      ...formData,
      location: "",
      service: "",
      employee: "",
      clientRef: "",
    })
    setStep(1);
    setBillReference(String(Math.round(new Date()/1000)));
    setBillDatetime(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
    notify("Work Order Created", "success");
    
  }

  const confirmOutgoingWorkOrder = async (event) => {

    setIsLoading(true);

    let datetime = moment().format('YYYY-MM-DD HH:mm:ss');
    let json_order_obj = {};        
    let update_entry;    
    let batch_query = ``;
        
    for (let i=0; i<data.length; ++i) {
      if (data[i].selected) {
          let item = data[i];
          update_entry = `UPDATE \`master_ldf\`.\`outgoing\` SET \`status\`='Pending', \`checked\`='${datetime}' WHERE \`id\`='${item.id}';`;
          batch_query = update_entry + "#" + batch_query;
          json_order_obj[item.id] = item
      }
    }

    const body = {
      id: billReference,
      datetime: datetime,
      client: formData.client,
      destination: formData.destination,
      location: formData.location,
      service: formData.service,
      clientRef: formData.clientRef,
      employee: formData.employee,
      reference: formData.clientReference,
      ordersObject: JSON.stringify(json_order_obj).replace(/'/g,"''"),
      batchQuery: batch_query,
      description: `Delivery to ${formData.destination} at ${formData.location}.`,
      type: 1,
      note: ``,
    }

    const response = await axiosPrivate.post(`/jobs/outgoing`, body, { method: "POST", credentials: "include" });
    console.log(response);
    
    setIsLoading(false); 
    setFormData({
      ...formData,
      location: "",
      service: "",
      employee: "",
      clientRef: "",
    })
    setStep(1);
    setBillReference(String(Math.round(new Date()/1000)));
    setBillDatetime(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
    notify("Work Order Created", "success");
    
  }

  const confirmPersonnelWorkOrder = async (event) => {

    //setIsLoading(true);

    let datetime = moment().format('YYYY-MM-DD HH:mm:ss');
    let update_entry;    
    let batch_query = ``;

    const body = {
      id: billReference,
      datetime: billDatetime,
      client: formData.client,
      destination: formData.destination,
      location: formData.location,
      service: formData.service,
      clientRef: formData.clientRef,
      reference: formData.clientReference,
      employee: formData. employee,
      personnelObject: JSON.stringify(personnelList).replace(/'/g,"''"),
      batchQuery: batch_query,
      description: `Transportation for personnel.`,
      type: 2,
      note: ``,
    }

    const response = await axiosPrivate.post(`/jobs/personnel`, body, { method: "POST", credentials: "include" });
    console.log(response);
    
    setIsLoading(false); 
    setStep(1);
    setFormData({
      location: "",
      //service: "Deliver to destination",
      employee: "",
      clientRef: "",
    })
    setBillReference(String(Math.round(new Date()/1000)));
    setBillDatetime(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
    notify("Work Order Created", "success");
  }

  const confirmPickupWorkOrder = async (event) => {

    setIsLoading(true);

    let datetime = moment().format('YYYY-MM-DD HH:mm:ss');
    let update_entry;    
    let batch_query = ``;

    const body = {
      id: billReference,
      datetime: billDatetime,
      client: formData.client,
      destination: formData.destination,
      location: formData.location,
      pickedUp: formData.pickedUp,
      pickedUpLocation: formData.pickedUpLocation,
      service: formData.service,
      reference: formData.id,
      clientRef: formData.clientRef,
      employee: formData. employee,
      itemObject: JSON.stringify(itemList).replace(/'/g,"''"),
      batchQuery: batch_query,
      description: `Pick up from ${formData.pickedUp} at ${formData.pickedUpLocation}.`,
      type: 3,
      note: ``,
    }

    const response = await axiosPrivate.post(`/jobs/pickup`, body, { method: "POST", credentials: "include" });
    console.log(response);
    
    setIsLoading(false); 
    setStep(1);
    setFormData({
      destination: "LOCKS AND DOCKS", 
      location:"4944 Avenue Dunn, Montreal, QC",
      //location: "  ",
      //service: "Service to pick up at",
      employee: "",
      clientRef:"",
    })
    setBillReference(String(Math.round(new Date()/1000)));
    setBillDatetime(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
    notify("Work Order Created", "success");
  }

  const changeOrderType = (value) => {
    setOrderType(value);
    //if (value==1) { setDestination(""); }
    //if (value!==1) { setFormData({...formData, destination: ""}); }
    if (value==3) { 
      setFormData({ 
        ...formData, 
        destination: "LOCKS AND DOCKS", 
        location:"4944 Avenue Dunn, Montreal, QC",
        service: "Service to pick up at"
      }); 
    }
    else {
      setFormData({
        ...formData,
        client: list_of_clients[0],
        destination: auth.auth.divisionMap[list_of_clients[0]].split(";")[0],
        service: "",
      })
    }
  }

  const addNewPersonnel = () => {
    setPersonnelList([...personnelList, {name:"",description:""}])
  }

  const removePersonnel = (index) => {
    const newPersonnelList = personnelList.filter((item, i) => i !== index)
    setPersonnelList(newPersonnelList)
  }

  const onChangePersonnel = (index, e) => {
    let newPersonnelList = personnelList.map((item, i) => {
      if (i === index) {
        if (e.target.value.includes('"') || e.target.value.includes('`')) {}
        else {
          let newItem = item;
          newItem[e.target.name] = e.target.value
          return newItem
        }
      } else {
        return item
      }
    })
    setPersonnelList(newPersonnelList)
  }

  const addNewItem = () => {
    setItemList([...itemList, {quantity:"",weight:1,weightType:"LBS",unit:"",to:"",reference:"",description:""}])
  }

  const removeItem = (index) => {
    const newItemList = itemList.filter((item, i) => i !== index)
    setItemList(newItemList)
  }

  const onChangeItem = (index, e) => {
    let newItemList = itemList.map((item, i) => {
      if (i === index) {
        if (e.target.value.includes('"') || e.target.value.includes('`')) {
          return item
        }
        else {
          let newItem = item;
          newItem[e.target.name] = e.target.value
          return newItem
        }
      } else {
        return item
      }
    })
    setItemList(newItemList)
  }

  const onEditQuantity = (row, value) => {
    
      //let newData = data.map((item, i) => {
      //  if (item.id === row.id) {
      //    let newItem = item;
      //    newItem.quantity = value
      //    return newItem
      //  } else {
      //    return item
      //  }
      //})
      //setData(newData)
  }

  const onEditTableData = (id, column, value) => {
    //console.log(id, column, value);
    //console.log(dataObject);
    if (column=='editDescription') { column = 'description'; }
    let newObject = dataObject[id]
    //console.log("NEW OBJECT" , newObject);
    newObject[column] = value
    let newDataObject = {...dataObject, [id]: newObject}
    //console.log(newDataObject);
    setDataObject(newDataObject)
    setData(Object.values(newDataObject))
  }


  return (
    <>    
      <Layout>
        
        {step==1?
        <div className="form_header w-100 flex h-12 mt-6 mb-10">  
            
          <div class="flex ml-6">
            {orderType==1? 
              <div>
                <h3 class="flex">Create Work Order for Merchandise</h3>
                <p class="">Fetches entries from the orders table filtered by setting the "Destination" field below.</p>
              </div>
            :orderType==2?
              <div>
                <h3 class="flex">Create Work Order for Personnel</h3>
                {/* <p class="">Manually enter Personnel</p> */}
              </div>
            :orderType==3?
              <div>
                <h3 class="flex">Create Work Order for Pickup</h3>
                {/* <p class="">Calls entries from the orders table filtered by setting the "Destination" field below.</p> */}
              </div>
            :orderType==4?
              <div>
                <h3 class="flex">Create Work Order for Outgoing</h3>
                <p class="">Fetches entries from the outgoing table by "Client". Returns all orders for selected client.</p>
              </div>
            :null}
          </div>
             
              


          <div class="ml-auto mr-6 w-1/3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                Select Work Order Type
            </label>
            <select value={orderType} onChange={(e) =>changeOrderType(e.target.value)} class="shadow block w-full bg-white text-gray-700 border border-red-500 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="client" type="text">
              <option value={1} selected={orderType==1?true:false}>Merchandise</option> 
              <option value={2} selected={orderType==2?true:false}>Personnel</option> 
              <option value={3} selected={orderType==3?true:false}>Pickup</option> 
              <option value={4} selected={orderType==4?true:false}>Outgoing</option>
              {/* <option value={4} selected={orderType==4?true:false}>Custom</option>  */}
            </select>
              
          </div>
        </div>:null}

        {step==1?        
        <>
          {/* {orderType==1 || orderType==3?} */}
            <div class="flex flex-wrap -mx-3 px-6 py-6">
              <div class="w-full md:w-1/2 px-3 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                    Client *
                </label>
                
                {/* {orderType!=3? */}
                <select name="client" onChange={(e) => onSelectClient(e)} class="shadow block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="client" type="text">
                    {auth.auth?
                        clientList.map(d => {
                            if(d!=='admin') { 
                              if(d===formData.client) {  
                                return <option selected value={d}>{d}</option> 
                              }
                              else {
                                return <option value={d}>{d}</option> 
                              }                          
                            }
                        })
                    :
                    <option value="default">default</option>
                    }
                </select>
                {/* :
                <input value={formData.client} placeholder="Enter a client..." onChange={(e) => onChange(e)} name="client" autoComplete="off" class=" shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="client" type="text" />
                }*/}
              </div>

              <div class="w-full md:w-1/2 px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                  {/* {orderType!=3?
                    "Destination"
                  : "Picked Up From"
                  } */}
                  Destination
              </label>

              {orderType==1?
              <select onChange={(e) => onChange(e)} name="destination" class="shadow block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="destination" type="text" >
                  {auth.auth?
                      arrayAlphebetize(auth.auth.divisionMap[formData.client].split(";")).map(d => {
                        if (d==formData.destination) {
                          return <option selected value={d}>{d}</option>
                        }
                        else {                        
                          return <option value={d}>{d}</option>
                        }
                      })
                  :
                  <option value="default">default</option>
                  }
              </select>
              :null}
              
              {orderType==2?
              <input maxLength={125} value={formData.destination} placeholder="Enter a destination..." onChange={(e) => onChange(e)} name="destination" autoComplete="off" class=" shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="destination" type="text" />
              :null}

              {orderType==3?
              <input maxLength={125} value={formData.destination} placeholder="Enter a destination..." onChange={(e) => onChange(e)} name="destination" autoComplete="off" class=" shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="destination" type="text" />
              :null}

              {orderType==4?
              <input maxLength={125} value={formData.destination} placeholder="Enter a destination..." onChange={(e) => onChange(e)} name="destination" autoComplete="off" class=" shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="destination" type="text" />
              :null}


              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6 px-6 py-6">
              

              <div class="w-full md:w-1/2 px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                    Service
                </label>
                <input autoComplete={false} value={formData.service} name="service" maxLength={120} onChange={(e) => onChange(e)} class="shadow block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="service" type="text" placeholder="Service being provided." />
              </div> 

              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                    Location
                </label>
                <input autoComplete={false} value={formData.location} name="location" maxLength={45} onChange={(e) => onChange(e)} class="shadow block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="location" type="text" 
                  placeholder="Location of delivery destination."
                />
                {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
              </div>

              {orderType==3?
              <>
                <div class="w-full md:w-1/2 px-3 pb-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                      Picked up from
                  </label>
                  <input autoComplete={false} value={formData.pickedUp} name="pickedUp" maxLength={120} onChange={(e) => onChange(e)} class="shadow block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="service" type="text" placeholder="Business/Organization picked up from." />
                </div> 

                <div class="w-full md:w-1/2 px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                    Picked up location
                </label>
                <input autoComplete={false} value={formData.pickedUpLocation} name="pickedUpLocation" maxLength={120} onChange={(e) => onChange(e)} class="shadow block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="service" type="text" placeholder="Location picked up from." />
                </div>
              </>
              :null}

              <div class="w-full md:w-1/2 px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                    Client Reference
                </label>
                <input autoComplete={false} value={formData.clientRef} name="clientRef" maxLength={20} onChange={(e) => onChange(e)} class="shadow block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="service" type="text" placeholder="Reference number for client." />
              </div> 

              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                    Employee
                </label>
                <input autoComplete={false} value={formData.employee} name="employee" maxLength={50} onChange={(e) => onChange(e)} class="shadow block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="service" type="text" placeholder="Employee name" />                
              </div>
       
            </div>

            {orderType==4
            ?
            <div>

            </div>
            :null}

            <div class="w-full flex">
              {!isLoading?
              <div onClick={transitionStep2} class="w-28 h-10 flex bg-blue-400 hover:bg-blue-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                  <p class="flex m-auto">Next</p>
              </div> 
              :   
              <div class="w-28 h-10 flex bg-blue-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                  <span class="flex m-auto"><Loading type={1} /></span>
              </div> 
              }
            </div>
          
        </>
        :step==2?

          orderType==4
          ? // WORK ORDER FOR OUTGOING
          <div class="h-100 w-100">
            <div class="w-100 h-18 pb-2">
              <button class="text-xl font-bold text-blue-600" onClick={() => setStep(1)}>← Back</button>
              <div class="flex w-100">
                <h3 class="text-black">
                {"Select "} 
                {orderType==1?"Merchandise"
                :orderType==2?"Personnel"
                :orderType==3?"Outgoing"
                :orderType==4?"Pickup"
                :null}
                {" Orders"}
                </h3>   
                <div class="flex ml-auto mt-auto">
                    {!isLoading?
                    <div onClick={transitionStep3} class="w-28 h-10 flex bg-blue-400 hover:bg-blue-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                        <p class="flex m-auto">Generate</p>
                    </div> 
                    :   
                    <div class="w-28 h-10 flex bg-blue-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                        <span class="flex m-auto"><Loading type={1} /></span>
                    </div> 
                    }
                </div>
              
              </div> 
            </div>    
            <div class="h-[calc(100%-5em)] w-100">
              <Table 
                loading={isLoading}
                editQuantities={true}
                data={data} 
                columns={columnsOutgoing}
                selectData={onSelectOrder}
                onEditQuantity={onEditQuantity}
                editTableData={onEditTableData}
              />
            </div>
          </div> 

          :orderType==2
          ? // WORK ORDER FOR PERSONNEL
          <div class="h-100 w-100">
            <div class="w-100 h-18 pb-2">
              <button class="text-xl font-bold text-blue-600" onClick={() => setStep(1)}>← Back</button>
              <div class="flex w-100">
                <h3 class="text-black">
                    Add Personnel List
                </h3>                            
                <div class="flex ml-auto mt-auto">
                    {!isLoading?
                    <div onClick={transitionStep3} class="w-28 h-10 flex bg-blue-400 hover:bg-blue-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                        <p class="flex m-auto">Generate</p>
                    </div> 
                    :   
                    <div class="w-28 h-10 flex bg-blue-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                        <span class="flex m-auto"><Loading type={1} /></span>
                    </div> 
                    }
                </div>
              </div> 
            </div>

            <div class="flex w-100 pb-3">            
              <button onClick={addNewPersonnel} class="flex bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded " type="button">Add New</button>
            </div>

            <div class="h-[calc(100%-5em)] w-100 border-t-2 border-black">

              <div class="w-100 h-3/4 p-1 bg-gray-400 overflow-scroll">
                {personnelList.map((personnel, index) => {
                  console.log(personnelList);
                  return (
                    <div class="w-100 flex h-10 my-2">
                      <p class="w-6 mx-1 h-100 p-2 bg-white rounded">{index+1}</p>
                      <div class="w-100 mx-auto">
                        <input onChange={(e) => onChangePersonnel(index,e)} name="name" placeholder="Personnel name" class="w-2/12 p-2 mb-2 bg-white rounded-md border border-black" value={personnel.name}></input>
                        <input onChange={(e) => onChangePersonnel(index,e)} name="description" placeholder="Additional details" class="w-9/12 p-2 mb-2 ml-1 bg-white rounded-md border border-black" value={personnel.description}></input>
                      </div>
                      <button onClick={() => {removePersonnel(index)}} class="p-2 my-1 hover:bg-red-500 bg-red-400 rounded mx-1">
                        <BsX color="white"/>
                      </button>
                    </div>     
                  )}
                )}
              </div>                                  
            
            </div>

          </div> 

          :orderType==3
          ? // WORK ORDER FOR PICKUP
          <div class="h-100 w-100">
            <div class="w-100 h-18 pb-2">
              <button class="text-xl font-bold text-blue-600" onClick={() => setStep(1)}>← Back</button>
              <div class="flex w-100">
                <h3 class="text-black">
                    Add List Of Items
                </h3>                            
                <div class="flex ml-auto mt-auto">
                    {!isLoading?
                    <div onClick={transitionStep3} class="w-28 h-10 flex bg-blue-400 hover:bg-blue-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                        <p class="flex m-auto">Generate</p>
                    </div> 
                    :   
                    <div class="w-28 h-10 flex bg-blue-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                        <span class="flex m-auto"><Loading type={1} /></span>
                    </div> 
                    }
                </div>
              </div> 
            </div>

            <div class="flex w-100 pb-3">            
              <button onClick={addNewItem} class="flex bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded " type="button">Add New</button>
            </div>

            <div class="h-[calc(100%-5em)] w-100 border-t-2 border-black">

              <div class="w-100 h-3/4 p-1 bg-gray-200 overflow-scroll">
                {itemList.map((item, index) => {
                  console.log(itemList);
                  return (
                    <div class="w-100 flex my-1 mb-2 p-2 bg-white rounded relative">
                      {/* <p class="w-6 x-1 h-100 p-2 mt-2 bg-transparent text-black text-xl rounded">
                        {index+1}
                      </p> */}

                      <div class="flex flex-grow -mx-3 mb-2 ml-2">
                        <div class="w-full md:w-32 px-1">
                          <label for="quantity" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1">
                              Quantity
                          </label>
                          <input maxLength={9} value={item.quantity} min={1} step={1} name="quantity" autoComplete="off" onChange={(e) => onChangeItem(index, e)} class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="quantity" type="number" />
                        </div>

                        <div class="w-full md:w-48 px-1">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" for="unit">
                              Unit
                          </label>
                          <input maxLength={60} value={item.unit} name="unit" autoComplete="off" onChange={(e) => onChangeItem(index, e)} class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="unit" type="text" />
                        </div>

                        <div class="w-full md:w-48 px-1">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" for="weight">
                              Weight    ( <button name="weightType" onClick={(e) => onChangeItem(index, e)} value={"LBS"} class="" style={item.weightType=="LBS"?{color:"blue"}:{color:"black"}}>LBS</button> / <button onClick={(e) => onChangeItem(index, e)} value={"KGS"} name="weightType" class="" style={item.weightType=="KGS"?{color:"blue"}:{color:"black"}}>KGS</button> )
                          </label>
                          <input maxLength={9} value={item.weight} min={0.1} step={0.1} autoComplete="off" name="weight" onChange={(e) => onChangeItem(index, e)} class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" id="weight" />
                        </div>  

                        <div class="w-full md:w-48 px-1">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" for="reference">
                              Reference
                          </label>
                          <input maxLength={60} value={item.reference} name="reference" autoComplete="off" onChange={(e) => onChangeItem(index, e)} class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reference" type="text" />
                        </div>

                        <div class="w-full md:w-48 px-1">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" for="to">
                              To
                          </label>
                          <input maxLength={60} value={item.to} name="to" autoComplete="off" onChange={(e) => onChangeItem(index, e)} class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="to" type="text" />
                        </div> 

                        <div class="w-full md:w-4/6 px-1 mr-4">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" for="description">
                              Description
                          </label>
                          <input onChange={(e) => onChangeItem(index,e)} name="description" placeholder="Additional details" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" value={item.description}></input>
                        </div>
                        
                      </div>

                      <button 
                          onClick={() => {removeItem(index)}} 
                          class="absolute top-1 right-0 p-1 hover:bg-red-500 bg-red-400 rounded ">
                          <BsX color="white"/>
                        </button>
                      
                    </div>     
                  )}
                )}
              </div>                               
            
            </div>

          </div> 

          : // WORK ORDER FOR MERCHANDISE
          <div class="h-100 w-100">
            <div class="w-100 h-18 pb-2">
              <button class="text-xl font-bold text-blue-600" onClick={() => setStep(1)}>← Back</button>
              <div class="flex w-100">
                <h3 class="text-black">
                {"Select "} 
                {orderType==1?"Merchandise"
                :orderType==2?"Outgoing"
                :orderType==3?"Personnel"
                :null}
                {" Orders"}
                </h3>   
                <div class="flex ml-auto mt-auto">
                    {!isLoading?
                    <div onClick={transitionStep3} class="w-28 h-10 flex bg-blue-400 hover:bg-blue-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                        <p class="flex m-auto">Generate</p>
                    </div> 
                    :   
                    <div class="w-28 h-10 flex bg-blue-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                        <span class="flex m-auto"><Loading type={1} /></span>
                    </div> 
                    }
                </div>
              
              </div> 
            </div>    
            <div class="h-[calc(100%-5em)] w-100">
              <Table 
                loading={isLoading}
                editQuantities={true}
                data={data} 
                columns={columns}
                selectData={onSelectOrder}
                onEditQuantity={onEditQuantity}
                editTableData={onEditTableData}
              />
            </div>
          </div>    
                

        :step==3?
        <div class="h-100 w-100">
          <div class="w-100 h-18 pb-2">
            <button class="text-xl font-bold text-blue-600" onClick={() => setStep(2)}>← Back</button>
            <div class="flex w-100">
              <h3 class="text-black">Generated Work Order</h3>                            
              <div class="flex ml-auto mt-auto">
                  {!isLoading?
                  <div 
                    onClick={
                         orderType==1?confirmWorkOrder
                        :orderType==2?confirmPersonnelWorkOrder
                        :orderType==3?confirmPickupWorkOrder
                        :orderType==4?confirmOutgoingWorkOrder:null} class="w-28 h-10 flex bg-blue-400 hover:bg-blue-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                      <p class="flex m-auto">Confirm</p>
                  </div> 
                  :   
                  <div class="w-28 h-10 flex bg-blue-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                      <span class="flex m-auto"><Loading type={1} /></span>
                  </div> 
                  }
              </div> 
            </div>            
          </div>

          {orderType==1?                  
            <div class="h-[calc(100%-5em)] w-100">
              <Bill 
                date={billDateString}
                reference={billReference}
                orders={data} 
                client={formData.client}
                destination={formData.destination} 
                location={formData.location}
                service={formData.service}
                clientReference={formData.clientRef}
                employee={formData.employee}
              />
            </div>
          :orderType==2?                  
          <div class="h-[calc(100%-5em)] w-100">
            <BillPersonnel 
                date={billDateString}
                reference={billReference}
                orders={personnelList} 
                client={formData.client}
                destination={formData.destination} 
                location={formData.location}
                service={formData.service}
                clientReference={formData.clientRef}
                employee={formData.employee}
              />
          </div>
          :orderType==3?
            <div class="h-[calc(100%-5em)] w-100">
              <BillPickup
                date={billDateString}
                reference={billReference}
                orders={itemList} 
                client={formData.client}
                destination={formData.destination} 
                pickedUp={formData.pickedUp}
                pickedUpLocation={formData.pickedUpLocation}
                location={formData.location}
                service={formData.service}
                clientReference={formData.clientRef}
                employee={formData.employee}
              />
            </div>
          :orderType==4?          
            <div class="h-[calc(100%-5em)] w-100">
              <Bill 
                date={billDateString}
                reference={billReference}
                orders={data} 
                client={formData.client}
                destination={formData.destination} 
                location={formData.location}
                service={formData.service}
                clientReference={formData.clientRef}
                employee={formData.employee}
              />
            </div>
          :null}               

        </div>          
        :null}

      </Layout>
                
    </>
  );
}


