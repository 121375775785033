
import React, { useState, useEffect } from 'react';

import logo from '../../static/assets/images/image832_white.png';
//import logo from '../../static/assets/images/logo_big_gray.png';




export default function Navbar() {
  
    return (

        <div class="bg-black w-100 z-20">          
          <div class="px-12 flex items-center justify-between py-3">

            <div class="flex items-center space-x-6">
              <a href="/" class="flex"><img src={logo} height={100} width={100} alt="logo"></img></a>
              <div class="w-12 text-white pl-6">|</div>
              <a href="/transport" class="navbar_link hover:text-gray-200 no-underline font-medium tracking-wide cursor-pointer">Transport</a>
              <a href="/delivery" class="navbar_link hover:text-gray-200 no-underline font-medium tracking-wide cursor-pointer">Delivery</a>
              <a href="/warehousing" class="navbar_link hover:text-gray-200 no-underline font-medium tracking-wide cursor-pointer">Warehousing</a>
              <a href="/logistics" class="navbar_link hover:text-gray-200 no-underline font-medium tracking-wide cursor-pointer">Logistics</a>
              <a href="/about" class="navbar_link hover:text-gray-200 no-underline font-medium tracking-wide cursor-pointer">About</a>
              <a href="/contact" class="navbar_link hover:text-gray-200 no-underline font-medium tracking-wide cursor-pointer">Contact</a>                
            </div>

            <div  class="flex items-center space-x-8 ">
            </div>

            <div class="flex items-center space-x-6">
              <a href="/login" class="navbar_link text-gray-700 hover:text-gray-200 hover:no-underline font-medium tracking-wide no-underline">Sign In</a>
              <a href="/info" class="bg-gray-100 text-black hover:bg-gray-300 hover:text-black font-medium no-underline tracking-wide py-2 px-4 rounded transition duration-300 ease-in-out">Get Started</a>
              {/* <a href="#" class="text-gray-300 hover:text-white font-medium tracking-wide no-underline">English</a> */}
            </div>
          </div>
        </div> 

    )

}