import React from "react"
import Sidebar from "../modules/sidebar"


const Layout = ({ children }) => {
    
    return (

        <main class="flex h-full w-full overflow-hidden">
            <Sidebar />
            <div className="overflow-scroll px-6 pb-0 pt-2 flex-2 ml-16 h-100 w-[calc(100%-4em)] justify-center bg-gray-100">
                {children}
            </div>
        </main>        

    )
}

export default Layout