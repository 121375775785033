import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import useLogout from '../hooks/useLogout';

import useAuth from '../hooks/useAuth';
import useRefreshToken from '../hooks/useRefreshToken';

import Sidebar from '../modules/sidebar';
import Table from '../modules/table';

import Layout from '../context/layoutPortal.jsx';



export default function Status({ notify }) {

  const auth = useAuth();
  const refresh = useRefreshToken();
  const navigate = useNavigate();
  const childRef = useRef();
  //let location = useLocation();

  useEffect(() => {
    //const page = location.pathname;

    //console.log(auth);
    
  }, []);



  return (

    // MODULES

    // sidebar 
    // plot
    // miniTable

    <>    

      <Layout>

        Status
                
      </Layout>
                
    </>
  );
}