import React, { useRef, useEffect, useState } from 'react';

import Popup from '../context/layoutPopup.jsx';
import Loading from './loading.jsx';

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';

import { BsX } from 'react-icons/bs';


function arrayAlphebetize(array) {
    return array.sort((a, b) => a.localeCompare(b));
}
  
function removeDuplicatesFromArray(array) {  
    return array.filter((a, b) => array.indexOf(a) === b);
} 


export default function MenuEditInventory({ data, divisionMap, onToggleDisplay, updateParent, notify}) {

    const axiosPrivate = useAxiosPrivate();
    const auth = useAuth();

    const list_of_clients = arrayAlphebetize(removeDuplicatesFromArray(Object.keys(divisionMap)));

    const [isLoading, setIsLoading] = useState(false);
    const [newEditData, setNewEditData] = useState(JSON.parse(JSON.stringify(data)));
    const [isSelected, setIsSelected] = useState(false);     
    const [selectedFile, setSelectedFile] = useState(null);
    const [displayConfirmationPopup, setDisplayConfirmationPopup] = useState(false);
    
    //useEffect(() => {
    //   
    //}, [data]);    

    const preFlightChecks = () => {
        if (newEditData.owner == "") {
            notify("Please select an owner", "error");
            return false;
        }
        if (newEditData.weight == "") {
            notify("Please enter a weight", "error");
            return false;
        }
        if (newEditData.quantity == "") {
            notify("Please enter a quantity", "error");
            return false;
        }
        return true;
    }

    const submitUpdateOrder = async () => {

        const checked = preFlightChecks();
        if (!checked) return;

        try{
            setIsLoading(true);
            const config = { headers: { 'Content-Type': 'application/json' } };
            const body = JSON.stringify(newEditData)
                    
            const fileData = new FormData()            
            fileData.append('name', newEditData.id);
            fileData.append('data', body)
            fileData.append('new_file_name','new_name_here.pdf')       
            fileData.append('file', selectedFile)

            const response = await axiosPrivate.post(`/inventory/update`, fileData, {headers: { 'Content-Type': 'multipart/form-data' }});
            console.log(response.data);
            await updateParent();
            onToggleDisplay(false)
            setIsLoading(false); 
            notify("Inventory item updated successfully", "success");     
        } catch (error) {
            console.error('Error updating order:', error);
            onToggleDisplay(false)
            setIsLoading(false);
            notify('Error updating order', 'error');
        }        
    }
        
    const submitDeleteOrder = async () => {
        try {
            setIsLoading(true);
            const config = { headers: { 'Content-Type': 'application/json' } };
            const body = JSON.stringify(newEditData)
            const response = await axiosPrivate.post(`/inventory/delete`, body, { method: "POST", credentials: "include" });
            //console.log(response.data);
            await updateParent();
            setDisplayConfirmationPopup(false);
            onToggleDisplay(false)
            setIsLoading(false);
            notify("Inventory item deleted successfully", "success");
        } catch (error) {
            console.error('Error deleting order:', error);
            setDisplayConfirmationPopup(false);
            onToggleDisplay(false)
            setIsLoading(false);
            notify('Error updating order', 'error');
        }
    }

    const uploadFile = (event) => {
        setSelectedFile(event.target.files[0 ]);
        setIsSelected(true);
    };

    const onChange = (e) => {    
        if (e.target.value.includes('"') || e.target.value.includes('`')) {}
        else {
            setNewEditData({...newEditData, [e.target.name]: e.target.value});
        }
    }

        
    return (

        <Popup>

            {displayConfirmationPopup? // create a centered popup to confirm deletion
                <div id="popupMenu" class="absolute z-20 mx-auto my-auto inset-0 overflow-hidden h-1/4 p-2 w-1/2  bg-gray-500 text-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
                    <div class="bg-gray-500 text-white sm:p-6 h-full w-100 flex">

                            <div class="flex">
                                <div class="ml-6">
                                    <h3 class="">Delete Inventory Item #{data.id}</h3>
                                    <p class="text-white">Are you sure you want to delete this inventory item?</p>
                                </div>
                            </div>
                            <div class="flex ml-auto mt-auto">
                                <button onClick={isLoading?null:() => setDisplayConfirmationPopup(false)} class="bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded mr-2">Cancel</button>                                      
                                <div class="">
                                    {!isLoading?
                                    <div onClick={submitDeleteOrder} class="w-28 h-10 flex bg-red-400 hover:bg-red-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                                        <p class="flex m-auto">Confirm</p>
                                    </div> 
                                    :   
                                    <div class="w-28 h-10 flex bg-red-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span>
                                    </div> 
                                    }
                                </div> 

                            </div>

                    </div>
                </div>                                                    
            :null}

            <div class="flex absolute top-5 right-5">
                <button onClick={() => onToggleDisplay(false)} class="h-10 w-10 bg-gray-200 hover:bg-gray-300 rounded-full flex m-2">
                    <BsX class="m-auto" size={25}/>
                </button>
            </div>

            <div id="innerEditMenu" class="h-3/4 pt-3 pb-0 w-3/4 inline-block bg-gray-200 rounded-lg text-left overflow-scroll shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">            

                <div class="flex pb-4">
                    <h5 class="ml-6">Edit Inventory Item</h5>
                    {/* <button onClick={() => onToggleDisplay(false)} class="flex ml-auto hover:bg-gray-200"><FiX/></button> */}
                </div>  

                <div class="flex flex-wrap -mx-3 mb-2 px-6">

                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                        Owner *
                    </label>
                    <select name="owner" onChange={(e) => onChange(e)} class="shadow block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="owner" type="text">
                        <option value="Locks and Docks" selected={newEditData.owner=="Locks and Docks"?true:false}>Locks and Docks</option>
                        <option disabled>---------------</option>
                        {auth.auth?
                        list_of_clients.map(d => {
                            return <option value={d} selected={d==newEditData.owner?true:false}>{d}</option> 
                        })
                    :
                    <option value="default">default</option>
                    }
                    </select></div>

                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="description">
                        Description
                    </label>
                    <input value={newEditData.description} onChange={(e) => onChange(e)} name="description" autoComplete="off" placeholder="Item description..." class=" shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="description" type="text" />                    </div>

                </div>                  


                <div class="flex flex-wrap -mx-3 mb-2 px-6">
                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                        Reference
                    </label>
                    <input defaultValue={newEditData.reference} name="reference" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reference" type="text" placeholder="" />
                    </div>

                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                        Supplier
                    </label>
                    <input defaultValue={newEditData.supplier} name="supplier" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="supplier" type="text" placeholder="" />
                    </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-2 px-6">
                    <div class="w-full md:w-1/3 px-3">
                    <label for="quantity"  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Quantity
                    </label>
                    <input defaultValue={newEditData.quantity} name="quantity" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="quantity" type="number"  />
                    </div>

                    <div class="w-full md:w-1/3 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="unit">
                        Unit
                    </label>
                    <input defaultValue={newEditData.unit} name="unit" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="unit" type="text" />
                    </div>

                    <div class="w-full md:w-1/3 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="weight">
                        Weight    ( <button value={newEditData.weightType} name="weightType" onChange={(e) => setNewEditData({...newEditData, weightType: "LBS"})} class="" style={newEditData.weightType=="LBS"?{color:"blue"}:{color:"black"}}>LBS</button> / <button value={newEditData.weightType} name="weightType" onChange={(e) => setNewEditData({...newEditData, weightType: "KGS"})} class="" style={newEditData.weightType=="KGS"?{color:"blue"}:{color:"black"}}>KGS</button> )
                    </label>
                    <input defaultValue={newEditData.weight} name="weight" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" maxLength={10} min={0.1} id="weight" />
                    </div>                    
                </div>

            

                <div class="w-full h-32 mr-3 px-7">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="notes">
                        Notes
                    </label>
                    <textarea defaultValue={newEditData.note} name="note" onChange={(e) => onChange(e)} autoComplete="off" placeholder="Additional notes..." class="h-full shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="notes" type="text" />
                </div> 

                <div class="flex flex-wrap -mx-3 mb-2 px-6 mt-5 pb-4">

                    {/* <div class="w-full md:w-1/3 px-3">
                        <label for="status" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            Status
                        </label>
                        <select name="status" onChange={(e) => setNewEditData({...data, status: e.target.value})} class="shadow block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="status" >
                            <option selected={data.status=="Inventory"?true:false} value="Inventory">Inventory</option>
                            <option selected={data.status=="Delivered"?true:false} value="Delivered">Delivered</option>
                            <option selected={data.status=="Damaged"?true:false} value="Damaged">Damaged</option>
                            <option selected={data.status=="Lost"?true:false} value="Lost">Lost</option>
                            <option selected={data.status=="Delayeds"?true:false} value="Delayed">Delayed</option>
                        </select>
                    </div> */}

                    <div class="w-full md:w-1/3 px-3 mt-4 ml-4">
                    <form action="#" className="upload_button_div">
                        <label>Upload Packing Slip</label>                            
                        <input
                            /*ref={this.resetFile.ref0}*/ 
                            style={isSelected?{color:"green",fontWeight:"bold"}:{color:"red",fontWeight:"bold"}} 
                            className={isSelected?"upload_file_input_selected":"upload_file_input_none"} 
                            type="file" 
                            name="file" 
                            onChange={uploadFile} 
                        />

                        
                    </form>
                    </div>

                    <div class="flex w-full md:w-1/3 px-3 mt-4 ml-auto">
                        <div onClick={() => setDisplayConfirmationPopup(true)} class="w-36 h-10 flex bg-red-400 hover:bg-red-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                            <p class="flex m-auto">Delete</p>
                        </div>                               
                        {!isLoading?
                        <div onClick={displayConfirmationPopup?null:submitUpdateOrder} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                            <p class="flex m-auto">Update</p>
                        </div> 
                        :   
                        <div class="w-36 h-10 flex bg-blue-700 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                            <span class="flex m-auto"><Loading type={1} /></span>
                        </div> 
                        }
                    </div> 

                
                </div>  
            </div>             

        </Popup>


    );
    
}