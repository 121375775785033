import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'



export default function Footer() {

  
  return (

      <> 
          
        <div class="absolute bottom-0 right-0 bg-transparent h-6 p-1">
            <p class="flex ml-auto text-black text-sm mr-1">Locks & Docks Forwarding Inc. {new Date().getFullYear()}</p>
        </div>           

      </>

  )
 }

