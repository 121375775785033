import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import LoadingButton from '@mui/lab/LoadingButton';
import LinearProgress from '@mui/material/LinearProgress';
import SaveIcon from '@mui/icons-material/Save';

export default function Loading({ type, text, size=25, color="inherit" }) {

    return (
        <>
        {type === 1 ?
            <Box sx={{ display: 'flex'}}>
                <CircularProgress size={size} color={color}/>
            </Box>
        :type === 2 ?
            <LoadingButton 
                loading
                variant="contained"
                color="primary"
                //style={{backgroundColor:}}
                //sx={{"& button": {
                //    backgroundColor:"blue"
                //    }
                //}}
            >
                {text}
            </LoadingButton>
        :type === 3 ?
            <LinearProgress />
        :
            null
        }                
        </>
    );
    
}