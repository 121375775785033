import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';

import LayoutLanding from '../context/layoutLanding.jsx';

import image3 from '../../static/assets/images/logistics-pic1.png'
import image1 from '../../static/assets/images/logistics-pic3.png'
import image2 from '../../static/assets/images/logistics-pic2.png'

// import forward arrow icon
import { IoIosArrowForward } from 'react-icons/io';

export default function LandingLogistics() {

  const childRef = useRef();
  let location = useLocation();

  //useEffect(() => {
  //  const page = location.pathname;
  //  document.body.classNameList.add('is-loaded')
  //  childRef.current.init();
  //  trackPage(page);
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [location]);

  return (


    <LayoutLanding>

      <div id="class_wrapper" class="flex flex-col w-100">
        {/* <div class="bg-white py-24 px-12 sm:py-20  w-100 my-auto"> */}
              
        <div class="flex flex-col shadow-lg py-24 h-full px-12 mx-auto space-y-2 max-w-7xl xl:px-12 w-100 bg-white">
            
            <div class="relative">
                <h2 class="my-6 text-5xl tracking-tight font-extrabold text-center text-gray-700">Custom Logistics</h2>
                {/* <h2 class="w-full text-3xl font-bold text-center sm:text-4xl md:text-5xl">Custom Logistics</h2> */}
                <p class="w-full py-8 mx-auto -mt-2 text-lg text-center text-gray-700 intro sm:max-w-3xl">We do it all! We specialize in unconventional and complex delivery conditions and can adapt our services to any situation.</p>
            </div>
            
            <div class="flex flex-col mb-8 animated fadeIn sm:flex-row">
                <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                    {/* <img class="rounded-lg shadow-xl" src="https://cdn.devdojo.com/images/december2020/dashboard-011.png" alt="" /> */}
                    <img class="rounded-lg shadow-xl" src={image1} alt="" />
                </div>
                <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                    <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">various options</p>
                    <h3 class="mt-2 text-2xl sm:text-left md:text-4xl">Flexibility</h3>
                    <p class="mt-2 text-lg text-gray-700 text md:text-left">
                      Transportation and delivery logistics play a crucial role in today's
                      economy. Our services help companies get their products to their destination in a timely 
                      and efficient manner. We ensure that our users can meet the demands of their clients and 
                      stay competitive.
                    </p>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
            </div>
            
            <div class="flex flex-col mb-8 animated fadeIn sm:flex-row">
                <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12">
                    {/* <img class="rounded-lg shadow-xl" src="https://cdn.devdojo.com/images/december2020/dashboard-04.png" alt="" /> */}
                    <img class="rounded-lg shadow-xl" src={image2} alt="" />
                </div>
                <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
                    <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">always dependable</p>
                    <h3 class="mt-2 text-2xl sm:text-left md:text-4xl">Optimization</h3>
                    <p class="mt-2 text-lg text-gray-700 text md:text-left">                       
                      We offer a range of services, including trucking, shipping, forwarding and a variety of 
                      specialized services. Reliability is a top priority for us and try our best to work with you 
                      in ways that are convenient and practical.
                    </p>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
            </div>

            <div class="flex flex-col mb-16 animated fadeIn sm:flex-row">
                <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                    {/* <img class="rounded-lg shadow-xl" src="https://cdn.devdojo.com/images/december2020/dashboard-03.png" alt="" /> */}
                    <img class="rounded-lg shadow-xl" src={image3} alt="" />
                </div>
                <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                    <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">analyze metrics</p>
                    <h3 class="mt-2 text-2xl sm:text-left md:text-4xl">Data</h3>
                    <p class="mt-2 text-lg text-gray-700 text md:text-left">
                      We collect, curate, process, and analyze all kinds of data and content related to our clients
                      delivery and inventory history. Our aim is to use these metrics is to help you refine your 
                      business model, limit overhead and improve efficiency.
                    </p>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
            </div>

            <div class="p-4 border-t-2 border-gray-700 prose prose-slate max-w-none dark:prose-invert dark:text-slate-400 prose-headings:scroll-mt-28 prose-headings:font-display prose-headings:font-normal lg:prose-headings:scroll-mt-[8.5rem] prose-lead:text-slate-500 dark:prose-lead:text-slate-400 prose-a:font-semibold dark:prose-a:text-sky-400 prose-a:no-underline prose-a:shadow-[inset_0_-2px_0_0_var(--tw-prose-background,#fff),inset_0_calc(-1*(var(--tw-prose-underline-size,4px)+2px))_0_0_var(--tw-prose-underline,theme(colors.sky.300))] hover:prose-a:[--tw-prose-underline-size:6px] dark:[--tw-prose-background:theme(colors.slate.900)] dark:prose-a:shadow-[inset_0_calc(-1*var(--tw-prose-underline-size,2px))_0_0_var(--tw-prose-underline,theme(colors.sky.800))] dark:hover:prose-a:[--tw-prose-underline-size:6px] prose-pre:rounded-xl prose-pre:bg-slate-900 prose-pre:shadow-lg dark:prose-pre:bg-slate-800/60 dark:prose-pre:shadow-none dark:prose-pre:ring-1 dark:prose-pre:ring-slate-300/10 dark:prose-hr:border-slate-800">
                <p class="text-center font-bold text-black my-4">
                    We want to hear from you. Get in touch with us to learn more about our B2B transport, warehousing and logistics services. 
                </p>
                
                <div class="w-100 flex">
                  <a href="/contact" class="mx-auto inline-flex no-underline items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-black hover:!bg-gray-800 rounded sm:w-auto sm:mb-0">
                        Contact Us
                        <span class="ml-2">
                          <IoIosArrowForward />
                        </span>
                        {/* <svg class="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg> */}
                    </a>
                  </div>
              </div>


        </div>
      </div>


    </LayoutLanding>
  );
}
















