import React, { useRef, useEffect, useState } from 'react';

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useRefreshToken from '../hooks/useRefreshToken';

import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import { FiX } from 'react-icons/fi';
import { BsX } from 'react-icons/bs';
import Loading from './loading';
import Popup from '../context/layoutPopup.jsx';

import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';


function arrayAlphebetize(array) {
    return array.sort((a, b) => a.localeCompare(b));
}

function removeDuplicatesFromArray(array) {
    return array.filter((a, b) => array.indexOf(a) === b);
} 

function checkEmail(str) {
    if (str.length>0) {    
        let val = str;
        if(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val)) {
            return true
        } else {
            return false
        }
    } else {
        return true;
    }
}


export default function MenuEditClients({ data, onToggleDisplay, removeUserFromData, updateParent, notify }) {

    const axiosPrivate = useAxiosPrivate();
    const refresh = useRefreshToken();

    const [isLoading, setIsLoading] = useState(false);
    const [newEditData, setNewEditData] = useState(data);
    const [displayMenu, setDisplayMenu] = useState(0);    
    const [newDestination, setNewDestination] = useState("");
    const [destinationsList, setDestinationsList] = useState(data.divisions.split(";"));    
    const [emailsList, setEmailsList] = useState(data.emails.split(";"));

    
    const _emailmap = JSON.parse(data.emailmap.replace(/""/g, '"').replace(/"{/, '{').replace(/}"$/, '}')) 

    //console.log("EMAIL MAP @@@@@@@@@@@@@@@@@@@@@")
    //console.log(_emailmap)

    const [emailMap, setEmailMap] = useState(_emailmap);

    

    const [editDestination, setEditDestination] = useState("");
    const [newEditDestination, setNewEditDestination] = useState("");

    const [emailsBlob, setEmailsBlob] = useState(data.emails);

    const [confirmDeleteCode, setConfirmDeleteCode] = useState(0);
    const [displayConfirmationPopup, setDisplayConfirmationPopup] = useState(false);

    const [newPassword, setNewPassword] = useState(""); 

    
    useEffect(() => {
        //console.log(data);  
    }, []);  
    
    //const updateParentData = () => {
    //    updateParent(newEditData);
    //}

    const onChangeNewDestination = (e) => {
        if (e.target.value.includes('"') || e.target.value.includes('`')) {}
        else {
            setNewDestination(e.target.value);
        }
    }
    
    const onChangeNewEditDestination = (e) => {
        if (e.target.value.includes('"') || e.target.value.includes('`')) {}
        else {
            setNewEditDestination(e.target.value);
        }
    }

    const onChangeNewPassword = (e) => {
        if (e.target.value.includes('"') || e.target.value.includes('`')) {}
        else {
            setNewPassword(e.target.value);
        }
    }

    const onChangeEmailsBlob = (e) => {
        if (e.target.value.includes('"') || e.target.value.includes('`')) {}
        else {
            setEmailsBlob(e.target.value);
        }
    }   

    const changeMenuDisplay = (value) => {
        setDisplayConfirmationPopup(false);
        setDisplayMenu(value);
    }
    
    const startEditDestination = (destination) => {        
        setEditDestination(destination);
        setDisplayMenu(1.3);
    }

    const startMapEmails = (destination) => {
        setEditDestination(destination);
        //console.log(emailMap);
        //console.log(emailMap[destination])
        setDisplayMenu(1.2);
    }

    const startDeleteDestination = (destination) => {
        setConfirmDeleteCode(1);
        setEditDestination(destination);
        setDisplayConfirmationPopup(true);
    }
    
    const startDeleteUser = () => {
        setConfirmDeleteCode(0);
        setDisplayConfirmationPopup(true);
    }

    const onChangeEmailmap = (email) => {
        //console.log(email);
        let emailList = emailMap[editDestination];
        //console.log(emailList);

        if (emailList.includes(email)) {
            for (let i=0; i<emailList.length; ++i) {
                if (emailList[i] == email) {
                    emailList.splice(i, 1);
                }            
            }
        }
        else {
            emailList.push(email);
        }

        //console.log(emailList);

        let newEmailMap = emailMap;
        newEmailMap[editDestination] = emailList;
        //console.log(newEmailMap);
        
        setEmailMap(newEmailMap);
        setNewEditData({ ...newEditData, emailmap: JSON.stringify(newEmailMap) });
    }

    const onSubmitEditInfo = async () => {
        setIsLoading(true);
        submitUpdateUser(newEditData);
        setIsLoading(false);
        notify("User updated","success");
    }

    const onSubmitMapEmails = async () => {
        setIsLoading(true);
        const newData = { ...newEditData, emailmap: JSON.stringify(emailMap) } 
        setNewEditData(newData);
        submitUpdateUser(newData);        
        setIsLoading(false);
        setDisplayMenu(1);
        notify("Emails updated","success");

    }

    const onSubmitEditDestination = async () => {

        // renaming object key
        // delete Object.assign(obj, {newkey: obj.oldkey}).oldkey;

        try {
        
            setIsLoading(true);
            let newDestinationsList = destinationsList.filter((destination) => destination !== editDestination);
            newDestinationsList.push(newEditDestination);        
            setDestinationsList(newDestinationsList);

            let newEmailMap = emailMap;
            newEmailMap[newEditDestination] = newEmailMap[editDestination];
            delete newEmailMap[editDestination];
            setEmailMap(newEmailMap);

            const newData = { ...newEditData, divisions: newDestinationsList.join(";"), emailmap: JSON.stringify(newEmailMap) } 

            setNewEditData(newData);

            const body = JSON.stringify({
                newDestination: newEditDestination,
                oldDestination: editDestination,
            });

            const response = await submitUpdateUser(newData);
            const updateOrders = await axiosPrivate.post('/orders/update/destination', body, { credentials: "include" });
            const updateOutgoing = await axiosPrivate.post('/outgoing/update/destination', body, { credentials: "include" });
            setIsLoading(false);
            setEditDestination(""); 
            setDisplayMenu(1); 
            notify("Destination updated","success");
        } catch (error) {
            notify("Destination update failed", "error")
            setIsLoading(false);
        }
    }   

    const onSubmitNewDestination = async () => {
        setIsLoading(true);
        let newDestinationsList = [...destinationsList, newDestination];
        setDestinationsList(newDestinationsList);        

        let newEmailMap = emailMap;
        newEmailMap[newDestination] = [];
        setEmailMap(newEmailMap);

        const newData = { ...newEditData, divisions: newDestinationsList.join(";"), emailmap: JSON.stringify(newEmailMap) } 

        setNewEditData(newData);
        submitUpdateUser(newData);
        setIsLoading(false);
        setDisplayMenu(1);
        setNewDestination("");       
    }

    const onSubmitEditEmails = async () => {
        setIsLoading(true);

        try {
            const newEmailsList = emailsBlob.split(';');
            for (let i=0; i<newEmailsList.length; ++i) {                  
                if (!checkEmail(newEmailsList[i])) {
                    throw new Error("Invalid email in list");
                }                     
            };
            setEmailsList(newEmailsList);

            const newData = { ...newEditData, emails: emailsBlob } 

            setNewEditData(newData);
            submitUpdateUser(newData);
            setIsLoading(false);   
            notify("Emails updated", "success")                     

        }
        catch (error) {
            notify("Invalid email in list", 'error');
            setIsLoading(false);
        }
    }

    const onSubmitNewPassword = async () => {
        setIsLoading(true);

        const data = {
            id: newEditData.id,
            password: newPassword
        }

        const body = JSON.stringify(data);
        try {
            const response = await axiosPrivate.post(`/users/changePassword`, body, { credentials: "include" });
            notify("Password updated", "success")
            setIsLoading(false);
        }
        catch {
            notify("Password update failed", "error")
            setIsLoading(false);
        }        
    }

    const deleteUserConfirmation = async () => {
        setIsLoading(true);
        const body = JSON.stringify({id: newEditData.id, username: newEditData.username});
        const response = await axiosPrivate.post(`/users/delete`, body, { method: "POST", credentials: "include" });
        //console.log(response.data);  
        removeUserFromData(newEditData.id)      
        setIsLoading(false);
        setDisplayConfirmationPopup(false);
        onToggleDisplay(false)        
    }

    const deleteDestinationConfirmation = async () => {
        setIsLoading(true);
        let newDestinationsList = destinationsList.filter((destination) => destination !== editDestination);
        setDestinationsList(newDestinationsList);

        let newEmailMap = emailMap;
        delete newEmailMap[editDestination];
        setEmailMap(newEmailMap);
        
        const newData = { ...newEditData, divisions: newDestinationsList.join(";"), emailmap: JSON.stringify(newEmailMap) }
        setNewEditData(newData);
        const response = await submitUpdateUser(newData);    
        setIsLoading(false);
        setEditDestination("");
        setDisplayConfirmationPopup(false);
    }

    const onChangeInfo = (e) => {    
        if (e.target.value.includes('"') || e.target.value.includes('`')) {}
        else {
            setNewEditData({...newEditData, [e.target.name]: e.target.value});
        }
    }

    const submitUpdateUser = async (newData) => {
        try {
            const body = JSON.stringify(newData);
            const response = await axiosPrivate.post(`/users`, body, { method: "POST", credentials: "include" });
            //console.log(response.data);
            await updateParent();
            await refresh();
            return response;
        } catch (error) {            
            notify("Update failed", "error")
            return;
        }
    }
        

    return (
          
            <Popup>

                        <div class="flex absolute top-5 right-5">
                            <button onClick={() => onToggleDisplay(false)} class="h-10 w-10 bg-gray-200 hover:bg-gray-300 rounded-full flex m-2">
                                <BsX class="m-auto" size={25}/>
                            </button>
                        </div>

                        {displayConfirmationPopup? // create a centered popup to confirm deletion
                        <div id="popupMenu" class="absolute z-20 mx-auto my-auto inset-0 overflow-hidden h-1/4 p-2 w-1/2  bg-gray-500 text-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
                            <div class="bg-gray-500 text-white sm:p-6 h-full w-100 flex">

                                {confirmDeleteCode==0
                                ? // Confirm Deleting User
                                <>
                                    <div class="flex">
                                        <div class="ml-6">
                                            <h3 class="">Delete User #{data.id}</h3>
                                            <p class="text-white">Are you sure you want to delete this user?</p>
                                        </div>
                                    </div>
                                    <div class="flex ml-auto mt-auto">
                                        <button onClick={isLoading?null:() => setDisplayConfirmationPopup(false)} class="bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded mr-2">Cancel</button>                                      
                                        <div class="">
                                            {!isLoading?
                                            <div onClick={deleteUserConfirmation} class="w-28 h-10 flex bg-red-400 hover:bg-red-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                                                <p class="flex m-auto">Confirm</p>
                                            </div> 
                                            :   
                                            <div class="w-28 h-10 flex bg-red-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                                <span class="flex m-auto"><Loading type={1} /></span>
                                            </div> 
                                            }
                                        </div> 

                                    </div>
                                </>
                                :confirmDeleteCode==1
                                ? // Confirm Delete Destination
                                <>
                                    <div class="flex">
                                        <div class="ml-6">
                                            <h3 class="">Delete Destination {editDestination}</h3>
                                            <p class="text-white">Are you sure you want to delete this destination?</p>
                                        </div>
                                    </div>
                                    <div class="flex ml-auto mt-auto">
                                        <button onClick={isLoading?null:() => setDisplayConfirmationPopup(false)} class="bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded mr-2">Cancel</button>
                                        <div class="">
                                            {!isLoading?
                                            <div onClick={deleteDestinationConfirmation} class="w-28 h-10 flex bg-red-400 hover:bg-red-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                                                <p class="flex m-auto">Confirm</p>
                                            </div> 
                                            :   
                                            <div class="w-28 h-10 flex bg-red-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                                <span class="flex m-auto"><Loading type={1} /></span>
                                            </div> 
                                            }
                                        </div> 
                                    </div>
                                </>
                                :null}

                            </div>
                        </div>                                                    
                        :null}

                        <div id="innerEditMenu" class="h-3/4 pb-0 w-3/4 inline-block bg-gray-200 rounded-lg text-left overflow-scroll shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
                            {displayMenu==0
                            ? // Edit User                                        
                            <div class="bg-gray-200 pb-4 sm:p-6 h-full w-100">
                                <div class="flex">
                                    <div class="ml-6">
                                        <h3 >Edit User #{data.id}</h3>                                        
                                    </div>                                    
                                </div>                    

                                <div class="flex ml-6 py-2">
                                    {newEditData.name!=="admin"
                                        ?
                                        <>
                                            <span onClick={() => changeMenuDisplay(1)} class="ml-2 font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">Edit Destinations</span>
                                            <span onClick={() => changeMenuDisplay(2)} class="ml-2 font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">Edit Emails</span>
                                        </>
                                        :null}
                                    <span onClick={() => changeMenuDisplay(3)} class="ml-2 font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">Edit Password</span>
                                </div>

                                <div class="flex flex-wrap -mx-3 mb-2 px-6">
                                    <div class="w-full md:w-1/2 px-3">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                        Name
                                    </label>
                                    <input value={newEditData.name} name="name" onChange={(e) => onChangeInfo(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reference" type="text" placeholder="" />
                                    </div>

                                    <div class="w-full md:w-1/2 px-3">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                        Email
                                    </label>
                                    <input value={newEditData.email} name="email" onChange={(e) => onChangeInfo(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="supplier" type="text" placeholder="" />
                                    </div>
                                </div>

                                <div class="flex flex-wrap -mx-3 mb-2 px-6">
                                    <div class="w-full md:w-100 px-3">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="waybill">
                                        Address
                                    </label>
                                    <input value={newEditData.address} name="address" onChange={(e) => onChangeInfo(e)} class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="waybill" type="text" placeholder="" />
                                    </div>
                                </div>                 

                                <div class="flex flex-wrap -mx-3 mb-2 px-6">
                                    <div class="w-full md:w-1/2 px-3">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                        Username
                                    </label>
                                    <input value={newEditData.username} name="username" onChange={(e) => onChangeInfo(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reference" type="text" placeholder="" />
                                    </div>

                                    <div class="w-full md:w-1/2 px-3">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                        Phone
                                    </label>
                                    <input defaultValue={newEditData.phone} name="phone" onChange={(e) => onChangeInfo(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="supplier" type="text" placeholder="" />
                                    </div>
                                </div> 

                                <div class="flex flex-wrap -mx-3 mb-2 px-6 mt-5">

                                    <div class="flex w-full md:w-100 px-3 mt-4 mb-3">
                                        {newEditData.name!=="admin"
                                        ?
                                            <div onClick={startDeleteUser} class="w-36 h-10 flex bg-red-400 hover:bg-red-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                                                <p class="flex m-auto">Delete</p>
                                            </div>  
                                        :null}                             
                                        {!isLoading?
                                        <div onClick={displayConfirmationPopup?null:onSubmitEditInfo} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                                            <p class="flex m-auto">Update</p>
                                        </div> 
                                        :   
                                        <div class="w-36 h-10 flex bg-blue-700 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                            <span class="flex m-auto"><Loading type={1} /></span>
                                        </div> 
                                        }
                                    </div> 

                                </div>  
                            </div>                        
                            :displayMenu==1.0
                            ? // List Destinations
                            <div class="bg-gray-200 pb-4 sm:p-6 h-full">
                                <div class="flex">
                                    <span onClick={() => changeMenuDisplay(0)} class="text-lg font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">← Back</span>
                                </div>
                                <div class="flex pb-1 pt-3">
                                    <div class="align-center">
                                        <h5 class="text-black ml-3">Edit Destinations</h5>                                    
                                    </div>
                                    <span onClick={() => changeMenuDisplay(1.1)} class="ml-auto text-lg font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">Add New Destination</span>                                    
                                </div>  

                                <div class="flex flex-wrap -mx-3 px-6 py-4 h-3/4 max-h-3/4">
                                    <div class="shadow w-100 px-3 bg-gray-100 h-100 border-black overflow-scroll py-2">
                                        {arrayAlphebetize(destinationsList).map((destination, index) => {                                            
                                            return  (
                                                <div class="flex w-100">
                                                    <p class="font-bold">{destination}</p>
                                                    <div class="flex ml-auto py-1">
                                                        <button onClick={() => startMapEmails(destination)} class="mr-1 p-1 bg-blue-500 text-white rounded hover:bg-blue-600"><Tooltip title="Map Emails" placement="top"><AlternateEmailOutlinedIcon /></Tooltip></button>
                                                        <button onClick={() => startEditDestination(destination)} class="mr-1 p-1 bg-orange-300 text-white rounded hover:bg-orange-500"><Tooltip title="Edit Destination" placement="top"><EditOutlinedIcon /></Tooltip></button>
                                                        <button onClick={() => startDeleteDestination(destination)} class="mr-1 p-1 bg-red-400 text-white rounded hover:bg-red-500"><Tooltip title="Delete Destination" placement="top"><DeleteOutlinedIcon /></Tooltip></button>
                                                    </div>                                                

                                                </div>    
                                            )                                            
                                        })}                                        
                                    </div>
                                </div> 

                                {/* <div class="w-full md:w-100 px-3 mt-4">
                                    <button onClick={(e) => onSubmitEdit(e, newEditData)} class="flex bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 py-2 mr-6 rounded mx-auto" type="button">
                                        Save
                                    </button> 
                                </div>*/}

                            </div>
                            :displayMenu==1.1
                            ? // Add New Destination
                            <div class="bg-gray-200 pb-4 sm:p-6 h-full">
                                <div class="flex">
                                    <span onClick={() => changeMenuDisplay(1)} class="text-lg font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">← Back</span>
                                </div>
                                <div class="flex pb-1 pt-3">
                                    <div class="align-center">
                                        <h5 class="ml-3">Add New Destination</h5>                                    
                                    </div>
                                </div>  

                                <div class="flex flex-wrap -mx-3 px-6 py-4 h-3/4 max-h-3/4">
                                    <div class="shadow w-100 px-3 bg-gray-100 h-100 border-black overflow-scroll py-2">
                                        
                                        <p class="font-bold">Input New Destination:</p>
                                        <input onChange={(e) => onChangeNewDestination(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="" />
                                                                             
                                    </div>
                                </div> 

                                <div class="w-full md:w-100 px-3 mt-4">
                                    {!isLoading?
                                    <div onClick={onSubmitNewDestination} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                                        <p class="flex m-auto">Submit</p>
                                    </div> 
                                    :   
                                    <div class="w-36 h-10 flex bg-blue-700 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span>
                                    </div> 
                                    }
                                </div> 

                                

                            </div>
                            :displayMenu==1.2
                            ? // Map Emails
                            <div class="bg-gray-200 pb-4 sm:p-6 h-full">
                                <div class="flex">
                                    <span onClick={() => changeMenuDisplay(1)} class="text-lg font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">← Back</span>
                                </div>
                                <div class="flex pb-1 pt-3">
                                    <div class="align-center">
                                        <h5 class="ml-3">Map Emails For <span class="text-blue-600">{editDestination}</span></h5>                                    
                                    </div>
                                </div>  

                                <div class="flex flex-wrap -mx-3 px-6 py-4 h-3/4 max-h-3/4">
                                    <div class="shadow w-100 px-3 bg-gray-100 h-100 border-black overflow-scroll py-2">                                        
                                        
                                        {emailsList[0].length>0                                        
                                        ?arrayAlphebetize(emailsList).map((email, index) => {
                                            return (
                                                <div class="w-100 flex h-10"> 
                                                    {emailMap[editDestination].includes(email)
                                                    ?<Checkbox onChange={() => onChangeEmailmap(email)} checked/>
                                                    :<Checkbox onChange={() => onChangeEmailmap(email)}/>}
                                                    <p class="font-bold align-middle ml-2 my-auto flex">{email}</p>
                                                </div>
                                            )}
                                        )
                                        :<p class="flex  m-auto text-red-500 font-bold">No Emails Found</p>
                                        }
                                                                             
                                    </div>
                                </div> 

                                <div class="w-full md:w-100 px-3 mt-4">
                                    {!isLoading?
                                    <div onClick={onSubmitMapEmails} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                                        <p class="flex m-auto">Submit</p>
                                    </div> 
                                    :   
                                    <div class="w-36 h-10 flex bg-blue-700 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span>
                                    </div> 
                                    }
                                </div> 

                            </div>
                            :displayMenu==1.3
                            ? // Edit Destination
                            <div class="bg-gray-200 pb-4 sm:p-6 h-full">
                                <div class="flex">
                                    <span onClick={() => changeMenuDisplay(1)} class="text-lg font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">← Back</span>
                                </div>
                                <div class="flex pb-1 pt-3">
                                    <div class="align-center">
                                        <h5 class="ml-3">Edit Selected Destination</h5>                                    
                                    </div>
                                </div>  

                                <div class="flex flex-wrap -mx-3 px-6 py-4 h-3/4 max-h-3/4">
                                    <div class="shadow w-100 px-3 bg-gray-100 h-100 border-black overflow-scroll py-2">                                        
                                        
                                        <p class="font-bold">Input Destination Name:</p>
                                        <input defaultValue={editDestination} onChange={(e) => onChangeNewEditDestination(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="" />
                                        <p class="text-sm text-red-500 font-bold">* This change will be reflected in the orders table and for all orders corresponding to this destination.</p>
                                                                             
                                    </div>
                                </div> 

                                <div class="w-full md:w-100 px-3 mt-4">
                                    {!isLoading?
                                    <div onClick={onSubmitEditDestination} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                                        <p class="flex m-auto">Submit</p>
                                    </div> 
                                    :   
                                    <div class="w-36 h-10 flex bg-blue-700 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span>
                                    </div> 
                                    }
                                </div> 

                            </div>
                            :displayMenu==2.0
                            ? // Emails List
                            <div class="bg-gray-200 pb-4 sm:p-6 h-full">
                                <div class="flex">
                                    <span onClick={() => changeMenuDisplay(0)} class="text-lg font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">← Back</span>
                                </div>
                                <div class="flex pb-1 pt-3 mx-2">
                                    <div class="align-center">
                                        <h5 class="ml-3">Current Emails List</h5>                                                                            
                                    </div>
                                    <span onClick={() => changeMenuDisplay(2.1)} class="font-bold ml-auto text-lg text-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">Add/Edit Emails</span>                                    
                                </div>  

                                <div class="flex flex-wrap -mx-3 px-6 py-4 h-3/4 max-h-3/4">
                                    <div class="shadow w-100 px-3 bg-gray-100 h-100 border-black overflow-scroll py-2">                                        

                                    {emailsList[0].length>0?
                                        emailsList.map((email, index) => {
                                            return (
                                                <div class="w-100 flex h-10">
                                                    <p class="font-bold align-middle ml-2 my-auto flex">{email}</p>
                                                </div>
                                            )}
                                        )
                                    :
                                    <div class="h-100 w-100 flex">
                                        <p class="flex m-auto text-red-500 font-bold">No Emails Found</p>
                                    </div>}
                                                                    
                                    </div>
                                </div> 
 
                            </div>
                            :displayMenu==2.1
                            ? // Edit Emails
                            <div class="bg-gray-200 pb-4 sm:p-6 h-full">
                                <div class="flex">
                                    <span onClick={() => changeMenuDisplay(2)} class="text-lg font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">← Back</span>
                                </div>
                                <div class="flex pb-1 pt-3">
                                    <div class="align-center">
                                        <h5 class="ml-3"> Emails List</h5>                                                                            
                                    </div>                                    
                                </div>  

                                <div class="flex flex-wrap -mx-3 px-6 py-4 h-3/4 max-h-3/4">
                                    <div class="shadow w-100 px-3 bg-gray-100 h-100 border-black overflow-scroll py-2">                                        

                                        <textarea 
                                            class="w-100 h-100 border-black rounded p-2"
                                            onChange={(e) => onChangeEmailsBlob(e)} 
                                            value={emailsBlob}
                                            placeholder="Add emails seperated by ';'. Example: emailA@email.com;emailB@email.com;...   Do not end with a ';'"
                                        />
                                                                    
                                    </div>
                                </div> 

                                <div class="w-full md:w-100 px-3 mt-4">
                                    {!isLoading?
                                    <div onClick={onSubmitEditEmails} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                                        <p class="flex m-auto">Submit</p>
                                    </div> 
                                    :   
                                    <div class="w-36 h-10 flex bg-blue-700 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span>
                                    </div> 
                                    }
                                </div> 

                            </div>
                            :displayMenu==3.0
                            ? // Edit Password
                            <div class="bg-gray-200 pb-4 sm:p-6 h-full">
                                <div class="flex">
                                    <span onClick={() => changeMenuDisplay(0)} class="text-lg font-bold text-blue-400 hover:text-blue-500 cursor-pointer hover:underline">← Back</span>
                                </div>
                                <div class="flex pb-1 pt-3">
                                    <div class="align-center">
                                        <h5 class="ml-3">Change Password For User</h5>                                                                            
                                    </div>                                    
                                </div>  

                                <div class="flex flex-wrap -mx-3 px-6 py-4 h-3/4 max-h-3/4">
                                    <div class="shadow w-100 px-3 bg-gray-100 h-100 border-black overflow-scroll py-2">                                        

                                        <p class="font-bold">Enter a new password.</p>
                                        <input value={newPassword} onChange={(e) => onChangeNewPassword(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Enter new password" />
                                        {/* <p class="text-sm text-red-500 font-bold">* This change will be reflected in the orders table and for all orders corresponding to this destination.</p> */}
                                           
                                                                    
                                    </div>
                                </div> 

                                <div class="w-full md:w-100 px-3 mt-4">
                                    {!isLoading?
                                    <div onClick={onSubmitNewPassword} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                                        <p class="flex m-auto">Submit</p>
                                    </div> 
                                    :   
                                    <div class="w-36 h-10 flex bg-blue-700 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span>
                                    </div> 
                                    }
                                </div> 

                            </div>

                            :null}
                        </div>    

            </Popup>

    );
    
}



