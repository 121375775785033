import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';

import LayoutLanding from '../context/layoutLanding';

export default function LandingMission() {

  const childRef = useRef();
  let location = useLocation();

  //useEffect(() => {
  //  const page = location.pathname;
  //  document.body.classNameList.add('is-loaded')
  //  childRef.current.init();
  //  trackPage(page);
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [location]);

  return (
    <LayoutLanding>

        <section class="text-gray-600 body-font w-100">
          <div class="container px-5 py-24 mx-auto border-4 shadow-lg">
            <div class="xl:w-3/4 lg:w-3/4 w-full mx-auto p-3">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="inline-block w-8 h-8 text-gray-400 mb-8" viewBox="0 0 975.036 975.036" data-darkreader-inline-fill="" >
                <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
              </svg>
              {/* <h1>Our Mission</h1> */}
              <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Mission</p>
              <p class="leading-relaxed text-md p-4">
                Our transport, warehousing and logistics company is dedicated to providing flexible and reliable solutions for all of our clients' shipping needs. 
                <br></br>
                <br></br>
                We understand that every business is unique and has specific requirements for their shipments, which is why we offer a wide range of services and customizable options.
                Our mission is to consistently deliver on-time, cost-effective transportation and logistics services that meet and exceed the expectations of our clients.
                <br></br>
                <br></br>
                We strive to be the best in the industry by constantly improving our processes and investing in technologies to ensure the best possible service.                            
                We are always available to answer questions, address concerns, and offer solutions to any challenges that may arise.
                <br></br>
                <br></br>
                Ultimately, our goal is to be a reliable and trusted partner for all of your transportation, warehousing and logistics needs.
              </p>
              <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
              <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm">LOCKS AND DOCKS INC.</h2>
              <p class="text-gray-500">2023</p>


              <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About the growth of LDF</p>

              
            </div>
          </div>
        </section>

    </LayoutLanding>
  );
}
















