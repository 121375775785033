import { createContext, useState, useEffect } from "react";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

    const [auth, setAuth] = useState({});
    const [persist, setPersist] = useState(JSON.parse(localStorage.getItem('persist')) || true);    
    const [userPreferences, setUserPreferences] = useState({});    

    // if theme is not in local storage, set it to light and set the state
    useEffect(() => {
        if (localStorage.getItem("theme") === null) {
          localStorage.setItem("theme", "light");         
        }
        const theme = localStorage.getItem("theme");

        if (localStorage.getItem("language") === null) {
          localStorage.setItem("language", "en");
        }
        const language = localStorage.getItem("language")    

        if (localStorage.getItem("searchPreferences") === null) {
          localStorage.setItem(
            "searchPreferences",
            JSON.stringify({
              inventory: true,
              pending: true,
              delivered: false,
              delayed: false,
              damaged: false,
              lost: false
            })
          );
        }
        const searchPreferences = JSON.parse(
          localStorage.getItem("searchPreferences")
        );

        if (localStorage.getItem("notificationPreferences") === null) {
          localStorage.setItem(
            "notificationPreferences",
            JSON.stringify({
              createJob: true,
              createOrder: false,
              editJob: true,
              editOrder: false
            })
          );
        }
        const notificationPreferences = JSON.parse(
          localStorage.getItem("notificationPreferences")
        );

        if (localStorage.getItem("tableColor") === null) {
          localStorage.setItem("tableColor", true);
        }
        const tableColor = localStorage.getItem("tableColor")

        setUserPreferences({
            theme,
            language,            
            searchPreferences,
            notificationPreferences,
            tableColor
        });

    }, []);
  

    return (
        <AuthContext.Provider value={{ 
            auth, setAuth, 
            persist, setPersist, 
            userPreferences, setUserPreferences 
          }}>
            {children}
        </AuthContext.Provider>
    )
} 

export default AuthContext;